import {
    LOCATION_LIST_END, LOCATION_LIST_START
} from '../constants';

const INIT_STATE = {
    location_loading: false,
    location_list: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOCATION_LIST_START: {
            return {...state, location_loading: true};
        }
        case LOCATION_LIST_END: {
            return {...state, location_loading: false, location_list: action.payload};
        }
        default:
            return state;
    }
};
