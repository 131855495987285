import React, {useState, useEffect} from 'react';
import {Button, DatePicker, Empty, notification, Select, Skeleton, Popconfirm, message} from "antd";
import {FormFeedback, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useFormik} from "formik";
import {handleNotify} from "../../appRedux/response";
import {connect} from "react-redux";
import {getCountryLists} from "../../appRedux/actions/Country";
import {
    getEducation,
    deleteEducation,
    getExperience,
    talentSaveEducation,
    talentSaveExperience
} from "../../appRedux/actions/Talents";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import * as Yup from "yup";
import {isEmpty} from "lodash"
import PopconfirmEducationDelete from "./PopconfirmEducationDelete"
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import moment from 'moment';

const {Option} = Select;


function Education(props) {

    const empty = {
        degree: '',
        title: '',
        institution: '',
        start_date: '',
        end_date: '',
        still_work: false,
    }
    const monthFormat = 'YYYY/MM';

    const [modal, setModal] = useState(false);
    const [education, setEducation] = useState(empty);
    const [edu, setEdu] = useState(false);
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [deletingEducation, setDeletingEducation] = useState([]);

    useEffect(() => {
        props.getEducation()
    }, []);

    const toggle = () => setModal(!modal);

    const isEducationDeleting = (education_id) => {
        return deletingEducation.find(e => e === education_id) !== undefined
    }

    const deleteEducationHandler = (education_id) => () => {
        setDeletingEducation(deletingEducation.concat(education_id))
        props.deleteEducation(education_id)
            .then((data) => {
                setDeletingEducation(deletingEducation.filter(e => e !== education_id))
                message.success("Deleted");
                props.getEducation()
            })
            .catch(e => {
                setDeletingEducation(deletingEducation.filter(e => e !== education_id))
                message.error(e.message)
            })
    };

    const regEduSchema = Yup.object().shape({
        degree: Yup.string().required('Degree is Required'),
        title: Yup.string().required('Title is Required'),
        institution: Yup.string().required('Institution is Required'),
        start_date: Yup.string().required('Start date address is Required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: education,
        validationSchema: regEduSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            if(!isEmpty(education)) {
                values['id'] = education.id;
            }
            // console.log('values', values)
            props.talentSaveEducation(values).then(res => {
                handleIn(res)
                resetForm(true);
                setSubmitting(false);
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Education saved successful',
            description: res.message,
            duration: NOTIFICATION_DURATION,
        });
        setLaddaLoading(false);
        setLaddaProgress(1.0);
        toggle()
    }

    const edit = e => {
        e['degree'] = e.degree_type_id;
        console.log(degree_list[e.degree_type_id])
        setEducation(e)
        setEdu(true)
        toggle()
    }

    const {degree_list, degree_loading, talent_education, talent_education_loading} = props;
    let optionDegree = [];
    for (let degree in degree_list) {
        optionDegree.push(<Option key={degree} value={degree}>{degree_list[degree]}</Option>)
    }

    return (
        <>
            <div className={'card mb-5'}>
                <div className="card-header border-0 bg-light text-center px-4">
                    <h5 className="card-header-title"> Education
                    </h5>
                    <Button className="btn btn-primary btn-xs transition-3d-hover" onClick={() => {
                        setEducation(empty);
                        setEdu(false)
                        toggle()
                    }}>
                        Add Education
                    </Button>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            {talent_education_loading ?
                                <div>
                                    <Skeleton active/>
                                    <Skeleton active/>
                                </div> :
                                <>
                                    {talent_education.length === 0 ? <Empty description={<>No Education</>}/> :
                                        <ul className="step step-icon-sm">
                                            {talent_education.map(education => (
                                                <li className="step-item" key={education.id}>
                                                    <div className="step-content-wrapper">
                                                          <span className="step-icon step-icon-soft-dark">
                                                            <i className="fas fa-graduation-cap"/>
                                                          </span>
                                                        <div className="step-content">
                                                            <p className={'p_title'}>{education.degree_type}</p>
                                                            <h5>{education.title}</h5>

                                                            <PopconfirmEducationDelete education_id={education.id} education_title={education.title}/>
                                                            <button onClick={() => edit(education)} style={{'top': '34px'}} className="btn remove btn-sm btn-outline-primary btn-xs transition-3d-hover mt-2">
                                                                <span> <EditOutlined /> Edit </span>
                                                            </button>

                                                            <span className="d-block text-dark">{education.institution} </span>
                                                            <small className="d-block">{education.start_date} to {isEmpty(education.end_date) ? 'Present' : education.end_date}</small>
                                                        </div>
                                                    </div>
                                                </li>))
                                            }
                                        </ul>
                                    }
                                </>}
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}> {edu ? 'Update' : 'Add'} Education </ModalHeader>
                <ModalBody>
                    <div>
                        <div>
                            <form className="js-validate" onSubmit={formik.handleSubmit}>

                                <div>
                                    <div className="js-form-message form-group">
                                        <label className="input-label"> Degree </label>
                                        <Select
                                            style={{width: '100%'}}
                                            className={'select'}
                                            size={'large'}
                                            loading={degree_loading}
                                            name="degree"
                                            placeholder="Select degree"
                                            value={degree_list[education.degree_type_id]}
                                            onBlur={formik.handleBlur}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={event => {
                                                formik.values.degree = event;
                                                formik.handleChange.degree = event;
                                            }}>
                                            {optionDegree}
                                        </Select>
                                        {formik.touched.degree && formik.errors.degree &&
                                        <FormFeedback>{formik.errors.degree}</FormFeedback>}
                                    </div>
                                </div>

                                <div className="js-form-message form-group">
                                    <label className="input-label"> Title </label>
                                    <input type="text"
                                           className="form-control"
                                           name="title"
                                           placeholder="Enter title"
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.title}/>
                                    {formik.touched.title && formik.errors.title &&
                                    <FormFeedback>{formik.errors.title}</FormFeedback>}
                                </div>

                                <div className="js-form-message form-group">
                                    <label className="input-label"> Institution </label>
                                    <input type="text"
                                           className="form-control"
                                           name="institution"
                                           placeholder="Enter institution"
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.institution}/>
                                    {formik.touched.institution && formik.errors.institution &&
                                    <FormFeedback>{formik.errors.institution}</FormFeedback>}
                                </div>

                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="js-form-message form-group">
                                            <label className="input-label"> Start Date </label>
                                            <DatePicker
                                                className="form-control"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.start_date && moment(formik.values.start_date, monthFormat)}
                                                onChange={(date, dateString) => {
                                                    formik.values.start_date = dateString;
                                                    formik.handleChange.start_date = dateString;
                                                }} picker="month"/>
                                            {formik.touched.start_date && formik.errors.start_date &&
                                            <FormFeedback>{formik.errors.start_date}</FormFeedback>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="js-form-message form-group">
                                            <label className="input-label"> End Date </label>

                                            <DatePicker
                                                disabled={(formik.values.still_work) && true}
                                                className="form-control"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.end_date && moment(formik.values.end_date, monthFormat)}
                                                onChange={(date, dateString) => {
                                                    formik.values.end_date = dateString;
                                                    formik.handleChange.end_date = dateString;
                                                }} picker="month"/>
                                            {formik.touched.end_date && formik.errors.end_date &&
                                            <FormFeedback>{formik.errors.end_date}</FormFeedback>}

                                            <div className="js-form-message mb-5">
                                                <div
                                                    className="custom-control custom-checkbox d-flex align-items-center text-muted">
                                                    <input type="checkbox"
                                                           className="custom-control-input"
                                                           id="termsCheckbox"
                                                           name="still_work"
                                                           checked={formik.values.still_work}
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}/>
                                                    <label className="custom-control-label"
                                                           htmlFor={'termsCheckbox'}>
                                                        <small>
                                                            I'm currently still here
                                                        </small>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" text-sm-right">
                                    <LaddaButton
                                        loading={laddaLoading}
                                        progress={laddaProgress}
                                        data-style={ZOOM_OUT}
                                        data-spinner-size={30}
                                        data-spinner-color="#fff"
                                        data-spinner-lines={12}
                                        className="btn btn-primary transition-3d-hover"
                                        type="submit"
                                        disabled={formik.isSubmitting}>
                                        {edu ? 'Update' : 'Add'} education
                                    </LaddaButton>
                                </div>

                            </form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </>
    );
}

const mapStateToProps = ({degree, talent}) => {
    return {...degree, ...talent}
}

export default connect(mapStateToProps, {
    getCountryLists,
    talentSaveEducation,
    getEducation,
    deleteEducation
})(Education);
