import React, {useEffect, useState} from 'react';
import ClientHeader from "../../components/ClientHeader";
import ClientMenu from "../../components/ClientMenu";
import {Button, notification, Select, Space, Table, Tag} from "antd";
import {connect, useSelector} from "react-redux";
import {getJobLists} from "../../appRedux/actions/Jobs";
import LinkComponent from "../../route/LinkComponent";
import moment from "moment";
import {LIMIT, NOTIFICATION_DURATION} from "../../appRedux/constants";
import {isEmpty} from "lodash";
import {v4 as uuid} from "uuidv4";
import {FormFeedback, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useFormik} from "formik";
import {handleNotify} from "../../appRedux/response";
import * as Yup from "yup";
import ReactQuill, {Mixin, Toolbar, Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import {getCountryLists} from "../../appRedux/actions/Country";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {clientSavePosting} from "../../appRedux/actions/Clients";

const {Option} = Select;


function ClientJobs(props) {

    const [modal, setModal] = useState(false);
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [paginationSortCurrent, setPaginationSortCurrent] = useState(1);
    const {client_profile: client} = useSelector(state => state.client);

    useEffect(() => {
        props.getJobLists({client_id: client.id, limit: LIMIT});
        props.getCountryLists();
        props.getCountryLists();
    }, []);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <div>
                    <LinkComponent to={`/client/job-details/${record.id}`}>
                        <p className={'h5 d-block'}>{text}</p>
                    </LinkComponent>
                    <p className={'text-body font-size-1 mb-1'}>{record.salary}</p>
                    <hr/>
                    <div className="">
                        {/*<div className="d-flex justify-content-md-end align-items-center">*/}
                        {/*    <span className="d-block small font-weight-bold text-cap mr-2 block" style={{display: 'block'}}>Skills:</span><br/>*/}
                        {/*</div>*/}
                        <div className="d-flex justify-content-md-start align-items-left">
                            <div>
                                {record.skills.map(skill => <small key={uuid}
                                                                   className="btn btn-xs btn-soft-secondary mr-1 mb-1"
                                                                   style={{color: '#00284f'}}>{skill.title}</small>)}
                            </div>
                            {/*<a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" href="#">*/}
                            {/*    <i className="fab fa-facebook-f"></i>*/}
                            {/*</a>*/}

                        </div>
                    </div>

                </div>
            ),
        },
        // {
        //     title: 'Location',
        //     dataIndex: 'location',
        //     key: 'location',
        //     render: (location, record) => (
        //         <div>
        //             <p className={'text-body font-size-1 mb-1'}>{record.location.name}, <br/> {record.location.country.name}</p>
        //         </div>
        //     ),
        // },
        // {
        //     title: 'Skills',
        //     key: 'skills',
        //     dataIndex: 'skills',
        //     render: (skills, record) => (
        //         <>
        //             {skills.map(skill => {
        //                 // let color = tag.length > 5 ? 'geekblue' : 'green';
        //                 // if (tag === 'loser') {
        //                 //     color = 'volcano';
        //                 // }
        //                 return (
        //                     <Tag className={'mb-1'} color={"blue"} key={skill.id}>
        //                         {skill.title.toUpperCase()}
        //                     </Tag>
        //                 );
        //             })}
        //         </>
        //     ),
        // },
        {
            title: 'Applied',
            dataIndex: 'talents',
            key: 'talents',
            render: (location, record) => (
                <div>
                    <p className={'text-body font-size-1 mb-1 text-center'}>{record.talents.length}</p>
                </div>
            ),
        },
        // {
        //     title: 'Created',
        //     dataIndex: 'created',
        //     key: 'created',
        //     render: (created, record) => (
        //         <div>
        //             <p className={'text-body font-size-1 mb-1'}>{moment(created).format("YYYY-MM-DD, h:mm:ss a")}</p>
        //         </div>
        //     ),
        // },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <LinkComponent to={`/client/job-details/${record.id}`}
                                   className={"btn btn-xs btn-ghost-secondary mb-1 mr-1"}> Details </LinkComponent>
                </Space>
            ),
        },
    ];

    const {jobs, jobs_loading, country_loading, country_list,skill_loading, skill_list} = props;

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = {...pagination};
        console.info(pager)
        pager.current = pagination.current;
        setPaginationSortCurrent(pagination.current);
        pagination.current -= 0;
        paginateSort(pagination);
    };

    const paginateSort = (pag) => {
        props.getJobLists({
            client_id: client.id,
            limit: pag.pageSize,
            page: LIMIT * pag.current
        });
    };

    const handlePagination = {
        // position: ["topRight", "none"],
        // showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
        total: !isEmpty(jobs.paging) ? jobs.paging.count : 0,
        current: paginationSortCurrent,
        pageSize: LIMIT
    };

    const newJobSchema = Yup.object().shape({
        title: Yup.string().required('Title is Required'),
        description: Yup.string().required('Description is Required'),
        salary: Yup.string().required('salary is Required'),
        location: Yup.string().required('location is Required'),
        year_experience: Yup.string().required('year_experience is Required'),
        type: Yup.string().required('type is Required'),
        education: Yup.string().required('education is Required'),
        skills: Yup.array().required('skills is Required'),
    });

    const handleIn = res => {
        notification['success']({
            message: 'Job posting created successful',
            description: res.message,
            duration: NOTIFICATION_DURATION,
        });
        setLaddaLoading(false);
        setLaddaProgress(1.0);
        toggle()
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            salary: '',
            location: '',
            year_experience: '',
            type: '',
            education: '',
            skills: '',
        },
        validationSchema: newJobSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            props.clientSavePosting(values).then(res => {
                handleIn(res)
                resetForm(true);
                setSubmitting(false);
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const toggle = () => setModal(!modal);

    const modules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{size: ["small", false, "large", "huge"]}],
                [
                    {list: "ordered"},
                    {list: "bullet"},
                    {indent: "-1"},
                    {indent: "+1"},
                    {align: []}
                ],
                // ["link"],
                ["clean"]
            ],
            // handlers: { image: this.imageHandler }
        },
        // placeholder: 'Compose an epic...',
        clipboard: {matchVisual: false}
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "align"
    ];

    const optionSalary = [
        <Option value={'40,000 - 50,000'}>{'40,000 - 50,000'}</Option>,
        <Option value={'50,000 - 60,000'}>{'50,000 - 60,000'}</Option>,
    ]

    const optionYearExperience = [
        <Option value={'1'}>{'0-3years'}</Option>,
        <Option value={'2'}>{'3-5 years'}</Option>,
        <Option value={'3'}>{'6-10 years'}</Option>,
        <Option value={'4'}>{'More than 10 years'}</Option>,
    ]

    const optionType = [
        <Option value={'remote'}>{'Remote'}</Option>,
        <Option value={'full_time'}>{'Full Time'}</Option>,
        <Option value={'part_time'}>{'Part Time'}</Option>,
        <Option value={'remote_relocate'}>{'Remote / relocate'}</Option>,
    ]

    const optionEducation = [
        <Option value={'1'}>{'Any / None'}</Option>,
        <Option value={'2'}>{'Bachelor\'s degree'}</Option>,
        <Option value={'3'}>{'Masters degree'}</Option>,
        <Option value={'4'}>{'Remote / relocate'}</Option>,
    ]
    let optionSkills = [];
    let optionsC = [];
    for (let country in country_list) {
        optionsC.push(<Option key={country} value={country}>{country_list[country]}</Option>)
    }
    for (let skill in skill_list) {
        optionSkills.push(<Option key={skill} value={skill}>{skill_list[skill]}</Option>)
    }

    return (
        <>
            <div className={'card mb-5'}>
                <div className="card-header border-0 bg-light text-center px-4">
                    <h5 className="card-header-title"> Job Listings
                    </h5>
                    <Button className="btn btn-primary btn-xs transition-3d-hover" onClick={toggle}>
                        Create New Job
                    </Button>
                </div>
                <div className="card-body" style={{'padding': 0}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Table
                                rowKey="id"
                                loading={jobs_loading}
                                columns={columns}
                                pagination={handlePagination}
                                onChange={handleTableChange}
                                dataSource={jobs.content}/>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}> Add New Job </ModalHeader>
                <ModalBody>
                    <form className="js-validate" onSubmit={formik.handleSubmit}>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Title </label>
                            <input type="text"
                                   className="form-control"
                                   name="title"
                                   placeholder="Enter title"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.title}/>
                            {formik.touched.title && formik.errors.title &&
                            <FormFeedback>{formik.errors.title}</FormFeedback>}
                        </div>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Description </label>
                            {/*<ReactQuill*/}
                            {/*    value={formik.values.description}*/}
                            {/*    onChange={formik.handleChange}*/}
                            {/*    // onKeyUp={this.onKeyUp}*/}
                            {/*    // onFocus={this.onFocus}*/}
                            {/*    onBlur={formik.handleBlur}*/}
                            {/*    theme="snow"*/}
                            {/*    modules={modules}*/}
                            {/*    formats={formats}*/}
                            {/*/>*/}
                            <input type="text"
                                   className="form-control"
                                   name="description"
                                   placeholder="Enter description"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.description}/>
                            {formik.touched.description && formik.errors.description &&
                            <FormFeedback>{formik.errors.description}</FormFeedback>}
                        </div>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Salary Range </label>

                            <Select
                                style={{width: '100%'}}
                                className={'select'}
                                size={'large'}
                                name="degree"
                                placeholder="Select salary range"
                                onBlur={formik.handleBlur}
                                onChange={event => {
                                    formik.values.salary = event;
                                    formik.handleChange.salary = event;
                                }}>
                                {optionSalary}
                            </Select>
                            {formik.touched.salary && formik.errors.salary &&
                            <FormFeedback>{formik.errors.salary}</FormFeedback>}
                        </div>
                        <div className="form-group required-field">
                            <label className="input-label"> Location </label>

                            <Select
                                defaultValue={formik.values.location}
                                style={{width: '100%'}}
                                className={'select'}
                                size={'large'}
                                loading={country_loading}
                                name="location"
                                placeholder="Select current location"
                                onBlur={formik.handleBlur}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={event => {
                                    formik.values.location = event;
                                    formik.handleChange.location = event;
                                }}>
                                {optionsC}
                            </Select>
                            {formik.touched.location && formik.errors.location &&
                            <FormFeedback>{formik.errors.location}</FormFeedback>}
                        </div>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Years of Experience </label>

                            <Select
                                style={{width: '100%'}}
                                className={'select'}
                                size={'large'}
                                name="year_experience"
                                placeholder="Select year of experience"
                                onBlur={formik.handleBlur}
                                onChange={event => {
                                    formik.values.year_experience = event;
                                    formik.handleChange.year_experience = event;
                                }}>
                                {optionYearExperience}
                            </Select>
                            {formik.touched.year_experience && formik.errors.year_experience &&
                            <FormFeedback>{formik.errors.year_experience}</FormFeedback>}
                        </div>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Type </label>

                            <Select
                                style={{width: '100%'}}
                                className={'select'}
                                size={'large'}
                                name="type"
                                placeholder="Select type"
                                onBlur={formik.handleBlur}
                                onChange={event => {
                                    formik.values.type = event;
                                    formik.handleChange.type = event;
                                }}>
                                {optionType}
                            </Select>
                            {formik.touched.type && formik.errors.type &&
                            <FormFeedback>{formik.errors.type}</FormFeedback>}
                        </div>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Education Level </label>

                            <Select
                                style={{width: '100%'}}
                                className={'select'}
                                size={'large'}
                                name="education"
                                placeholder="Select education"
                                onBlur={formik.handleBlur}
                                onChange={event => {
                                    formik.values.education = event;
                                    formik.handleChange.education = event;
                                }}>
                                {optionEducation}
                            </Select>
                            {formik.touched.education && formik.errors.education &&
                            <FormFeedback>{formik.errors.education}</FormFeedback>}
                        </div>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Skills </label>

                            <Select
                                style={{width: '100%'}}
                                className={'select select_skill'}
                                size={'large'}
                                loading={skill_loading}
                                name="skills"
                                placeholder="Select skills"
                                mode="multiple"
                                onBlur={formik.handleBlur}
                                onChange={event => {
                                    formik.values.skills = event;
                                    formik.handleChange.skills = event;
                                }}>
                                {optionSkills}
                            </Select>
                            {formik.touched.skills && formik.errors.skills &&
                            <FormFeedback>{formik.errors.skills}</FormFeedback>}
                        </div>
                        <div className=" text-sm-right">
                            <LaddaButton
                                loading={laddaLoading}
                                progress={laddaProgress}
                                data-style={ZOOM_OUT}
                                data-spinner-size={30}
                                data-spinner-color="#fff"
                                data-spinner-lines={12}
                                className="btn btn-primary transition-3d-hover"
                                type="submit"
                                disabled={formik.isSubmitting}>
                                Create Job Posting
                            </LaddaButton>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    );
}

const mapStateToProps = ({client, job, country, skill}) => ({...job, ...client, ...country, ...skill})
export default connect(mapStateToProps, {getJobLists, getCountryLists, clientSavePosting})(ClientJobs);
