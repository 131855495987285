import React, {useEffect, useState} from 'react';
import {Empty, List, Pagination, Select, Skeleton} from 'antd';
import ListProfile from "./ListProfile";
import {LIMIT} from "../../appRedux/constants";
import {connect} from "react-redux";
import {getCountryLists} from "../../appRedux/actions/Country";
import {useFormik} from "formik";
import {v4 as uuid} from 'uuidv4';
import {clearTalentPool, getTalentPool} from "../../appRedux/actions/Clients";
import {isEmpty} from "lodash"
import developer from "../../_assets/media/svg/illustrations/developer.svg";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

const {Option} = Select;

function ClientTalents(props) {

    const [paginationSortCurrent, setPaginationSortCurrent] = useState(1);
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);

    useEffect(() => {
        props.clearTalentPool();
        props.getCountryLists();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = {...pagination};
        pager.current = pagination.current;
        setPaginationSortCurrent(pagination.current);
        pagination.current -= 0;
        paginateSort(pagination);
    };

    const paginateSort = (pag) => {
        props.getJobLists({
            limit: pag.pageSize,
            page: LIMIT * pag.current
        });
    };

    const formik = useFormik({
        initialValues: {
            search: '',
            skills: '',
        },
        onSubmit: (values, {setSubmitting}) => {
            if (!isEmpty(values.search) || !isEmpty(values.skills)) {
                setSubmitting(true);
                setLaddaLoading(true);
                setLaddaProgress(0.5);

                let filter = {limit: LIMIT};
                !isEmpty(values.search) && (filter['search'] = values.search);
                !isEmpty(values.skills) && (filter['skills'] = values.skills.join(','));

                props.getTalentPool(filter).then(res => {
                    clearResponse(setSubmitting)
                }) // TODO not sure if catching error is needed
            } else {
                clearResponse(setSubmitting)
            }
        },
    });

    const clearResponse = (setSubmitting) => {
        setSubmitting(false);
        setLaddaLoading(false);
        setLaddaProgress(1);
    }

    const {skill_loading, skill_list, client_talent_pool, client_talent_pool_loading} = props;
    const optionSkills = [];
    for (let skill in skill_list) {
        optionSkills.push(<Option key={uuid} value={skill}>{skill_list[skill]}</Option>)
    }

    return (
        <>
            <div className={'card mb-5'}>
                <div className="card-header border-0 bg-light text-center px-4">
                    <h5 className="card-header-title"> Talents Pool </h5>
                </div>
                <div className="card-body">
                    <div>

                        <div className={'card p-2 mb-3'}>
                            <form className="js-validate" onSubmit={formik.handleSubmit}>
                                <div className="form-row input-group-borderless">
                                    <div className="col-sm mb-2 mb-md-0">
                                        <div className="input-group input-group-merge input-group-borderless">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fas fa-map-marker-alt"/>
                                                </div>
                                            </div>

                                            <Select
                                                style={{width: '100%', marginLeft: '32px'}}
                                                className={'select select_skill'}
                                                loading={skill_loading}
                                                name="skills"
                                                allowClear
                                                placeholder="Select skills"
                                                mode="multiple"
                                                onBlur={formik.handleBlur}
                                                onChange={event => {
                                                    formik.values.skills = event;
                                                    formik.handleChange.skills = event;
                                                }}>
                                                {optionSkills}
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="col-sm d-sm-none">
                                        <hr className="my-0"/>
                                    </div>

                                    <div className="col-sm column-divider-sm mb-2 mb-md-0">
                                        <div className="input-group input-group-merge input-group-borderless">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fas fa-search"/>
                                                </div>
                                            </div>

                                            <Select
                                                style={{width: '100%', marginLeft: '32px'}}
                                                className={'select'}
                                                name="search"
                                                allowClear
                                                placeholder="Select years"
                                                onBlur={formik.handleBlur}
                                                // onChange={formik.handleChange}
                                                // value={formik.values.size}
                                                onChange={event => {
                                                    formik.values.search = event;
                                                    formik.handleChange.search = event;
                                                }}>
                                                <Option value="1"> 1 years </Option>
                                                <Option value="2"> 2 years </Option>
                                                <Option value="3"> 3 years </Option>
                                                <Option value="4"> 4 years </Option>
                                                <Option value="5"> 5 years </Option>
                                                <Option value="6"> 6 years </Option>
                                                <Option value="7"> 7 years </Option>
                                                <Option value="8"> 8 years </Option>
                                                <Option value="9"> 9 years </Option>
                                                <Option value="10"> Less than 10 years </Option>
                                            </Select>

                                        </div>
                                    </div>

                                    <div className="col-md-auto">
                                        <LaddaButton
                                            loading={laddaLoading}
                                            progress={laddaProgress}
                                            data-style={ZOOM_OUT}
                                            data-spinner-size={30}
                                            data-spinner-color="#fff"
                                            data-spinner-lines={12}
                                            className="btn btn-block btn-primary px-5"
                                            type="submit"
                                            disabled={formik.isSubmitting}>
                                            Filter
                                        </LaddaButton>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {client_talent_pool_loading ? <div>
                            <Skeleton active/>
                            <Skeleton active/>
                        </div> : <>{isEmpty(client_talent_pool.content) ? <Empty imageStyle={{
                            height: 440,
                        }} description={
                            <><h1 style={{display: 'none'}}>Ups!... no results found</h1></>
                        } image={developer}/> : <><List
                            dataSource={client_talent_pool.content}
                            renderItem={pool => <><List.Item>
                                    <ListProfile key={uuid} pool={pool}/>
                            </List.Item></>}
                        />
                            <div className={'d-flex justify-content-between align-items-center mt-7'}>
                                <Pagination
                                    onChange={handleTableChange}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                    defaultCurrent={paginationSortCurrent}/>
                            </div>
                        </>}</>}

                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({skill, client}) => ({...skill, ...client});
export default connect(mapStateToProps, {getCountryLists, getTalentPool, clearTalentPool})(ClientTalents);
