import React from 'react';
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import LinkComponent from "../../route/LinkComponent";
import TalentMenu from "../../components/TalentMenu";
import {userSignOut} from "../../appRedux/actions/Talents";
import {useDispatch} from "react-redux";


const InnerLanding = (Component) => {

    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(userSignOut());
    }

    return (props) => (
    <>
        <div className={'landing'}>
            <Header/>

            <main id="content" role="main" className="bg-light" style={{marginTop: '72px'}}>
                <div>
                    <div className="bg-dark shapes-20">
                        <div className="container space-1 space-top-lg-2 space-bottom-lg-3">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="d-none d-lg-block">
                                        <h1 className="h2 text-white"> Talent Personal info</h1>
                                    </div>

                                    <ol className="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
                                        <li className="breadcrumb-item">Account</li>
                                        <li className="breadcrumb-item active" aria-current="page">Personal info</li>
                                    </ol>
                                </div>

                                <div className="col-auto">
                                    <div className="d-none d-lg-block">
                                        <p className="btn btn-sm btn-soft-light" onClick={signOut} >Log out</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container space-1 space-top-lg-0 space-bottom-lg-2 mt-lg-n10">
                        <div className="row">
                            <TalentMenu/>
                            <div className="col-lg-9">
                                <div className="">
                                    <Component {...props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    </>
)}

export default InnerLanding;
