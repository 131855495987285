import React, {useCallback, useEffect, useState} from 'react';
import {ReactComponent as LogoWhite} from "../../_assets/media/svg/logo_white.svg";
import {ReactComponent as Logo} from "../../_assets/media/svg/logo.svg";
import LinkComponent from "../../route/LinkComponent";
import {useLocation} from "@reach/router";
import {PENDING_TALENT_ACCOUNT} from "../../appRedux/constants";
import {useSelector} from "react-redux";
import {redirectToProfile} from "../../route/Redirect";
import {Spin} from "antd";
import {isEmpty} from "lodash";
import {Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

// import { Dropdown } from 'antd';


function Header(props) {

    const [white, setWhite] = useState(true);
    const [visible, setVisible] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [alterClass, setAlterClass] = useState(false);
    const [avatarStyle, setAvatarStyle] = useState({background: '#1d315e'});

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const location = useLocation();
    const {talent_profile, talent_profile_loading} = useSelector(state => state.talent)
    const {client_profile, client_profile_loading} = useSelector(state => state.client)

    useEffect(() => {
        if (!isEmpty(talent_profile.avatar)) {
            setAvatarStyle({background: `url(${talent_profile.avatar})`, backgroundSize: 'contain'});
        }
    }, [talent_profile])

    const handleScroll = useCallback(() => {
        const top = window.scrollY;
        // if (location.pathname === '/') {
        //     // if (top > 600) setWhite(false);
        //     // if (top < 600) setWhite(true);
        //
        //     if (top >= 600) setAlterClass(true);
        //     if (top < 600) setAlterClass(false);
        // }

        function checkLocationWithParams(path) {
            const parts = location.pathname.split('/');
            return parts[1] === path;
        }

        if (location.pathname === '/jobs'
            || location.pathname === '/terms'
            || location.pathname === '/help/faqs'
            || checkLocationWithParams('job-overview'))
        {
            if (top > 300) setWhite(false);
            if (top < 300) setWhite(true);

            // if (top >= 300) setAlterClass(true);
            // if (top < 300) setAlterClass(false);
            setAlterClass(true);
        }

        if (location.pathname === '/') {
            if (top > 20) setWhite(false);
            if (top < 300) setWhite(true);

            // if (top >= 300) setAlterClass(true);
            // if (top < 300) setAlterClass(false);
            setAlterClass(true);
        }

        if (checkLocationWithParams('profile')
            || location.pathname === '/overview'
            || checkLocationWithParams('client')
        ) {

            // if (top >= 300) setAlterClass(true);
            // if (top < 300) setAlterClass(false);
            setAlterClass(true);
            setWhite(false);
        }
    }, [alterClass])

    useEffect(() => {
        handleScroll()
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, []);
    // ${alterClass ? "header-top" : "header-fix-top" }

    return (
        <>
            {}
            <header id="header"
                    className={`header header-box-shadow-on-scroll header-fix-top header-bg-transparent-lg header-show-hide-lg`}>
                <div className={`header-section ${white ? 'text-white' : 'text-bg'}`}>
                    {/*<div className="container header-hide-content pt-2 d-flex justify-content-between">*/}
                    {/*    <div className="d-flex align-items-center navbar-nav-item mr-auto">*/}
                    {/*        /!*<ul className="nav nav-sm nav-x-sm align-items-center">*!/*/}
                    {/*        /!*    <li className="nav-item">*!/*/}
                    {/*        /!*        <a className="nav-link" href="#"> hi@borderless.com </a>*!/*/}
                    {/*        /!*    </li>*!/*/}
                    {/*        /!*</ul>*!/*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex justify-content-end navbar-nav-item">*/}
                    {/*        <ul className={`nav nav-sm nav-x-sm align-items-center ${white && 'text-white'}`}>*/}
                    {/*            <li className="nav-item">*/}
                    {/*                <LinkComponent to={"/policy"} className="nav-link ">Privacy Policy</LinkComponent>*/}
                    {/*            </li>*/}
                    {/*            <li className="nav-item opacity mx-3">/</li>*/}
                    {/*            <li className="nav-item">*/}
                    {/*                <LinkComponent className="nav-link"*/}
                    {/*                               to={"/terms"}>Terms &amp; Condition</LinkComponent>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div id="logoAndNav" className="container">
                        <nav className="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
                            <LinkComponent className="navbar-brand navbar-nav-wrap-brand" to={'/'}>
                                {white ? <Logo/> : <Logo/>}
                            </LinkComponent>

                            <button type="button"
                                    className="navbar-toggler btn btn-icon btn-sm btn-soft-secondary rounded-circle"
                                    aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar"
                                    data-toggle="collapse" data-target="#navBar">
                            </button>

                            <div id="navBar" className="collapse navbar-collapse">
                                <ul className="navbar-nav">

                                    {/*<li className="navbar-nav-item">*/}
                                    {/*    <LinkComponent className="nav-link text-muted" to="/client">*/}
                                    {/*        For Companies*/}
                                    {/*    </LinkComponent></li>*/}

                                    {!isEmpty(client_profile) &&
                                    <li className="navbar-nav-item">
                                        <LinkComponent className="nav-link text-muted" to="/client">
                                            Client Account
                                        </LinkComponent></li>}
                                    {!isEmpty(talent_profile) &&
                                    <li className="navbar-nav-item">
                                        <LinkComponent className="nav-link text-muted" to="/profile">
                                            Talent Account
                                        </LinkComponent></li>}


                                    {(isEmpty(talent_profile) && isEmpty(client_profile)) && <>
                                        {/*<li className="navbar-nav-item">*/}
                                        {/*    <LinkComponent className={`nav-link ${alterClass ? "alter" : ""}`} to={"/jobs"}>Postings</LinkComponent>*/}
                                        {/*</li>*/}
                                        <li className="navbar-nav-item">
                                            <LinkComponent className={`nav-link ${alterClass ? "alter" : ""}`}
                                                           to={"/talents/signup"}> For Talents </LinkComponent>
                                        </li>
                                        <li className="navbar-nav-last-item">
                                            <LinkComponent
                                                className="btn btn-sm btn-primary transition-3d-hover"
                                                to={"/client/signup"}>
                                                For Companies
                                            </LinkComponent>
                                        </li>
                                    </>}

                                    {/*{(!isEmpty(talent_profile) || !isEmpty(client_profile)) && <>*/}
                                    {/*    <li className="navbar-nav-item">*/}
                                    {/*        <LinkComponent*/}
                                    {/*            className="btn btn btn-light transition-3d-hover btn-sm"*/}
                                    {/*            to={"/jobs"}> Postings </LinkComponent>*/}
                                    {/*    </li>*/}
                                    {/*</>}*/}

                                    {(!isEmpty(talent_profile) || !isEmpty(client_profile)) &&
                                    <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle className="btn-trans">
                                            <div className="d-flex justify-content-between">
                                                <span className="ava_header" style={avatarStyle}/>
                                                {/*<span className="mr-10"> Olatunde </span>*/}
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            className="dropdown-menu dropdown-menu-sm-right dropdown-menu-no-border-on-mobile p-0 hs-unfold-css-animation animated"
                                            style={{minWidth: '245px', animationDuration: '50ms'}} right>
                                            <div className="card">
                                                <div className="card-header">
                                                    <a className="media" href="#">
                                                        {/*<div className="avatar mr-3">*/}
                                                        {/*    <img className="avatar-img"*/}
                                                        {/*         src="../assets/img/100x100/img1.jpg"/>*/}
                                                        {/*</div>*/}

                                                        <div className="media-body">
                                                        {client_profile_loading && !isEmpty(client_profile) ? <Spin/>
                                                            : <>
                                                                <span className="d-block font-weight-bold"> {client_profile.name} </span>
                                                                <span className="d-block small text-muted"> {client_profile.email} </span>
                                                            </>}
                                                        {talent_profile_loading && !isEmpty(talent_profile) ? <Spin/>
                                                            : <>
                                                                <span className="d-block font-weight-bold"> {talent_profile.name} </span>
                                                                <span className="d-block small text-muted"> {talent_profile.email} </span>
                                                            </>}
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="card-body py-3">
                                                    {/*                                <a className="dropdown-item px-0" href="#">*/}
                                                    {/*<span className="dropdown-item-icon">*/}
                                                    {/*  <i className="fas fa-comments"></i>*/}
                                                    {/*</span> Messages*/}
                                                    {/*                                </a>*/}
                                                    {/*                                <a className="dropdown-item d-lg-none" href="#">*/}
                                                    {/*<span className="dropdown-item-icon">*/}
                                                    {/*  <i className="fas fa-tasks"></i>*/}
                                                    {/*</span> My courses*/}
                                                    {/*                                </a>*/}
                                                    {/*                                <a className="dropdown-item px-0" href="#">*/}
                                                    {/*<span className="dropdown-item-icon">*/}
                                                    {/*  <i className="fas fa-database"></i>*/}
                                                    {/*</span> Purchase history*/}
                                                    {/*                                </a>*/}
                                                    {!isEmpty(client_profile) &&
                                                    <LinkComponent className="dropdown-item px-0" to="/client">
                                                            <span className="dropdown-item-icon">
                                                              <i className="fas fa-user"/>
                                                            </span> Account
                                                    </LinkComponent>}
                                                    {!isEmpty(talent_profile) &&
                                                    <LinkComponent className="dropdown-item px-0" to="/profile">
                                                            <span className="dropdown-item-icon">
                                                              <i className="fas fa-user"/>
                                                            </span> Account
                                                    </LinkComponent>}
                                                    {/*                                <a className="dropdown-item px-0" href="#">*/}
                                                    {/*<span className="dropdown-item-icon">*/}
                                                    {/*  <i className="fas fa-credit-card"></i>*/}
                                                    {/*</span> Payment methods*/}
                                                    {/*                                </a>*/}

                                                    <div className="dropdown-divider"/>
                                                    <LinkComponent className="dropdown-item px-0" to="/help">
                                                            <span className="dropdown-item-icon">
                                                              <i className="fas fa-question-circle"/>
                                                            </span> Help
                                                    </LinkComponent>
                                                    <LinkComponent className="dropdown-item px-0" to="/logout">
                                                            <span className="dropdown-item-icon">
                                                              <i className="fas fa-power-off"/>
                                                            </span> Log out
                                                    </LinkComponent>
                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>}

                                    {(isEmpty(talent_profile) && isEmpty(client_profile)) &&
                                    <li className="navbar-nav-last-item">
                                        <LinkComponent className={`nav-link ${alterClass ? "alter" : ""}`}
                                                       to={"/login"}> Login </LinkComponent>
                                    </li>
                                    }


                                    {/*{client_profile_loading ? <Spin/> : <>*/}
                                    {/*    {!isEmpty(client_profile) && <li className="navbar-nav-last-item">*/}
                                    {/*        <LinkComponent to={'/client'}>*/}
                                    {/*            <div className={"ava_header transition-3d-hover"}/>*/}
                                    {/*        </LinkComponent>*/}
                                    {/*    </li>*/}
                                    {/*    }*/}
                                    {/*</>}*/}
                                    {/*{talent_profile_loading ? <Spin/> : <>*/}
                                    {/*    {!isEmpty(talent_profile) && <li className="navbar-nav-last-item">*/}
                                    {/*        <LinkComponent to={'/profile'}>*/}
                                    {/*            <div className={"ava_header transition-3d-hover"}/>*/}
                                    {/*        </LinkComponent>*/}
                                    {/*    </li>}*/}
                                    {/*</>}*/}

                                </ul>
                            </div>
                        </nav>

                        {/*<nav className="js-mega-menu navbar navbar-expand-lg">*/}
                        {/*    <div className="navbar-nav-wrap">*/}
                        {/*        <LinkComponent className="navbar-brand navbar-nav-wrap-brand" to={'/'}>*/}
                        {/*            {white ? <LogoWhite style={{height: '32px', width: 'auto'}}/> : <Logo style={{height: '32px', width: 'auto'}}/> }*/}
                        {/*        </LinkComponent>*/}

                        {/*        <div className="navbar-nav-wrap-content text-center">*/}
                        {/*            <LinkComponent to={'/talents/login'} className="nav-link d-inline-block" > Login </LinkComponent>*/}
                        {/*            <LinkComponent to={'/get_onboard'} className="btn btn-sm btn-primary btn-pill d-none d-sm-inline-block"> Hire Talent </LinkComponent>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</nav>*/}
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
