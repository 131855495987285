import React, {useLayoutEffect} from "react";
import {Router, useLocation} from "@reach/router";

import Routes from "./index";
import AppComponent from "./AppComponent";
import Missing404 from "../components/NotFound/Missing404";
import {AppContext as aContext} from "./AppContext";
import {InterfaceConfig} from "./InterfaceConfig";
import {useSelector} from "react-redux";
import Loading from "../components/Loading/Loading";


function RouteComponent(props) {
    const {location} = props;

    const locationHook = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [locationHook.pathname]);

    // const {talent_profile} = useSelector(state => state.talent);
    // console.log('talent_profile', talent_profile);

    /**
     * App Setting config
     * @type {React.Context<{ACCESS_WIDTH, APP_NAME}>}
     */
    const AppContext = aContext;

    const RouteList = Routes.map((route, index) => {
        let routeComponent = null;
        let component = route.component;
        let layout = route.layout;

        if (typeof route.children !== "undefined") {
            const childrens = route.children;
            const childrenComponent = childrens.map((route, index) => (
                <AppComponent
                    key={index}
                    route={route}
                    path={route.path}
                    component={component}
                    layout={layout}
                    auth={route.auth ? route.auth : false}
                    location={location}
                />
            ));

            routeComponent = (
                <AppComponent
                    route={route}
                    key={index}
                    path={route.path}
                    component={component}
                    layout={layout}
                    auth={route.auth ? route.auth : false}
                    location={location}
                >
                    {childrenComponent}
                </AppComponent>
            );
        } else {
            routeComponent = (
                <AppComponent
                    key={index}
                    route={route}
                    path={route.path}
                    component={component}
                    layout={layout}
                    auth={route.auth ? route.auth : false}
                    location={location}
                />
            );
        }
        return routeComponent;
    });

    return (
        <>
            <AppContext.Provider value={InterfaceConfig}>
                <Router location={location}>
                    {RouteList}
                    <Missing404 default/>
                </Router>
            </AppContext.Provider>
        </>
    );
}

export default RouteComponent;
