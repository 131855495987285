import { useEffect } from "react";
import {setSessionWithValue} from "../../appRedux/store/cookies"
import {redirectToTalentSignup} from "../../route/Redirect"

const TalentReferral = (props) => {
    const ref_code = props.referral_code;

    useEffect(() => {
        setSessionWithValue(ref_code, 'ref_code')
        redirectToTalentSignup();
    }, [])

    return <>
        <h4>Loading ...</h4>
    </>
} 

export default TalentReferral;