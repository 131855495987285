import {
    Login,
    ForgotPassword,
    SignUp,
    Overview,
    Talents,
    ProjectDetails,
    ClientLogin,
    Client, ClientJobs,
    OnBoard, ClientJobDetail, ClientForgotPassword,
    TalentAccount, ClientAccount, TalentInfo,
    Policy, Terms, HelpDesk,
    ClientSignUp, TalentJobDetails,
    Jobs,
    JobOverview,
    Profile, ClientTalents,
    ProfileAppliedJobs, TalentGetVetted,
    Home, Faqs, ResetPassword, ResumeUpload
} from "../containers";
import TalentReferral from "../containers/TalentReferral";
import HomeNew from "../containers/Home/Home_new";

import {Access, Inner, ClientAccess, Landing, LandingPage, InnerLanding, InnerLandingClient, Auth, AuthClient, Pending} from "../layouts";

const Routes = [
    {path: "/", component: HomeNew, layout: Landing},
    {path: "/home", component: HomeNew, layout: Landing}, //HomeNew
    {path: "/jobs", component: Jobs, layout: Landing},
    {path: "/job-overview/:job_id", component: JobOverview, layout: Landing},
    {path: "/get_onboard", component: OnBoard, layout: Landing},

    {path: "/overview", component: Overview, layout: InnerLanding, auth: true},
    {path: "/profile", component: Profile, layout: InnerLanding, auth: true},
    {path: "/profile/info", component: TalentInfo, layout: InnerLanding, auth: true},
    {path: "/profile/applied-jobs", component: ProfileAppliedJobs, layout: InnerLanding, auth: true},
    {path: "/profile/account", component: TalentAccount, layout: InnerLanding, auth: true},
    {path: "/profile/get-vetted", component: TalentGetVetted, layout: InnerLanding, auth: true},
    {path: "/profile/job-details/:job_id", component: TalentJobDetails, layout: InnerLanding, auth: true},

    // {path: "/logout"},
    {path: "/logout"},
    {path: "/login", component: Login, layout: Auth},
    {path: "/talents/login", component: Login, layout: Auth},
    {path: "/talents/resume", component: ResumeUpload, layout: Pending},
    {path: "/talents/forgot-password", component: ForgotPassword, layout: Auth},
    {path: "/talents/verify-forgot-password/:activation_code/:code", component: ResetPassword, layout: Auth},
    {path: "/talents/verify-forgot-password/:activation_code", component: ResetPassword, layout: Auth},
    {path: "/talents/signup", component: SignUp, layout: Auth},
    {path: "/talents/referral/:referral_code", component: TalentReferral, layout: Access},

    {path: "/client", component: Client, layout: InnerLandingClient},
    {path: "/client/account", component: ClientAccount, layout: InnerLandingClient},
    {path: "/client/jobs", component: ClientJobs, layout: InnerLandingClient},
    {path: "/client/talents-pool", component: ClientTalents, layout: InnerLandingClient},
    {path: "/client/job-details/:job_id", component: ClientJobDetail, layout: InnerLandingClient},
    {path: "/client/login", component: ClientLogin, layout: AuthClient},
    {path: "/client/signup", component: ClientSignUp, layout: AuthClient},
    {path: "/client/forgot-password", component: ClientForgotPassword, layout: AuthClient},
    {path: "/client/verify-forgot-password/:activation_code/:code", component: ResetPassword, layout: AuthClient},
    {path: "/client/verify-forgot-password/:activation_code", component: ResetPassword, layout: AuthClient},

    {path: "/help", component: HelpDesk, layout: Landing},
    {path: "/help/faqs", component: Faqs, layout: Landing},
    {path: "/policy", component: Policy, layout: Landing},
    {path: "/terms", component: Terms, layout: Landing},

    {path: "/overview", component: Overview, layout: Inner},
    {path: "/talents", component: Talents, layout: Inner},
    {path: "/project_details", component: ProjectDetails, layout: Inner},
];

export default Routes;
export {default as RouteComponent} from "./RouteComponent";
