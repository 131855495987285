import {
    SETTING_CALL_START, SETTING_CALL_END, TOP_DEVELOPERS_END, TOP_DEVELOPERS_START, TOP_DEVELOPERS_ENDED
} from '../constants';

const INIT_STATE = {
    top_loading: false,
    tops: [],
    setting_loading: false,
    content: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SETTING_CALL_START: {
            return {...state, setting_loading: true};
        }
        case SETTING_CALL_END: {
            return {...state, setting_loading: false, content: action.payload};
        }
        case TOP_DEVELOPERS_START: {
            return {...state, top_loading: true};
        }
        case TOP_DEVELOPERS_ENDED: {
            return {...state, top_loading: false};
        }
        case TOP_DEVELOPERS_END: {
            return {...state, top_loading: false, tops: action.payload};
        }
        default:
            return state;
    }
};
