import React from 'react';
import LinkComponent from "../../route/LinkComponent";
import {Row , Col} from "antd";
// import {FrontHeader} from "../../components/Header";
// import {ReactComponent as Logo} from "../../assets/svg/logo.svg";

const Access = (Component) => (props) => (
    <>
        <div className="d-flex flex-column flex-root" style={{height: "100vh"}}>
            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="bh_login">
                <div className="access user-login login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10">
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        {/*<LinkComponent to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">*/}
                        {/*    <img*/}
                        {/*        alt="Logo"*/}
                        {/*        className="max-h-70px"*/}
                        {/*        src="../src/media/borderless.png"*/}
                        {/*    />*/}
                        {/*</LinkComponent>*/}

                        <div className="flex-column-fluid d-flex flex-column justify-content-center">
                            <h3 className="font-size-h1 mb-5 text-white">
                                 Borderless!
                            </h3>
                            <p className="text-white opacity-80">
                                We empower businesses to directly access top pre-vetted, <br/>experienced African Tech Talents.
                            </p>
                        </div>

                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                            <div className="opacity-70 font-weight-bold	text-white">
                                &copy; 2020 Borderless
                            </div>
                            <div className="d-flex">
                                <LinkComponent to="/terms" className="text-white">
                                    Privacy
                                </LinkComponent>
                                <LinkComponent to="/terms" className="text-white ml-10">
                                    Legal
                                </LinkComponent>
                                <LinkComponent to="/terms" className="text-white ml-10">
                                    Contact
                                </LinkComponent>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
              {/*      <div*/}
              {/*          className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">*/}
              {/*<span className="font-weight-bold text-dark-50">*/}
              {/*  Don't have an account yet?*/}
              {/*</span>*/}
              {/*          <LinkComponent to="/signup"*/}
              {/*              className="font-weight-bold ml-2"*/}
              {/*              id="bh_login_signup">*/}
              {/*              Sign Up!*/}
              {/*          </LinkComponent>*/}
              {/*      </div>*/}

                    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0" >
                        <Row style={{width: '420px'}}>
                            <Col span={24}>
                                <Component {...props}/>
                            </Col>
                        </Row>
                    </div>

                    <div
                        className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                        <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                            &copy; 2020 Borderless
                        </div>
                        <div className="d-flex order-1 order-sm-2 my-2">
                            <LinkComponent to="/terms" className="text-dark-75 text-hover-primary">
                                Privacy
                            </LinkComponent>
                            <LinkComponent to="/terms" className="text-dark-75 text-hover-primary ml-4"
                            > Legal
                            </LinkComponent>
                            <LinkComponent
                                to="/terms"
                                className="text-dark-75 text-hover-primary ml-4"
                            > Contact
                            </LinkComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default Access;
