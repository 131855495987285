import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getJobLists, searchJobLists} from "../../appRedux/actions/Jobs";
import LinkComponent from "../../route/LinkComponent";
import {Loading} from "../../components/Loading";
import {isEmpty} from "lodash";
import {Pagination, Select, Skeleton, Empty} from "antd";
import {useFormik} from "formik";
import * as Yup from "yup";
import {LIMIT} from "../../appRedux/constants";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {getCountryLists} from "../../appRedux/actions/Country";
import empty from "../../_assets/media/svg/illustrations/empty.svg"
import {v4 as uuid} from 'uuidv4';

const {Option} = Select;

function Jobs(props) {

    const [paginationSortCurrent, setPaginationSortCurrent] = useState(1);
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);

    const [selected_skills, setSelectedSkills] = useState([]);
    const [selected_education, setSelectedEducation] = useState([]);
    const [selected_years_of_experience, setSelectedYearsOfExperience] = useState([]);

    const [current_search_mode, setCurrentSearchMode] = useState('main');

    useEffect(() => {
        props.getJobLists({limit: LIMIT});
        props.getCountryLists();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = {...pagination};
        pager.current = pagination.current;
        setPaginationSortCurrent(pagination.current);
        pagination.current -= 0;
        paginateSort(pagination);
    };

    const paginateSort = (pag) => {
        let filter = {};
        if(current_search_mode === 'main'){
            !isEmpty(formik.values.search) && (filter['search'] = formik.values.search);
            !isEmpty(formik.values.location) && (filter['location_id'] = formik.values.location);
        }else{
            !isEmpty(selected_education) && (filter['education'] = selected_education.join(','))
            !isEmpty(selected_years_of_experience) && (filter['year_of_experience'] = selected_years_of_experience.join(','))
            !isEmpty(selected_skills) && (filter['skills'] = selected_skills.join(','))
        }

        props.getJobLists({
            ...filter,
            limit: pag.pageSize,
            page: LIMIT * pag.current
        });
    };

    const education_checked = (id) => {
        return selected_education.find(e => e === id) !== undefined;
    }

    const year_experience_checked = (id) => {
        return selected_years_of_experience.find(e => e === id) !== undefined;
    }

    const handleSelect = (type, id) => event => {

        if(type === 'education'){
            selected_education.find(e => e === id) === undefined ?
                setSelectedEducation(selected_education.concat(id)) :
                setSelectedEducation(selected_education.filter(e => e !== id))
        }
        if(type === 'yoe'){
            selected_years_of_experience.find(e => e === id) === undefined ?
                setSelectedYearsOfExperience(selected_years_of_experience.concat(id)) :
                setSelectedYearsOfExperience(selected_years_of_experience.filter(e => e !== id))
        }
    }

    const SidebarSearch = () => {
        let filter = {limit: LIMIT};
        !isEmpty(selected_education) && (filter['edu'] = selected_education.join(','))
        !isEmpty(selected_years_of_experience) && (filter['ex'] = selected_years_of_experience.join(','))
        !isEmpty(selected_skills) && (filter['skills'] = selected_skills.join(','))

        setCurrentSearchMode('sidebar');

        props.getJobLists(filter);
    }

    const formik = useFormik({
        initialValues: {
            search: '',
            location: '',
            skills: '',
        },
        onSubmit: (values, {setStatus, setSubmitting}) => {
            if (!isEmpty(values)) {
                setCurrentSearchMode('main');
                setSubmitting(true);
                setLaddaLoading(true);
                setLaddaProgress(0.5);

                let filter = {limit: LIMIT};
                !isEmpty(values.search) && (filter['search'] = values.search);
                !isEmpty(values.location) && (filter['location_id'] = values.location);
                props.getJobLists(filter);

                setSubmitting(false);
                setLaddaLoading(false);
                setLaddaProgress(1);
            }
        },
    });

    const {jobs, jobs_loading, location_list, location_loading, skill_loading, skill_list} = props;
    const {year_experience_list, eduction_list} = jobs;

    let optionLocation = [];
    const optionSkills = [];
    for (let skill in skill_list) {
        optionSkills.push(<Option key={uuid} value={skill}>{skill_list[skill]}</Option>)
    }
    for (let location in location_list) {
        optionLocation.push(<Option key={uuid} value={location}>{location_list[location]}</Option>)
    }

    return (
        <>
            <div>
                <div className="gradient-x-three-sm-primary">
                    <div className="container space-top-2 space-top-lg-4 space-bottom-2">

                        <div className="w-lg-80 text-center text-lg-left mb-5 mb-lg-7">
                            <h1 className="display-4">Find the most<br/><span
                                className="text-primary text-highlight-warning">exciting</span> remote jobs</h1>
                        </div>

                        <div className="card p-2 mb-3">
                            <form className="js-validate" onSubmit={formik.handleSubmit}>
                                <div className="form-row input-group-borderless">
                                    <div className="col-sm mb-2 mb-md-0">
                                        <div className="input-group input-group-merge input-group-borderless">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fas fa-search"/>
                                                </div>
                                            </div>

                                            <input type="text"
                                                   className="form-control"
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   value={formik.values.search}
                                                   name="search"
                                                   placeholder="Search Job title"/>
                                        </div>
                                    </div>

                                    <div className="col-sm d-sm-none">
                                        <hr className="my-0"/>
                                    </div>

                                    <div className="col-sm column-divider-sm mb-2 mb-md-0">
                                        <div className="input-group input-group-merge input-group-borderless">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">
                                                    <i className="fas fa-map-marker-alt"/>
                                                </div>
                                            </div>

                                            <Select
                                                style={{width: '100%', marginLeft: '32px'}}
                                                className={'select'}
                                                loading={location_loading}
                                                name="location"
                                                allowClear
                                                size={'large'}
                                                placeholder="Select city or state,"
                                                onBlur={formik.handleBlur}
                                                onChange={event => {
                                                    formik.values.location = event;
                                                    formik.handleChange.location = event;
                                                }}>
                                                {optionLocation}
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="col-md-auto">
                                        <LaddaButton
                                            loading={laddaLoading}
                                            progress={laddaProgress}
                                            data-style={ZOOM_OUT}
                                            data-spinner-size={30}
                                            data-spinner-color="#fff"
                                            data-spinner-lines={12}
                                            className="btn btn-block btn-primary px-5"
                                            type="submit"
                                            disabled={formik.isSubmitting}>
                                            Search
                                        </LaddaButton>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-auto mr-n3 mb-3 mb-lg-0">
                                <h6 className="mb-0">Limit search to:</h6>
                            </div>

                            <div className="col-md mb-3 mb-lg-0">
                                {/*<ul className="list-inline mb-0">*/}
                                {/*    <li className="list-inline-item">*/}
                                {/*        <div className="custom-control custom-checkbox">*/}
                                {/*            <input type="checkbox" className="custom-control-input"*/}
                                {/*                   id="searchJobTitleCheck" checked=""/>*/}
                                {/*            <label className="custom-control-label" htmlFor="searchJobTitleCheck">Job*/}
                                {/*                title</label>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}

                                {/*    <li className="list-inline-item">*/}
                                {/*        <div className="custom-control custom-checkbox">*/}
                                {/*            <input type="checkbox" className="custom-control-input"*/}
                                {/*                   id="searchSkillsCheck"/>*/}
                                {/*            <label className="custom-control-label"*/}
                                {/*                   htmlFor="searchSkillsCheck">Skills</label>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}

                                {/*    <li className="list-inline-item">*/}
                                {/*        <div className="custom-control custom-checkbox">*/}
                                {/*            <input type="checkbox" className="custom-control-input"*/}
                                {/*                   id="searchCompaniesCheck"/>*/}
                                {/*            <label className="custom-control-label"*/}
                                {/*                   htmlFor="searchCompaniesCheck">Companies</label>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}

                                {/*    <li className="list-inline-item">*/}
                                {/*        <div className="custom-control custom-checkbox">*/}
                                {/*            <input type="checkbox" className="custom-control-input"*/}
                                {/*                   id="searchStudyFieldCheck"/>*/}
                                {/*            <label className="custom-control-label" htmlFor="searchStudyFieldCheck">Filed*/}
                                {/*                of study</label>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                            </div>

                            <div className="col-lg-auto">
                                <label className="toggle-switch d-flex align-items-center" htmlFor="remoteOnlySwitch">
                                    <input type="checkbox"
                                           name={'type'}
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.type} className="toggle-switch-input"
                                           id="remoteOnlySwitch"/>
                                    <span className="toggle-switch-label">
                                        <span className="toggle-switch-indicator"/>
                                      </span>
                                    <span className="toggle-switch-content">
                                        <span className="d-block">Remote only</span>
                                      </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container space-2 space-bottom-lg-3">
                    <div className="row">
                        <div className={'col-lg-9'}>
                            {jobs_loading ? <div>
                                <Skeleton active/>
                                <Skeleton active/>
                                <Skeleton active/>
                            </div> : <>
                                {/*<div className="row align-items-sm-center">*/}
                                {/*    <div className="col-sm mb-3 mb-sm-0">*/}
                                {/*        <h3 className="mb-0">{!isEmpty(jobs.paging) ? jobs.paging.count : 0} Jobs</h3>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<hr className="my-4"/>*/}
                                {isEmpty(jobs.content) ? <Empty imageStyle={{
                                        height: 440,
                                    }} description={
                                         <><h1>Ups!... no results found</h1></>
                                    } image={empty}/> :
                                    <>{jobs.content.map(job =>
                                        <div key={uuid} className="card card-bordered card-hover-shadow mb-5">
                                            <div className="card-body">
                                                <div className="d-sm-flex">

                                                    <div className="media-body">
                                                        <div className="row">
                                                            <div className="col col-md-8">
                                                                <h3 className="text-hover-primary">
                                                                    <LinkComponent className="text-dark"
                                                                                   to={`/job-overview/${job.id}`}>
                                                                        {job.title}
                                                                    </LinkComponent>
                                                                </h3>
                                                                <div className={'skill_holder'}>
                                                                    {job.skills.map(skill => <small key={uuid}
                                                                                                    className="btn btn-xs btn-ghost-secondary">{skill.title}</small>)}
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md mt-3 mt-md-0 text-right">
                                                                <span className="badge badge-soft-info">
                                                                <span className="legend-indicator bg-info"/>{job.type}
                                                                </span>
                                                                <span className="d-block font-size-1 text-body mb-1">{job.year_experience} experience</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <ul className="list-inline list-separator small text-body">
                                                    {/*<li className="list-inline-item">Posted {job.created}</li>*/}
                                                    <li className="list-inline-item">{job.location.name}, {job?.location?.country?.name}</li>
                                                    {/*<li className="list-inline-item">{job.year_experience}</li>*/}
                                                    <li className="list-inline-item">{job.education}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}</>}
                                <div className={'d-flex justify-content-between align-items-center mt-7'}>
                                    <Pagination
                                        onChange={handleTableChange}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        defaultCurrent={paginationSortCurrent}
                                        total={!isEmpty(jobs.paging) ? jobs.paging.count : 0}/>
                                </div>
                            </>}
                        </div>

                        <div className="col-lg-3 order-lg-2 mb-5 mb-lg-0">
                            <div>
                                <div className="mb-5">
                                    <h6 className="small text-cap"> Skills </h6>
                                    <Select
                                        style={{width: '100%'}}
                                        className={'select select_skill'}
                                        loading={skill_loading}
                                        name="skills"
                                        placeholder="Search skills"
                                        mode="multiple"
                                        onBlur={formik.handleBlur}
                                        onChange={event => {
                                            setSelectedSkills(selected_skills.concat(event))
                                            formik.values.skills = event;
                                            formik.handleChange.skills = event;
                                        }}>
                                        {optionSkills}
                                    </Select>
                                </div>
                                <div className="mb-5">
                                    <h6 className="small text-cap">Education</h6>
                                    {!isEmpty(eduction_list) && <> {eduction_list.map(eduction_list =>
                                        <div key={eduction_list.id} className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                       id={`eduction_list${eduction_list.id}`} checked={education_checked(eduction_list.id)}
                                                       onChange={handleSelect('education', eduction_list.id)} />
                                                <label className="custom-control-label"
                                                       htmlFor={`eduction_list${eduction_list.id}`}>{eduction_list.name}</label>
                                            </div>
                                            <small>{eduction_list.count}</small>
                                        </div>
                                    )}</>}
                                </div>

                                <div className="mb-5">
                                    <h6 className="small text-cap">Years of experience</h6>
                                    {!isEmpty(year_experience_list) && <> {year_experience_list.map(year_experience =>
                                        <div key={year_experience.id}
                                             className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                       id={`yearsOfExperience${year_experience.id}`} checked={year_experience_checked(year_experience.id)}
                                                       onChange={handleSelect('yoe', year_experience.id)}/>
                                                <label className="custom-control-label"
                                                       htmlFor={`yearsOfExperience${year_experience.id}`}>{year_experience.name}</label>
                                            </div>
                                            <small>{year_experience.count}</small>
                                        </div>
                                    )}</>}
                                </div>

                                {/*<button type="button" className="btn btn-sm btn-block btn-white">*/}
                                {/*    <i className="fas fa-redo fa-sm mr-1"/> Reset all*/}
                                {/*</button>*/}
                                {
                                    <button type="button" className="btn btn-sm btn-block btn-white"
                                        onClick={SidebarSearch}>
                                        Search
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

const mapStateToProps = ({client, job, location, skill}) => ({...job, ...client, ...location, ...skill})

export default connect(mapStateToProps, {getJobLists, getCountryLists, searchJobLists})(Jobs);
