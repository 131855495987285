import React from "react";
import {Missing404} from "../components/NotFound";
import {trim, isEmpty} from 'lodash'
import {PENDING_TALENT_ACCOUNT} from "../appRedux/constants";
import {clearSessionWithParams, getSessionJsonWithKey, getSessionWithKey} from "../appRedux/store/cookies";
import {Redirect} from "@reach/router";
import {userSignOut} from "../appRedux/actions/Talents";
import {useDispatch} from "react-redux";
import {redirectToClientLogin, redirectToTalentLogin} from "./Redirect";

function AppComponent(props) {
    let {component, layout, route, auth} = props;
    const {path} = route;

    const dispatch = useDispatch();
    const talentProfile = getSessionJsonWithKey('talent_profile');
    const clientProfile = getSessionJsonWithKey('client_profile');

    // if (auth && isEmpty(talentProfile) && isEmpty(clientProfile)) {
    //     const login = '/login';
    //     dispatch(userSignOut());
    //
    //
    // }

    console.log(auth)

    // if(auth && !isEmpty(talentProfile) && isEmpty(clientProfile)) return <Redirect from={path} to={"/talents/login"} noThrow/>;
    // if(isEmpty(getSessionWithKey('talent_profile')) && !isEmpty(getSessionWithKey('client_profile'))) return <Redirect from={path} to={"/clients/login"} noThrow/>;
    // if(isEmpty(getSessionWithKey('talent_profile')) && isEmpty(getSessionWithKey('client_profile'))) return <Redirect from={path} to={"/talents/login"} noThrow/>;

    // component = Access(component)

    if (
        trim(route.path) === "/login" ||
        trim(route.path) === "/Register" ||
        trim(route.path) === "/reset-password" ||
        trim(route.path) === "/verify-forgot-password/:activation_code" ||
        trim(route.path) === "/reset-password-response/:token"
    ) {

    }

    const data = {};
    const layoutHandler = (lay, comp) => {
        if (typeof comp == "undefined" && isEmpty(layout)) {
            props = {...props, componentMissing: true};
            return React.memo(Missing404);
        } else {
            return typeof lay == "undefined" && isEmpty(lay)
                ? comp
                : React.memo(lay(comp, {data}));
        }
    };


    if (trim(route.path) === "/login" || trim(route.path) === "/client/login") {
        clearSessionWithParams('talent_profile');
        clearSessionWithParams('client_profile');
    }

    // if (
    //     _.isEmpty(session) ||
    //     typeof session === 'undefined' ||
    //     session === null ||
    //     authUser === null
    // ) {
    //     notification['error']({
    //         message: 'User not logged in!',
    //         description: 'Please login to continue',
    //         duration: NOTIFICATION_DURATION,
    //     });
    //
    //     //console.log('path', path, route, location);
    //     if (path === '/gift-card/:id' || path === '/airtime/:id') {
    //         setSessionWithValue(location.pathname, 'urlKeep');
    //     }
    //
    //     userSignOut();
    //     const login = '/login';
    //     return <Redirect from={path} to={login} noThrow />;
    // }

    if (typeof path !== 'undefined' && path === '/logout') {
        // const login = '/login';
        dispatch(userSignOut());
        // return <Redirect from={path} to={login} noThrow/>;
        // return <Redirect from={path} to={login} noThrow/>;
    }

    return React.createElement(layoutHandler(layout, component), {
        path: path,
        props: props,
        ...props,
    });
}

export default AppComponent;
