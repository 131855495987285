import React, {useEffect} from 'react';
import {Carousel} from "antd";
import LinkComponent from "../../route/LinkComponent";
import {AuthHeader} from "../../components";
import Profile from "../../_assets/media/svg/Profile.svg";
import CreateAccount from "../../_assets/media/svg/create_account.svg";
import {useLocation} from "@reach/router";
import {ReactComponent as Resume} from "../../_assets/media/svg/upload.svg";


const Pending = (Component) => {

    return (props) => (
        <div>
            <div>
                <AuthHeader/>
                <main id="content" role="main">
                    <div className="d-flex align-items-center position-relative vh-lg-100 " >
                        <div className="container">
                            <Component {...props}/>
                        </div>
                        <div className="col-md-8 d-none d-lg-flex align-items-center vh-lg-100 px-0">
                            <div className="w-100 text-center">

                                <div className={'p-5'} style={{width: '80%'}}><Resume/></div>

                                {/*<img src={CreateAccount} className={'p-5'} style={{width: '80%'}} />*/}
                                {/*<div>*/}
                                {/*<CreateAccount className={'p-5'} style={{width: '80%'}} />*/}

                                {/*<div className="position-absolute right-0 bottom-0 left-0 text-center p-5">*/}
                                {/*    /!*<span className="d-block text-white-70 mb-3"> &copy; 2020 Borderless </span>*!/*/}
                                {/*    <div className="flex-column-auto d-lg-flex justify-content-between mt-10 footer_auth">*/}
                                {/*        /!*<div className="opacity-70 font-weight-bold	text-white">*!/*/}
                                {/*        /!*    &copy; 2020 Borderless*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*        /!*    <LinkComponent to={"/policy"} className="text-white">*!/*/}
                                {/*        /!*        Privacy*!/*/}
                                {/*        /!*    </LinkComponent>*!/*/}
                                {/*        /!*    <LinkComponent to={"/terms"} className="text-white ml-5">*!/*/}
                                {/*        /!*        Terms &amp; Conditions*!/*/}
                                {/*        /!*    </LinkComponent>*!/*/}
                                {/*            /!*<LinkComponent to={"/contact"} className="text-white ml-10">*!/*/}
                                {/*            /!*    Contact*!/*/}
                                {/*            /!*</LinkComponent>*!/*/}
                                {/*    </div>*/}

                                {/*    /!*<div className="d-flex justify-content-center">*!/*/}
                                {/*    /!*    <img className="max-w-10rem mx-auto"*!/*/}
                                {/*    /!*         src="assets/svg/clients-logo/slack-white.svg"*!/*/}
                                {/*    /!*         alt="Image Description"/>*!/*/}
                                {/*    /!*    <img className="max-w-10rem mx-auto"*!/*/}
                                {/*    /!*         src="assets/svg/clients-logo/google-white.svg" alt="Image Description"/>*!/*/}
                                {/*    /!*    <img className="max-w-10rem mx-auto"*!/*/}
                                {/*    /!*         src="assets/svg/clients-logo/spotify-white.svg"*!/*/}
                                {/*    /!*         alt="Image Description"/>*!/*/}
                                {/*    /!*</div>*!/*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        </div>
    )
}


export default Pending;
