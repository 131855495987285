/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import {useLocation} from "@reach/router";
import {toAbsoluteUrl} from "../../_helpers";


function SubHeader() {
    const location = useLocation();

    return (
        <div
            id="bh_subheader"
            className={`subheader py-3 py-lg-8 subheader-solid`}
        >
            <div className={`container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
                <div className="d-flex align-items-center flex-wrap mr-1">

                    <button
                        className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                        id="bh_subheader_mobile_toggle"
                    >
                        <span/>
                    </button>

                    <div className="d-flex align-items-baseline mr-5">
                        {/* begin::Title */}
                        {/*<h2 className="subheader-title text-dark font-weight-bold my-2 mr-3">*/}
                        {/*    Overview*/}
                        {/*</h2>*/}
                    </div>
                </div>

                <div className="d-flex align-items-center flex-wrap">
                    <button
                        type="button"
                        className={`btn btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2`}>
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Add-user.svg"
                              )}
                          />
                        </span>
                    </button>

                    <button
                        className="btn btn-icon font-weight-bolds mr-2  "
                        data-toggle="modal"
                        data-target="#bh_chat_modal">
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Group-chat.svg"
                              )}
                          />
                        </span>
                    </button>

                    <div className="topbar-item">
                        <div className="btn btn-icon w-sm-auto d-flex align-items-center btn-lg px-2"
                             id="bh_quick_user_toggle">
                            <div className="d-flex flex-column text-right pr-sm-3">
                                <span
                                    className="opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">Olatunde O.</span>
                                <span
                                    className="font-weight-bolder font-size-sm d-none d-sm-inline"> Lead Developer </span>
                            </div>
                            <span className="symbol symbol-35">
                                <span className="symbol-label font-size-h5 font-weight-bold btn-clean"> OO </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubHeader
