import {
    COUNTRY_LIST_START, COUNTRY_LIST_END, INDUSTRY_LIST_START, INDUSTRY_LIST_END
} from '../constants';

const INIT_STATE = {
    industry_loading: false,
    industry_list: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INDUSTRY_LIST_START: {
            return {...state, industry_loading: true};
        }
        case INDUSTRY_LIST_END: {
            return {...state, industry_loading: false, industry_list: action.payload};
        }
        default:
            return state;
    }
};
