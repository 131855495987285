import {notification} from "antd";
import {FETCH_ERROR_MESSAGE, NOTIFICATION_DURATION} from "./constants";

export const handleNotify = (message, callback) => {
    notification['error']({
        message: 'Validation error!',
        description: message,
        duration: NOTIFICATION_DURATION,
    });
    return callback();
};

export const handleNotifyMessage = (message, type = 'error') => {
    notification[type]({
        message: 'Notification!',
        description: message,
        duration: NOTIFICATION_DURATION,
    });
};
