import React from 'react';
import LinkComponent from "../../route/LinkComponent";
import {v4 as uuid} from "uuidv4";

function ListProfile(props) {

    // active: 2
    // address: "Consequatur dolor re"
    // citizenship: "2"
    // created: null
    // expectation_cad: null
    // expectation_eur: null
    // expectation_usd: null
    // gender_id: 2
    // id: 12
    // location: "2"
    // name: "Olatunde Owokoniran"
    // notice_period: null
    // ref_code: "989929"
    // referral: "12456"
    // test: null
    // title: 11

    const {pool} = props;
    // console.log(pool);

    return (
        <>

            <div className="container">
                <div className="">
                    <div className="row align-items-md-center">
                        <div className="col-md-6 mb-5 mb-md-0">
                            <div className="media align-items-center">
                                <div className="avatar avatar-circle">
                                    <div className={'avatar-img'} />
                                </div>
                                <div className="media-body font-size-1 ml-3">
                                    <span className="h6">
                                        <span style={{color: '#00284f'}}>{pool.name}</span>
                                        {/*<LinkComponent to={`/client/talent-pool/${pool.id}`}     style={{color: '#00284f'}}>{pool.name}</LinkComponent>*/}
                                        {/*<button type="button" className="btn btn-xs btn-soft-primary font-weight-bold transition-3d-hover py-1 px-2 ml-1">Follow</button>*/}
                                    </span>
                                    {/*<span className="d-block text-muted">July 15, 2018</span>*/}
                                    <span className="d-block text-muted">{pool.title?.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-md-end align-items-center">
                                <span className="d-block small font-weight-bold text-cap mr-2 block" style={{display: 'block'}}>Skills:</span><br/>
                            </div>
                            <div className="d-flex justify-content-md-end align-items-right">
                                <div>
                                    {pool.skills.map(skill => <small key={uuid} className="btn btn-xs btn-soft-secondary mr-1 mb-1" style={{color: '#00284f'}}>{skill.title}</small>)}
                                </div>
                                {/*<a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" href="#">*/}
                                {/*    <i className="fab fa-facebook-f"></i>*/}
                                {/*</a>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="container">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-md-12 mb-md-0">*/}
            {/*            <div className="media align-items-center">*/}
            {/*                <div className="avatar avatar-circle">*/}
            {/*                    <div className={'avatar-img'}></div>*/}
            {/*                    /!*<img className="avatar-img" src="../assets/img/100x100/img11.jpg"*!/*/}
            {/*                    /!*     alt="Image Description"/>*!/*/}
            {/*                    /!*dont remove this part we will still work on it *!/*/}
            {/*                </div>*/}
            {/*                <div className="media-body font-size-1 ml-3">*/}
            {/*                    <span className="h5"><LinkComponent to={`/client/talent-pool/${pool.id}`}*/}
            {/*                                                        style={{color: '#00284f'}}>{pool.name}</LinkComponent>*/}
            {/*                        /!*<button type="button"*!/*/}
            {/*                        /!*        className="btn btn-xs btn-soft-primary font-weight-bold transition-3d-hover py-1 px-2 ml-1">Follow</button>*!/*/}
            {/*                    </span>*/}
            {/*                     <span className="d-block text-muted"> {pool.title?.name}*/}
            {/*                         {pool.expectation_usd &&<small>{pool.expectation_usd}</small>} </span>*/}
            {/*                </div>*/}
            {/*            </div>*/}


            {/*            <div className="over">*/}
            {/*                <div className="d-flex justify-content-md-end align-items-right">*/}
            {/*                    <span className="d-block small font-weight-bold text-cap mr-2"> Skills:</span>*/}

            {/*                    <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" href="#">*/}
            {/*                        <i className="fab fa-facebook-f"></i>*/}
            {/*                    </a>*/}
            {/*                    <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" href="#">*/}
            {/*                        <i className="fab fa-twitter"></i>*/}
            {/*                    </a>*/}
            {/*                    <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" href="#">*/}
            {/*                        <i className="fab fa-instagram"></i>*/}
            {/*                    </a>*/}
            {/*                    <a className="btn btn-xs btn-icon btn-soft-secondary rounded-circle ml-2" href="#">*/}
            {/*                        <i className="fab fa-telegram"></i>*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}

export default ListProfile;
