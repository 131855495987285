import {
    CLIENT_SIGNUP,
    COUNTRY_INDUSTRY_LIST,
    COUNTRY_LIST,
    COUNTRY_LIST_END,
    COUNTRY_LIST_START, DEGREES_LIST_END, DEGREES_LIST_START, GENDER_LIST_END, GENDER_LIST_START,
    INDUSTRY_LIST_END,
    INDUSTRY_LIST_START,
    LOCATION_LIST_END,
    LOCATION_LIST_START, SKILLS_LIST_END,
    SKILLS_LIST_START,
    TALENT_SIGNUP, TITLE_LIST_END, TITLE_LIST_START
} from "../constants";
import api from "../api";

export const getCountryLists = () => {
    return dispatch => {
        dispatch({type: COUNTRY_LIST_START});
        dispatch({type: INDUSTRY_LIST_START});
        dispatch({type: LOCATION_LIST_START});
        dispatch({type: SKILLS_LIST_START});
        dispatch({type: GENDER_LIST_START});
        dispatch({type: TITLE_LIST_START});
        dispatch({type: DEGREES_LIST_START});
        api.get(COUNTRY_INDUSTRY_LIST).then(({data}) => {
            if (data.success) {
                dispatch({type: COUNTRY_LIST_END, payload: data.data.countries});
                dispatch({type: INDUSTRY_LIST_END, payload: data.data.industries});
                dispatch({type: LOCATION_LIST_END, payload: data.data.locations});
                dispatch({type: SKILLS_LIST_END, payload: data.data.skills});
                dispatch({type: GENDER_LIST_END, payload: data.data.genders});
                dispatch({type: TITLE_LIST_END, payload: data.data.titles});
                dispatch({type: DEGREES_LIST_END, payload: data.data.degrees});
            }
        })
    }
}
