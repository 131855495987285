import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Table, Tag, Space} from 'antd';

import LinkComponent from "../../route/LinkComponent";
import {getClientProfile, getClientStat} from "../../appRedux/actions/Clients";
import ClientMenu from "../../components/ClientMenu";
import ClientHeader from "../../components/ClientHeader";
import {getJobLists} from "../../appRedux/actions/Jobs";
import Job from "../../appRedux/reducers/Job";
import {LoadingMini} from "../../components/Loading";
import HalfCircle from "../../_assets/media/svg/components/half-circle-2.svg"

function Client(props) {

    useEffect(() => {
        props.getClientStat();
    }, []);

    const {client_stat_loading, client_stat} = props;

    return (
        <>
            <div>
                <div className="t_overview">
                    <div>

                        <div className="mb-4">
                            <div className="card overflow-hidden p-3">
                                <div
                                    className="row justify-content-md-start align-items-md-center text-center text-md-left">
                                    <div className="col-md-8 col-lg-5 offset-lg-3 mb-3 mb-md-0">
                                        <h3 className="h4 text-indigo mb-0"> Need Help! Schedule a call with us </h3>
                                    </div>

                                    <div className="col-md-4 text-md-right">
                                        <a className="btn btn-soft-primary btn-pill transition-3d-hover" target="_blank"
                                           href="https://calendly.com/paschal-borderlesshr">
                                            Reach Out <i className="fas fa-angle-right ml-1"/>
                                        </a>
                                    </div>
                                </div>

                                <figure className="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
                                    <img className="img-fluid" src={HalfCircle} alt="Image Description"/>
                                </figure>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="card text-left bg-primary card-bg-light h-100 transition-3d-hover">
                                    <div className="card-body p-lg-5">
                                        <h3 className="h4 text-white"> Wallet </h3>
                                        <div className=" mb-0 text-white"
                                             style={{fontSize: '42px', color: '#00284f'}}>{client_stat_loading ? <>
                                            <LoadingMini/></> : <>
                                            {client_stat.wallet_amount} <br/>
                                            <small className={'ledger text-white'}> ledger
                                                balance {client_stat.wallet_amount_ledger}</small>
                                        </>}
                                        </div>
                                    </div>
                                    {/*<div className="card-footer font-weight-bold py-3 px-lg-5">*/}
                                    {/*    <LinkComponent to={'/client'}>*/}
                                    {/*        View Transactions <i className="fas fa-angle-right fa-sm ml-1"/>*/}
                                    {/*    </LinkComponent>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card text-left card-bg-light h-100 transition-3d-hover">
                                    <div className="card-body p-lg-5">
                                        <h3 className="h4n" style={{color: '#ff00008a'}}> Outstanding </h3>
                                        <div className=" mb-0"
                                             style={{fontSize: '42px', color: '#ff00008a'}}>{client_stat_loading ? <>
                                            <LoadingMini/></> : <>{client_stat.outstanding_amount}</>}
                                        </div>
                                    </div>
                                    {/*<div className="card-footer font-weight-bold py-3 px-lg-5">*/}
                                    {/*    <LinkComponent to={'/client'}>*/}
                                    {/*        Visit Jobs <i className="fas fa-angle-right fa-sm ml-1"/>*/}
                                    {/*    </LinkComponent>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card text-left card-bg-light h-100 transition-3d-hover">
                                    <div className="card-body p-lg-5">
                                        <h3 className="h4n" style={{color: '#ff00008a'}}> Total Payout </h3>
                                        <div className=" mb-0"
                                             style={{fontSize: '42px', color: '#ff00008a'}}>{client_stat_loading ? <>
                                            <LoadingMini/></> : <>{client_stat.total_payout}</>}
                                        </div>
                                    </div>
                                    {/*<div className="card-footer font-weight-bold py-3 px-lg-5">*/}
                                    {/*    <LinkComponent to={'/client'}>*/}
                                    {/*        Visit Jobs <i className="fas fa-angle-right fa-sm ml-1"/>*/}
                                    {/*    </LinkComponent>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-3">
                                <div className="card text-center h-100 transition-3d-hover">
                                    <div className="card-body p-lg-5">
                                        <h3 className="h4"> Jobs / Postings </h3>
                                        <div className=" mb-0"
                                             style={{fontSize: '50px', color: '#00284f'}}>{client_stat_loading ? <>
                                            <LoadingMini/></> : <>{client_stat.count_jobs}</>}
                                        </div>
                                    </div>
                                    {/*<div className="card-footer font-weight-bold py-3 px-lg-5">*/}
                                    {/*    <LinkComponent to={'/profile/info'}>*/}
                                    {/*        Visit Jobs <i className="fas fa-angle-right fa-sm ml-1"/>*/}
                                    {/*    </LinkComponent>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-center h-100 transition-3d-hover">
                                    <div className="card-body p-lg-5">
                                        <h3 className="h4"> Employees </h3>
                                        <div className=" mb-0"
                                             style={{fontSize: '50px', color: '#00284f'}}>{client_stat_loading ? <>
                                            <LoadingMini/></> : <>{client_stat.count_employees}</>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </>
    );
}

const mapStateToProps = ({client}) => ({...client})

export default connect(mapStateToProps, {getClientStat})(Client);
