import {
    SKILLS_LIST_START,
    SKILLS_LIST_END
} from '../constants';

const INIT_STATE = {
    skill_loading: false,
    skill_list: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SKILLS_LIST_START: {
            return {...state, skill_loading: true};
        }
        case SKILLS_LIST_END: {
            return {...state, skill_loading: false, skill_list: action.payload};
        }
        default:
            return state;
    }
};
