import React from 'react';
import {Carousel} from "antd";
import {ReactComponent as Qoute} from "../../_assets/media/svg/qoute.svg";
import LinkComponent from "../../route/LinkComponent";
import {AuthHeader} from "../../components";
import {ReactComponent as Business} from "../../_assets/media/svg/business.svg";
import {ReactComponent as BusinessLogin} from "../../_assets/media/svg/client_login.svg";
import {ReactComponent as ResetPassword} from "../../_assets/media/svg/reset-password.svg";
import {ReactComponent as CreateAccount} from "../../_assets/media/svg/create_account.svg";
import {useLocation} from "@reach/router";
// import {ReactComponent as Profile} from "../../_assets/media/svg/Profile.svg";

const Auth = (Component) => {

    const location = useLocation();

    function checkLocationWithParams(path) {
        const parts = location.pathname.split('/');
        return parts[2] === path;
    }

    return (props) => (
        <div>
            <div>
                <AuthHeader type={'client'}/>
                <main id="content" role="main">
                    <div className="d-flex align-items-center position-relative vh-lg-100">
                        <div className="col-md-7 d-none d-lg-flex align-items-center vh-lg-100 px-0">

                        {/*<div className="col-lg-5 col-xl-4 d-none d-lg-flex align-items-center bg-dark vh-lg-100 px-0 bg_auth">*/}
                        {/*    <div style={shade}></div>*/}
                            <div className="w-100 text-center">

                                {(location.pathname === '/client/signup') && <div className={'p-5'}><Business style={{width: '70%'}}/></div>}
                                {(location.pathname === '/client/login') && <div className={'p-5'}><BusinessLogin style={{width: '70%'}}/></div>}
                                {/*{(location.pathname === '/client/forgot-password') && <div className={'p-5'}><BusinessLogin style={{width: '70%'}}/></div>}*/}
                                {(checkLocationWithParams('verify-forgot-password') || (location.pathname === '/client/forgot-password')) && <div className={'p-5'}><ResetPassword style={{width: '70%'}}/></div>}

                                {/*<div className="position-absolute right-0 bottom-0 left-0 text-center p-5 hidden">*/}
                                {/*    <span className="d-block text-white-70 mb-3"> &copy; 2020 Borderless </span>*/}
                                {/*    <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10 footer_auth">*/}
                                {/*        <div className="opacity-70 font-weight-bold	text-white">*/}
                                {/*            &copy; 2020 Borderless*/}
                                {/*        </div>*/}
                                {/*            <LinkComponent to={"/policy"} className="text-white">*/}
                                {/*                Privacy*/}
                                {/*            </LinkComponent>*/}
                                {/*            <LinkComponent to={"/terms"} className="text-white ml-5">*/}
                                {/*                Terms &amp; Conditions*/}
                                {/*            </LinkComponent>*/}
                                {/*            <LinkComponent to={"/contact"} className="text-white ml-10">*/}
                                {/*                Contact*/}
                                {/*            </LinkComponent>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="container">
                            <Component {...props}/>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}


export default Auth;
