import React from 'react';
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import LinkComponent from "../../route/LinkComponent";
import TalentMenu from "../../components/TalentMenu";
import ClientHeader from "../../components/ClientHeader";
import ClientMenu from "../../components/ClientMenu";

const InnerLandingClient = (Component) => (props) => (
    <>
        <div className={'landing bg-light'}>
            <Header/>
            <div className="container space-top-1 space-top-lg-4">
                <ClientHeader/>
                <div className="row">
                    <div className="col-lg-3">
                        <ClientMenu/>
                    </div>
                    <div className="col-lg-9">
                        <Component {...props}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </>
)

export default InnerLandingClient;
