import {
    CLIENT_PROFILE_LOGIN_CLEAR,
    CLIENT_PROFILE_LOGIN_END,
    CLIENT_PROFILE_LOGIN_START,
    CLIENT_STATS_END,
    CLIENT_STATS_START,
    CLIENT_TALENT_POOL_END,
    CLIENT_TALENT_POOL_START
} from '../constants';
import {getSessionJsonWithKey, setSessionWithValue} from "../store/cookies";
import {isEmpty} from "lodash"

const INIT_STATE = {
    client_profile_loading: false,
    client_stat_loading: false,
    client_talent_pool_loading: false,
    client_talent_pool: {},
    client_stat: {},
    client_profile: isEmpty(getSessionJsonWithKey('client_profile')) ? {} : getSessionJsonWithKey('client_profile'),
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLIENT_TALENT_POOL_START: {
            return {...state, client_talent_pool_loading: true};
        }
        case CLIENT_TALENT_POOL_END: {
            return {...state, client_talent_pool_loading: false, client_talent_pool: action.payload};
        }
        case CLIENT_PROFILE_LOGIN_START: {
            return {...state, client_profile_loading: true};
        }
        case CLIENT_STATS_START: {
            return {...state, client_stat_loading: true};
        }
        case CLIENT_STATS_END: {
            return {...state, client_stat_loading: false, client_stat: action.payload};
        }
        case CLIENT_PROFILE_LOGIN_END: {
            setSessionWithValue(action.payload, 'client_profile');
            return {...state, client_profile_loading: false, client_profile: action.payload};
        }
        case CLIENT_PROFILE_LOGIN_CLEAR: {
            return {...state, client_profile_loading: false, client_profile: {}};
        }
        default:
            return state;
    }
};
