import React, {useState} from 'react';
import {Popconfirm} from "antd";
import {useDispatch} from "react-redux";
import {deleteExperience} from "../../appRedux/actions/Talents";
import {LoadingMini} from "../../components/Loading";

function PopconfirmExperienceDelete(props) {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const confirm = (experience_id) => {
        setLoading(true)
        dispatch(deleteExperience(experience_id))
    }

    return (
        <>
            {loading ? <LoadingMini/> :
                <Popconfirm
                    placement="topRight"
                    title={'You are about to delete this experience, Do you want to proceed?'}
                    onConfirm={() => confirm(props.experience_id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <button type="button"
                            className="btn remove btn-sm btn-outline-danger btn-xs transition-3d-hover mt-2">
                        <span> <i className="fas fa-trash-alt mr-1"/> Delete </span>
                    </button>
                    {/*<button type="button" className="btn btn-xs delete_btn">*/}
                    {/*    <i className="fas fa-trash-alt mr-1"/>/!*Delete*!/*/}
                    {/*</button>*/}
                </Popconfirm>}
        </>
    );
}

export default PopconfirmExperienceDelete;
