import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {isEmpty} from "lodash";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {Empty, notification, Skeleton} from "antd";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {talentApplyForJob, getJobDetail} from "../../appRedux/actions/Jobs";
import {Loading} from "../../components/Loading";
import LinkComponent from "../../route/LinkComponent";
// import img12 from "../../_assets/media/img12.png";
import half from "../../_assets/media/svg/half-circle-2.svg";
import empty from "../../_assets/media/svg/illustrations/empty.svg";
import renderHTML from 'react-render-html';


function JobOverview(props) {

    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const {job_detail: job, job_detail_loading} = props;
    const {talent_profile} = useSelector(state => state.talent);

    useEffect(() => {
        if (props.job_id) {
            props.getJobDetail(parseInt(props.job_id))
        } else {
            console.log('got an issue here');
        }
    }, []);

    useEffect(() => {
        //applied
        if (!isEmpty(job.applied)) {
            setDisabled(true)
        }
    }, [job]);

    const applyForThisJob = () => {
        if (isEmpty(talent_profile)) {
            notification['info']({
                message: 'Unable to apply',
                description: 'Please login to apply for this job',
                duration: NOTIFICATION_DURATION,
            });
        } else {
            setLaddaLoading(true);
            setLaddaProgress(0.5);
            props.talentApplyForJob(parseInt(props.job_id)).then(res => {
                handleResponse(true, res.message)
            }).catch(e => {
                handleResponse(false, e.message)
            })
        }
    }

    const handleResponse = (type, message) => {
        setLaddaLoading(false);
        setLaddaProgress(1.0);

        if (type) {
            setDisabled(true)
            notification['success']({
                message: 'Job applied',
                description: message,
                duration: NOTIFICATION_DURATION,
            });
        } else {
            notification['error']({
                message: 'Job applied Error',
                description: message,
                duration: NOTIFICATION_DURATION,
            });
        }
    }


    return (
        <>
            <main id="content" role="main">
                <div className="container space-top-1 space-top-lg-4">
                    <div className=" mx-lg-auto">
                        <div className="d-none d-lg-block">
                            <LinkComponent className="btn btn-sm btn-soft-primary btn-pill transition-3d-hover"
                                           to={"/jobs"}> Back to
                                Postings </LinkComponent>
                        </div>
                        <br/>
                        <br/>
                    </div>

                    <div className={'row'}>

                        {job_detail_loading ? <div className="col-md-12"><div className="mx-lg-auto" style={{marginBottom: '120px'}}><Skeleton active/></div></div> :
                            <>
                                {isEmpty(job) ? <div className="col-md-12 text-center mb-10"><Empty imageStyle={{
                                    height: 440,
                                }} description={
                                    <><h1>Ups!... no results found</h1></>
                                } image={empty}/></div> : <>
                                    <div className="col-md-8">
                                        <div className="mx-lg-auto">
                                            <div className="page-header">
                                                <div className="d-sm-flex mb-3">
                                                    <div className="media-body">
                                                        <div className="row mb-2">
                                                            <div className="col">
                                                                <h1 className="h2 mb-1">{job.title}</h1>
                                                                <div className={'skill_holder'}>
                                                                    {job.skills.map(skill => <small key={skill.id}
                                                                                                    className="btn btn-xs btn-ghost-secondary">{skill.title}</small>)}
                                                                </div>
                                                            </div>

                                                            {/*<div className="col-auto">*/}
                                                            {/*    <div className="custom-control custom-checkbox-bookmark">*/}
                                                            {/*        <input type="checkbox" id="checkboxBookmarkSaveJob"*/}
                                                            {/*               className="custom-control-input custom-checkbox-bookmark-input"/>*/}
                                                            {/*        <label className="custom-checkbox-bookmark-label"*/}
                                                            {/*               htmlFor="checkboxBookmarkSaveJob">*/}
                                                            {/*                          <span*/}
                                                            {/*                              className="custom-checkbox-bookmark-default">*/}
                                                            {/*                            <i className="far fa-star mr-1"/>*/}
                                                            {/*                            <small*/}
                                                            {/*                                className="text-body">Save this job</small>*/}
                                                            {/*                          </span>*/}
                                                            {/*            <span className="custom-checkbox-bookmark-active">*/}
                                                            {/*                        <i className="fas fa-star mr-1"/>*/}
                                                            {/*                        <small className="text-body">Saved</small>*/}
                                                            {/*                    </span>*/}
                                                            {/*        </label>*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                        </div>

                                                        <ul className="list-inline list-separator small text-body mb-2">
                                                            {/*<li className="list-inline-item">Posted {job.created}</li>*/}
                                                            <li className="list-inline-item">{job.location?.name}, {job.location?.country?.name}</li>
                                                            {/*<li className="list-inline-item">Full time</li>*/}
                                                            <li className="list-inline-item">{job.year_experience}</li>
                                                            <li className="list-inline-item">{job.education}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-2 space-bottom-lg-3">
                                            <div className="mx-lg-auto">
                                                <div className="row mb-3">
                                                    <div className="col-6">
                                                        <h3>Description</h3>
                                                    </div>

                                                    {/*<div className="col-6 text-right">*/}
                                                    {/*    <div className="hs-unfold">*/}
                                                    {/*        <a className="js-hs-unfold-invoker" href="">*/}
                                                    {/*            Share this job <i*/}
                                                    {/*            className="fas fa-share-alt fa-sm ml-1"></i>*/}
                                                    {/*        </a>*/}

                                                    {/*        <div id="shareOptionsDropdown"*/}
                                                    {/*             className="hs-unfold-content dropdown-unfold dropdown-menu mt-1">*/}
                                                    {/*            <a className="dropdown-item" href="#">*/}
                                                    {/*                <i className="fab fa-facebook dropdown-item-icon"></i> Facebook*/}
                                                    {/*            </a>*/}
                                                    {/*            <a className="dropdown-item" href="#">*/}
                                                    {/*                <i className="fab fa-twitter dropdown-item-icon"></i> Twitter*/}
                                                    {/*            </a>*/}
                                                    {/*            <a className="dropdown-item" href="#">*/}
                                                    {/*                <i className="fab fa-linkedin dropdown-item-icon"></i> LinkedIn*/}
                                                    {/*            </a>*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>

                                                <div>
                                                    {renderHTML(job.description)}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <article className="card overflow-hidden transition-3d-hover ">
                                                <div className=" p-4">
                                                    <div className="mb-5">
                                                        <span className="d-block font-size-2 text-dark text-lh-sm">Share this position</span>
                                                        <span
                                                            className="d-block font-size-4 text-dark font-weight-bold text-lh-sm">With your friends</span>
                                                    </div>
                                                    {/*<h3>Front gives every team the freedom to create</h3>*/}
                                                    {/*<div className="mb-4">*/}
                                                    {/*    <p>Share this position with your friends and getCAD $500when they are hired</p>*/}
                                                    {/*</div>*/}

                                                    <ul className="list-inline mb-0 hidden hide">
                                                        <li className="list-inline-item">
                                                            <a className="btn btn-xs btn-soft-primary btn-icon"
                                                               href="#">
                                                                <i className="fab fa-facebook-f"/>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a className="btn btn-xs btn-soft-primary btn-icon"
                                                               href="#">
                                                                <i className="fab fa-twitter"/>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a className="btn btn-xs btn-soft-primary btn-icon"
                                                               href="#">
                                                                <i className="fab fa-linkedin" />
                                                            </a>
                                                        </li>
                                                    </ul>

                                                    {/*<a className="btn btn-sm btn-soft-primary btn-pill transition-3d-hover"*/}
                                                    {/*   href="#">Find out More <i className="fas fa-angle-right ml-1" /></a>*/}

                                                    <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
                                                        <img class="img-fluid" src={half} alt="Image Description"/>
                                                    </figure>
                                                </div>
                                                {/*<div className="position-absolute right-0 bottom-0 w-50 mb-n3 mr-n4">*/}
                                                {/*    <img className="img-fluid" src={img12} alt="Image Description" />*/}
                                                {/*</div>*/}

                                                {/*<div className="position-absolute bottom-0 right-0 w-sm-35 max-w-27rem">*/}
                                                {/*    <img className="img-fluid" src={list_app} alt="Image Description" />*/}
                                                {/*</div>*/}
                                            </article>
                                            <br/>
                                            <div className="mt-7">
                                                <LaddaButton
                                                    loading={laddaLoading}
                                                    progress={laddaProgress}
                                                    data-style={ZOOM_OUT}
                                                    data-spinner-size={30}
                                                    data-spinner-color="#fff"
                                                    onClick={applyForThisJob}
                                                    data-spinner-lines={12}
                                                    className="btn btn-block btn-primary transition-3d-hover"
                                                    disabled={disabled}
                                                    type="button">
                                                    {disabled ? "Already applied for this job" : "Apply for this job"}
                                                </LaddaButton>

                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </>
                        }
                    </div>
                </div>
            </main>
        </>
    );
}

const mapStateToProps = ({job}) => ({...job})
export default connect(mapStateToProps, {getJobDetail, talentApplyForJob})(JobOverview);
