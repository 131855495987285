import React, {useEffect} from 'react';
import {Loading} from "../../components/Loading";
import {connect} from "react-redux";
import {getJobDetail} from "../../appRedux/actions/Jobs";
import {isEmpty} from "lodash"
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {redirectToJobs} from "../../route/Redirect";

function TalentJobDetails(props) {

    useEffect(() => {
        if (isEmpty(job_id)) redirectToJobs();

        props.getJobDetail(parseInt(job_id))
    }, []);

    const {job_detail: job, job_detail_loading, job_id} = props;
    console.info('job_detail_loading', job_detail_loading)

    return (
        <div>
            {isEmpty(job) ? <Loading/> :
                <>
                    <div className="w-lg-75 mx-lg-auto">
                        <div className="page-header">
                            <div className="d-sm-flex mb-3">
                                <div className="media-body">
                                    <div className="row mb-2">
                                        <div className="col">
                                            <h1 className="h2 mb-1">{job.title}</h1>
                                            <div className={'skill_holder'}>
                                                {job.skills.map(skill => <small key={skill.id}
                                                                                className="btn btn-xs btn-ghost-secondary">{skill.title}</small>)}
                                            </div>
                                        </div>

                                        <div className="col-auto">
                                            <div className="custom-control custom-checkbox-bookmark">
                                                <input type="checkbox" id="checkboxBookmarkSaveJob"
                                                       className="custom-control-input custom-checkbox-bookmark-input"/>
                                                <label className="custom-checkbox-bookmark-label"
                                                       htmlFor="checkboxBookmarkSaveJob">
                                                                      <span
                                                                          className="custom-checkbox-bookmark-default">
                                                                        <i className="far fa-star mr-1"/>
                                                                        <small
                                                                            className="text-body">Save this job</small>
                                                                      </span>
                                                    <span className="custom-checkbox-bookmark-active">
                                                                    <i className="fas fa-star mr-1"/>
                                                                    <small className="text-body">Saved</small>
                                                                </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="list-inline list-separator small text-body mb-2">
                                        <li className="list-inline-item">Posted {job.created}</li>
                                        <li className="list-inline-item">{job.location.name}, {job.location.country.name}</li>
                                        <li className="list-inline-item">Full time</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="space-2 space-bottom-lg-3">
                        <div className="w-lg-75 mx-lg-auto">
                            <div className="row mb-3">
                                <div className="col-6">
                                    <h3>Description</h3>
                                </div>

                                <div className="col-6 text-right">
                                    <div className="hs-unfold">
                                        <a className="js-hs-unfold-invoker" href="">
                                            Share this job <i
                                            className="fas fa-share-alt fa-sm ml-1"></i>
                                        </a>

                                        <div id="shareOptionsDropdown"
                                             className="hs-unfold-content dropdown-unfold dropdown-menu mt-1">
                                            <a className="dropdown-item" href="#">
                                                <i className="fab fa-facebook dropdown-item-icon"></i> Facebook
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <i className="fab fa-twitter dropdown-item-icon"></i> Twitter
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <i className="fab fa-linkedin dropdown-item-icon"></i> LinkedIn
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {job.description}
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

const mapStateToProps = ({job}) => {
    const {job_detail, job_detail_loading} = job;
    return {job_detail, job_detail_loading}
}

export default connect(mapStateToProps, {getJobDetail})(TalentJobDetails);
