import React from 'react';
import {ReactComponent as Logo} from "../../_assets/media/svg/logo.svg";
import Footer from "./Footer";
import Header from "./Header";
import {Loading} from "../../components/Loading";

const Landing = (Component) => {

    return (props) => (
    <>
        <div className={'landing'}>
            <Header/>
            <Component {...props}/>
            <Footer />
        </div>
    </>
)}

export default Landing;
