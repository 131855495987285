import React from 'react';
import LinkComponent from "../../route/LinkComponent";
import {useLocation} from "@reach/router";
import {useDispatch} from "react-redux";
import {userSignOut} from "../../appRedux/actions/Talents";

function ClientMenu(props) {

    const location = useLocation();
    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(userSignOut('client'));
    }

    return (
        <div>
            <div className="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light">
                <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
                    <div className="card mb-5">
                        <div className="card-body">

                            <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                <li className="nav-item">
                                    <LinkComponent className={`nav-link ${location.pathname === '/client' && 'active'}`} to="/client">
                                        <i className="fas fa-id-card nav-icon"/> Overview
                                    </LinkComponent>
                                </li>
                            </ul>

                            <h6 className="text-cap small"> Jobs & Listings </h6>
                            <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                <li className="nav-item">
                                    <LinkComponent className={`nav-link ${location.pathname === '/client/jobs' && 'active'}`} to="/client/jobs">
                                        <i className="fas fa-shopping-basket nav-icon"/> Listed Jobs
                                    </LinkComponent>
                                </li>
                                <li className="nav-item">
                                    <LinkComponent className={`nav-link ${location.pathname === '/client/talents-pool' && 'active'}`} to="/client/talents-pool">
                                        <i className="fas fa-users nav-icon"/> Talents Pool
                                    </LinkComponent>
                                </li>
                            </ul>

                            <h6 className="text-cap small"> Account </h6>
                            <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                <li className="nav-item">
                                    <LinkComponent className={`nav-link ${location.pathname === '/client/account' && 'active'}`} to="/client/account">
                                        <i className="fas fa-shield-alt nav-icon"/> Login & security
                                    </LinkComponent>
                                </li>
                            </ul>

                            <div className="">
                                <div className="dropdown-divider"/>

                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                                    <li className="nav-item">
                                        <p className="fas fa-sign-out-alt nav-icon" onClick={signOut} >Log out</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ClientMenu;
