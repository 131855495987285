import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, DatePicker, Empty, notification, Popconfirm, Select, Skeleton} from "antd";
import {FormFeedback} from "reactstrap";
import LinkComponent from "../../route/LinkComponent";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {useFormik} from "formik";
import {handleNotify} from "../../appRedux/response";
import * as Yup from "yup";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {connect} from "react-redux";
import {getCountryLists} from "../../appRedux/actions/Country";
import {clientSignUp} from "../../appRedux/actions/Clients";
import {deleteExperience, getExperience, talentSaveExperience} from "../../appRedux/actions/Talents";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {redirectToClientProfile} from "../../route/Redirect";
import isEmpty from "lodash";
import {Loading} from "../../components/Loading";
import PopconfirmExperienceDelete from "./PopconfirmExperienceDelete";

const {Option} = Select;


function Experience(props) {

    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        props.getExperience();
    }, []);

    const toggle = () => setModal(!modal);

    const initExperience = (experience_id) => {
        console.log(experience_id)
    }

    const regEduSchema = Yup.object().shape({
        company_name: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required('Email address is Required'),
        skills: Yup.array().required('Skills is Required'),
        location: Yup.string().required('Location is Required'),
        industry: Yup.string().required('Industry is Required'),
        start_date: Yup.string().required('Start date address is Required'),
    });

    const formik = useFormik({
        initialValues: {
            company_name: '',
            description: '',
            skills: '',
            location: '',
            industry: '',
            start_date: '',
            end_date: '',
            still_work: false,
        },
        validationSchema: regEduSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            props.talentSaveExperience(values).then(res => {
                handleIn(res)
                resetForm(true);
                setSubmitting(false);
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Experience saved successful',
            description: res.message,
            duration: NOTIFICATION_DURATION,
        });
        setLaddaLoading(false);
        setLaddaProgress(1.0);
        toggle()
    }

    const {industry_list, industry_loading, skill_loading, skill_list, talent_titles, talent_title_loading, talent_experience, talent_experience_loading} = props;

    let optionIndustry = [];
    let optionSkills = [];
    let optionTitles = [];
    const titleOptions = [];
    for (let industry in industry_list) {
        optionIndustry.push(<Option key={industry} value={industry}>{industry_list[industry]}</Option>)
    }
    for (let skill in skill_list) {
        optionSkills.push(<Option key={skill} value={skill}>{skill_list[skill]}</Option>)
    }
    for (let title in talent_titles) {
        optionTitles.push(<Option key={title} value={title}>{talent_titles[title]}</Option>)
        titleOptions.push({label: talent_titles[title], value: title})
    }

    return (
        <>
            <div className={'card'}>
                <div className="card-header border-0 bg-light text-center px-4">
                    <h5 className="card-header-title"> Working experience
                    </h5>
                    <Button className="btn btn-primary btn-xs transition-3d-hover" onClick={toggle}>
                        Add Experience
                    </Button>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            {talent_experience_loading ?
                                <div>
                                    <Skeleton active/>
                                    <Skeleton active/>
                                </div> :
                                <>
                                    {talent_experience.length === 0 ? <Empty description={<>No Experience</>}/> :
                                        <>

                                            <ul className="step step-icon-sm">
                                                {talent_experience.map(experience => (
                                                        <li key={experience.id} className="step-item">
                                                            <div className="step-content-wrapper">
                                                                <div className="step-avatar step-avatar-sm">
                                                                    <span className="step-icon step-icon-soft-dark"><i
                                                                        className="fas fa-briefcase"/></span>
                                                                </div>
                                                                <div className="step-content">
                                                                    <h5>{experience.title.name}</h5>

                                                                    <PopconfirmExperienceDelete
                                                                        experience_id={experience.id}/>

                                                                    <span
                                                                        className="d-block text-dark">{experience.company_name} - {experience.location}</span>
                                                                    <small
                                                                        className="d-block mb-4">{experience.start_date} to {experience.currently === 1 ? 'Present' : experience.end_date}</small>
                                                                    <p className="text-body mb-0">{experience.description}</p>
                                                                    <br/>
                                                                    <ul className="list-inline list-sm-article">
                                                                        {experience.skills.map(skill =>
                                                                            <li key={skill.id} className="list-inline-item">
                                                                                <span
                                                                                    className="badge badge-soft-dark py-2 px-3">{skill.title}</span>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>

                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}> Add Experience </ModalHeader>
                <ModalBody>
                    <div>
                        <div>
                            <form className="js-validate" onSubmit={formik.handleSubmit}>

                                <div>
                                    <div className="js-form-message form-group">
                                        <label className="input-label"> Title </label>

                                        {/*<AutoComplete*/}
                                        {/*    className="form-control"*/}
                                        {/*    value={value}*/}
                                        {/*    options={titleOptions}*/}
                                        {/*    style={{ width: 200 }}*/}
                                        {/*    // onSelect={onSelect}*/}
                                        {/*    onSearch={onSearch}*/}
                                        {/*    onChange={event => {*/}
                                        {/*        formik.values.title = event;*/}
                                        {/*        formik.handleChange.title = event;*/}
                                        {/*    }}*/}
                                        {/*    // placeholder="control mode"*/}
                                        {/*/>*/}
                                        <Select
                                            style={{width: '100%'}}
                                            className={'select'}
                                            size={'large'}
                                            loading={talent_title_loading}
                                            name="title"
                                            placeholder="Select title"
                                            onBlur={formik.handleBlur}
                                            onChange={event => {
                                                formik.values.title = event;
                                                formik.handleChange.title = event;
                                            }}>
                                            {optionTitles}
                                        </Select>
                                        {formik.touched.title && formik.errors.title &&
                                        <FormFeedback>{formik.errors.title}</FormFeedback>}
                                    </div>
                                </div>

                                <div className="js-form-message form-group">
                                    <label className="input-label"> Company Name </label>
                                    <input type="text"
                                           className="form-control"
                                           name="company_name"
                                           placeholder="Enter company name"
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.company_name}/>
                                    {formik.touched.company_name && formik.errors.company_name &&
                                    <FormFeedback>{formik.errors.company_name}</FormFeedback>}
                                </div>

                                <div className="js-form-message form-group">
                                    <label className="input-label"> Description </label>
                                    <textarea role="4"
                                              className="form-control"
                                              name="description"
                                              placeholder="Enter job description"
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              value={formik.values.description}/>
                                    {formik.touched.description && formik.errors.description &&
                                    <FormFeedback>{formik.errors.description}</FormFeedback>}
                                </div>

                                <div>
                                    <div className="js-form-message form-group">
                                        <label className="input-label"> Skills / Stack </label>

                                        <Select
                                            style={{width: '100%'}}
                                            className={'select select_skill'}
                                            size={'large'}
                                            loading={skill_loading}
                                            name="skills"
                                            placeholder="Select skills"
                                            mode="multiple"
                                            onBlur={formik.handleBlur}
                                            onChange={event => {
                                                formik.values.skills = event;
                                                formik.handleChange.skills = event;
                                            }}>
                                            {optionSkills}
                                        </Select>
                                        {formik.touched.skills && formik.errors.skills &&
                                        <FormFeedback>{formik.errors.skills}</FormFeedback>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-5">
                                        <div className="js-form-message form-group">
                                            <label className="input-label"> County / Location </label>
                                            <input type="text"
                                                   className="form-control"
                                                   name="location"
                                                   placeholder="Enter company location"
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   value={formik.values.location}/>
                                            {formik.touched.location && formik.errors.location &&
                                            <FormFeedback>{formik.errors.location}</FormFeedback>}
                                        </div>
                                    </div>
                                    <div className="col-xl-7">
                                        <div className="js-form-message form-group">
                                            <label className="input-label"> Industry/Sector </label>

                                            <Select
                                                defaultValue={formik.values.industry}
                                                style={{width: '100%'}}
                                                className={'select'}
                                                size={'large'}
                                                loading={industry_loading}
                                                name="industry"
                                                placeholder="Select industry"
                                                onBlur={formik.handleBlur}
                                                onChange={event => {
                                                    formik.values.industry = event;
                                                    formik.handleChange.industry = event;
                                                }}>
                                                {optionIndustry}
                                            </Select>
                                            {formik.touched.industry && formik.errors.industry &&
                                            <FormFeedback>{formik.errors.industry}</FormFeedback>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="js-form-message form-group">
                                            <label className="input-label"> Start Date </label>
                                            <DatePicker
                                                className="form-control"
                                                onBlur={formik.handleBlur}
                                                onChange={(date, dateString) => {
                                                    formik.values.start_date = dateString;
                                                    formik.handleChange.start_date = dateString;
                                                }} picker="month"/>
                                            {formik.touched.start_date && formik.errors.start_date &&
                                            <FormFeedback>{formik.errors.start_date}</FormFeedback>}
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="js-form-message form-group">
                                            <label className="input-label"> End Date </label>

                                            {formik.values.still_work && (formik.values.end_date = "")}
                                            <DatePicker
                                                disabled={(formik.values.still_work) && true}
                                                className="form-control"
                                                onBlur={formik.handleBlur}
                                                onChange={(date, dateString) => {
                                                    formik.values.end_date = dateString;
                                                    formik.handleChange.end_date = dateString;
                                                }} picker="month"/>
                                            {formik.touched.end_date && formik.errors.end_date &&
                                            <FormFeedback>{formik.errors.end_date}</FormFeedback>}

                                            <div className="js-form-message mb-5">
                                                <div
                                                    className="custom-control custom-checkbox d-flex align-items-center text-muted">
                                                    <input type="checkbox"
                                                           className="custom-control-input"
                                                           id="termsCheckbox"
                                                           name="still_work"
                                                           checked={formik.values.still_work}
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}/>
                                                    <label className="custom-control-label"
                                                           htmlFor={'termsCheckbox'}>
                                                        <small>
                                                            I currently still work here
                                                        </small>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=" text-sm-right">
                                    <LaddaButton
                                        loading={laddaLoading}
                                        progress={laddaProgress}
                                        data-style={ZOOM_OUT}
                                        data-spinner-size={30}
                                        data-spinner-color="#fff"
                                        data-spinner-lines={12}
                                        className="btn btn-primary transition-3d-hover"
                                        type="submit"
                                        disabled={formik.isSubmitting}>
                                        Add Experience
                                    </LaddaButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

const mapStateToProps = ({industry, skill, talent}) => {
    return {...skill, ...industry, ...talent}
}

export default connect(mapStateToProps, {getCountryLists, talentSaveExperience, getExperience})(Experience);
