import React from 'react';
import {Carousel} from "antd";
import LinkComponent from "../../route/LinkComponent";
import {ReactComponent as Qoute} from "../../_assets/media/svg/qoute.svg";

import Typical from "react-typical";

function OnBoard(props) {

    return (
        <>
            <div className="onboarding" style={{marginTop: '220px'}}>
                <div className="container">
                    <div className="row">
                        <article className="col-lg-6 mb-3 mb-sm-5 mb-lg-0">
                            <div
                                className=" align-items-end flex-wrap flex-row bg-img-hero h-100 min-h-380rem transition-3d-hover"
                                data-aos="fade-up">
                                <div className="mb-5">
                                    <h1 className={'head_lead_inner'}>Boost productivity with
                                        experienced <br/> <Typical
                                            steps={['remote talents', 3000, 'pre-vetted talents', 3000, 'dedicated talents', 3000]}
                                            loop={Infinity}
                                            wrapper="span"
                                        />
                                    </h1>
                                    <br/>
                                    <p>Built on standard web technology, teams use Front to build beautiful
                                        cross-platform
                                        hybrid apps in a fraction of the time.</p>
                                </div>
                            </div>
                        </article>

                        <article className="col-sm-6 item col-lg-3 mb-3 mb-sm-0">
                            <div className="card dev align-items-end flex-wrap flex-row bg-img-hero text-white h-100 min-h-380rem transition-3d-hover"
                                data-aos="fade-up" data-aos-delay="100">
                                <div className={'overlay'}/>
                                <div className="card-body">
                                    <h3 className="text-white"> Help build the industry of the future </h3>
                                    <LinkComponent to={'/talents/signup'}
                                                   className="btn btn-sm btn-primary btn-pill d-none d-sm-inline-block transition-3d-hover"> Talents Let
                                        Code </LinkComponent>
                                </div>
                            </div>
                        </article>

                        {/*https://unsplash.com/photos/ANGrwTKxjlk*/}
                        <article className="col-sm-6 item col-lg-3">
                            <div className="card client align-items-end flex-wrap flex-row bg-img-hero text-white h-100 min-h-380rem transition-3d-hover"
                                data-aos="fade-up" data-aos-delay="150">
                                <div className={'overlay'}/>
                                <div className="card-body">
                                    <h3 className="text-white"> Let's help build your idea with the best </h3>
                                    <LinkComponent to={'/client/signup'}
                                                   className="btn btn-sm btn-primary btn-pill d-none d-sm-inline-block transition-3d-hover"> Client Get
                                        Started </LinkComponent>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="container space-2 text-center" style={{marginTop: '120px'}}>
                    <div className="w-md-80 w-lg-50 position-relative mx-md-auto">
                        <blockquote className="lead text-dark font-weight-bold text-lh-lg mb-6">Our daily routines would
                            not merely serve purposes of utility or quick-fixes, but involve an exploration of our
                            creative and spiritual lives.
                        </blockquote>

                        <span className="d-block h4 mb-0">Bryan Chung</span>
                        <small>Co-Founder</small>

                        <figure className="position-absolute top-0 left-0 z-index-n1 mt-n10 ml-n10">
                            <Qoute />
                        </figure>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OnBoard;
