import React from 'react';
import {InterfaceConfig} from "../../route/InterfaceConfig";
import {ReactComponent as Logo} from "../../_assets/media/svg/logo_hash.svg";
import {ReactComponent as HalfCircle} from "../../_assets/media/svg/half-circle-1.svg";
import {ReactComponent as HalfCircleSec} from "../../_assets/media/svg/half-circle-2.svg";
import LinkComponent from "../../route/LinkComponent";

function Footer(props) {
    return (
        <>

            <footer className="gradient-x-overlay-sm-indigo overflow-hidden">
                <div className="container">
                    <div className="space-top-2 space-bottom-1 space-bottom-lg-2">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-3 ml-lg-auto mb-5 mb-lg-0">
                                <div className="mb-4">
                                    <LinkComponent to={'/'}>
                                        <Logo className="brand" style={{
                                            height: '32px',
                                            width: 'auto',
                                            color: '#fff'
                                        }}/>
                                    </LinkComponent>
                                </div>

                                <ul className="nav nav-sm nav-x-0  flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link media_" href="">
                                            <span className="media_">
                                              {/*<span className="fas fa-location-arrow mt-1 mr-2" />*/}
                                              <span className="media-body navstyle">
                                                  2600-207 Bank Street <br/> K2P 2N2, Ottawa ON, Canada
                                                {/*2600-207 Bank Street, Ottawa ON, Canada*/}
                                              </span>
                                            </span>
                                        </a>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-link media_" href="tel:1-062-109-9222">*/}
                                    {/*        <span className="media_">*/}
                                    {/*          <span className="fas fa-phone mt-1 mr-2" />*/}
                                    {/*          <span className="media-body">*/}
                                    {/*            +1 (888) 989-7878*/}
                                    {/*          </span>*/}
                                    {/*        </span>*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <a className="nav-link media_" href="tel:1-062-109-9222">
                                            <span className="media_">
                                              {/*<span className="fas fa-mail-bulk mt-1 mr-2" />*/}
                                              <span className="media-body navstyle">
                                                hi@borderlesshr.com
                                              </span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-3 col-lg"></div>

                            <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0">
                                <h5 className="">Company</h5>

                                {/*<LinkComponent*/}
                                {/*    className="btn btn btn-light transition-3d-hover btn-sm"*/}
                                {/*    to={"/jobs"}> Postings </LinkComponent>*/}

                                <ul className="nav nav-sm nav-x-0  flex-column">
                                    <li className="nav-item"><LinkComponent className="nav-link" to="/client/signup" >Hire Talent</LinkComponent></li>
                                    <li className="nav-item"><LinkComponent className="nav-link" to="/talents/signup" >Get Hired</LinkComponent></li>
                                    <li className="nav-item"><LinkComponent className="nav-link" to="/jobs"> Openings <span className="badge badge-primary ml-1">We're hiring</span> </LinkComponent></li>
                                    <li className="nav-item"><LinkComponent className="nav-link" to="/help/faqs">FAQs</LinkComponent></li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0">
                                <h5 className="">Features</h5>
                                <ul className="nav nav-sm nav-x-0  flex-column ">
                                    <li className="nav-item"><LinkComponent className="nav-link" to="/client/signup"> Blogs </LinkComponent></li>
                                    {/*<li className="nav-item"><a className="nav-link" href="#">Release notes</a></li>*/}
                                    {/*<li className="nav-item"><a className="nav-link" href="#">Integrations</a></li>*/}
                                    {/*<li className="nav-item"><a className="nav-link" href="#">Pricing</a></li>*/}
                                </ul>
                            </div>

                            <div className="col-6 col-md-3 col-lg">
                                <h5 className="">Resources</h5>

                                <ul className="nav nav-sm nav-x-0  flex-column ">
                                    <li className="nav-item">
                                        <LinkComponent className="nav-link" to={'/help'}>
                                            <span className="media_ align-items-center">
                                              <i className="fa fa-info-circle mr-2" />
                                              <span className="media-body">Help</span>
                                            </span>
                                        </LinkComponent>
                                    </li>
                                    <li className="nav-item">
                                        <LinkComponent className="nav-link" to={"/talents/login"}>
                                            <span className="media_ align-items-center">
                                              <i className="fa fa-user-circle mr-2" />
                                              <span className="media-body"> Account</span>
                                            </span>
                                        </LinkComponent>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/*<hr className="opac-ity-xs my-0"/>*/}

                    <div className="space-1">
                        <div className="row align-items-md-center">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <ul className="nav nav-sm  nav-x-sm align-items-center">
                                    <li className="nav-item">
                                        <LinkComponent to={'/policy'} className="nav-link" style={{color: '#6d4de6'}} >Privacy Policy</LinkComponent>
                                    </li>
                                    <li className="nav-item opacity mx-3">&#47;</li>
                                    <li className="nav-item">
                                        <LinkComponent to={"/terms"} className="nav-link" style={{color: '#6d4de6'}}>Terms &amp; Conditions</LinkComponent>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-6 text-md-right">
                                <p className=" opacity-sm small" style={{color: '#6d4de6', marginBottom: 0}}>&copy; {InterfaceConfig.APP_NAME}. {InterfaceConfig.C_YEAR}.
                                    All rights
                                    reserved.</p>
                                {/*<ul className="list-inline mb-0">*/}
                                {/*    <li className="list-inline-item">*/}
                                {/*        <a className="btn btn-xs btn-icon btn-soft-light" href="#">*/}
                                {/*            <i className="fas fa-facebook-f" />*/}
                                {/*        </a>*/}
                                {/*    </li>*/}
                                {/*    <li className="list-inline-item">*/}
                                {/*        <a className="btn btn-xs btn-icon btn-soft-light" href="#">*/}
                                {/*            <i className="fas fa-facebook-f" />*/}
                                {/*        </a>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                            </div>
                        </div>

                        {/*<div className="w-md-75 text-lg-center mx-lg-auto">*/}
                        {/*    <p className=" opacity-sm small">&copy; {InterfaceConfig.APP_NAME}. {InterfaceConfig.C_YEAR}.*/}
                        {/*        All rights*/}
                        {/*        reserved.</p>*/}
                        {/*    <p className=" opacity-sm small">When you visit or interact with our sites,*/}
                        {/*        services or tools, we or our authorised service providers may use cookies for*/}
                        {/*        storing information to help provide you with a better, faster and safer experience*/}
                        {/*        and for marketing purposes.</p>*/}
                        {/*</div>*/}
                    </div>
                </div>

                {/*<figure className="w-35 position-absolute top-0 right-0 z-index-n1 mt-n11 mr-n11">*/}
                {/*    <HalfCircle className="img-fluid"/>*/}
                {/*</figure>*/}
                {/*<figure className="w-25 position-absolute bottom-0 left-0 z-index-n1 mb-n11 ml-n11">*/}
                {/*    <HalfCircleSec className="img-fluid"/>*/}
                {/*</figure>*/}
            </footer>

            {/*<div className="container position-fixed bottom-0 right-0 left-0 z-index-4 ">*/}
            {/*    <div className="alert bg-white w-lg-80 border shadow-sm mx-auto hide hidden" role="alert">*/}
            {/*        <h5 className="text-dark">Cookie preferences</h5>*/}
            {/*        <p className="small"><span className="font-weight-bold">Note!</span> This is just a simple popup*/}
            {/*            modal to demonstrate how you can display a cookie message. This website uses the following types*/}
            {/*            of cookies; strictly necessary, functional, visitor statistics and advertising cookies.</p>*/}

            {/*        <div className="row align-items-sm-center">*/}
            {/*            <div className="col-sm-8 mb-3 mb-sm-0">*/}
            {/*                <div className="custom-control custom-checkbox custom-control-inline text-muted">*/}
            {/*                    <label className="custom-control-label">*/}
            {/*                        <small>Strictly necessary</small>*/}
            {/*                    </label>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="col-sm-4 text-sm-right">*/}
            {/*                <button type="button" className="btn btn-sm btn-primary transition-3d-hover"*/}
            {/*                        data-dismiss="alert" aria-label="Close">Got it!*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}

export default Footer;
