import api, {setToken} from "../api";
import {
    CLIENT_LOGIN,
    CLIENT_PROFILE,
    CLIENT_PROFILE_LOGIN_CLEAR,
    CLIENT_PROFILE_LOGIN_END,
    CLIENT_PROFILE_LOGIN_START, CLIENT_SAVE_POSTING, CLIENT_SAVE_POSTING_END, CLIENT_SAVE_POSTING_START,
    CLIENT_SIGNUP,
    CLIENT_STATS,
    CLIENT_STATS_END,
    CLIENT_STATS_START,
    CLIENT_TALENT_POOL,
    CLIENT_TALENT_POOL_END,
    CLIENT_TALENT_POOL_START, JOB_LIST, JOB_LIST_END, JOB_LIST_START,
    SUCCESS_CODE, TALENT_EDUCATION_CREATED,
    TALENT_PROFILE_LOGIN_CLEAR,
    TALENT_PROFILE_LOGIN_END, TALENT_SAVE_EDUCATION_END, TALENT_SAVE_EDUCATION_START,
    TALENT_STATS,
    TALENT_STATS_END,
    TALENT_STATS_START
} from "../constants";
import {clearSessionWithParams, setSession} from "../store/cookies";

export const clientSignUp = params => async dispatch => {
    dispatch({type: CLIENT_PROFILE_LOGIN_START})
    const {data} = await api.post(CLIENT_SIGNUP, params);
    if (data.status === SUCCESS_CODE) {
        const {token, expiry_time} = data;
        clearSessionWithParams('talent_profile'); //clear any logged in talent_profile
        setToken(token);
        setSession(token, expiry_time);
        dispatch({type: TALENT_PROFILE_LOGIN_CLEAR});
        dispatch({type: CLIENT_PROFILE_LOGIN_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const clientLogin = (params) => async dispatch => {
    dispatch({type: CLIENT_PROFILE_LOGIN_START});
    const {data} = await api.post(CLIENT_LOGIN, params);
    if (data.status === SUCCESS_CODE) {
        const {token, expiry_time} = data;
        setToken(token);
        setSession(token, expiry_time);
        clearSessionWithParams('talent_profile'); //clear any logged in talent_profile
        dispatch({type: TALENT_PROFILE_LOGIN_CLEAR});
        dispatch({type: CLIENT_PROFILE_LOGIN_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const getClientProfile = () => dispatch => {
    dispatch({type: CLIENT_PROFILE_LOGIN_START});
    api.get(CLIENT_PROFILE).then(({data}) => {
        if (data.success) {
            dispatch({type: CLIENT_PROFILE_LOGIN_END, payload: data.data});
        }
    });
}

export const getClientJobs = () => {
    return dispatch => {

        api.get(CLIENT_PROFILE).then(({data}) => {
            if (data.success) {
                dispatch({type: CLIENT_PROFILE_LOGIN_END, payload: data.data});
            }
        });

    }
}

export const getClientStat = () => dispatch => {
    dispatch({type: CLIENT_STATS_START});
    api.get(CLIENT_STATS).then(({data}) => {
        if (data.status === SUCCESS_CODE) {
            dispatch({type: CLIENT_STATS_END, payload: data.data});
        }
    });
}

export const getTalentPool = params => async dispatch => {
    dispatch({type: CLIENT_TALENT_POOL_START});
    const {data} = await api.get(CLIENT_TALENT_POOL, {params: params});
    if (data.status === SUCCESS_CODE) {
        dispatch({type: CLIENT_TALENT_POOL_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const clearTalentPool = () => dispatch => {
    dispatch({type: CLIENT_TALENT_POOL_END, payload: {}});
}

export const clientSavePosting = params => async dispatch => {
    dispatch({type: CLIENT_SAVE_POSTING_START});
    const {data} = await api.post(CLIENT_SAVE_POSTING, params);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: CLIENT_SAVE_POSTING_END});
        return {...data.data}
    } else {
        dispatch({type: CLIENT_SAVE_POSTING_END});
        throw new Error(data.message)
    }
}

// export const getTalentPool = params => dispatch => {
//     dispatch({type: CLIENT_TALENT_POOL_START});
//     api.get(CLIENT_TALENT_POOL, {params: params}).then(({data}) => {
//         if (data.status === SUCCESS_CODE) {
//             dispatch({type: CLIENT_TALENT_POOL_END, payload: data.data});
//         }
//     });
// }
