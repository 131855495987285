import {
    CLIENT_RESET_PASSWORD,
    PASSWORD_AUTH_LOADING_END,
    PASSWORD_AUTH_LOADING_START, SUCCESS_CODE, TALENT_RESET_PASSWORD
} from "../constants";
import api from "../api";

export const resetPassword = (params) => async dispatch => {
    dispatch({type: PASSWORD_AUTH_LOADING_START});
    let url = (params.type === 'client') ? CLIENT_RESET_PASSWORD : TALENT_RESET_PASSWORD;
    const {data} = await api.post(url, params);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: PASSWORD_AUTH_LOADING_END});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}
