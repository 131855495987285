import React, {forwardRef, useState} from 'react';
import LinkComponent from "../../route/LinkComponent";
import {FormFeedback} from "reactstrap";
import * as Yup from "yup";
import {useFormik} from "formik";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {notification} from "antd";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {connect} from "react-redux";
import {clientLogin} from "../../appRedux/actions/Clients";
import {redirectToClientProfile} from "../../route/Redirect";
import {handleNotify} from "../../appRedux/response";
import {clearSession, clearSessionWithParams} from "../../appRedux/store/cookies";

const ClientLogin = forwardRef((props, ref) => {
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);

    clearSessionWithParams('')

    const regSchema = Yup.object().shape({
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .email('Invalid email address').required('Email address is Required'),
        password: Yup.string()
            .required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: regSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            const {email, password: passwd} = values;
            props.clientLogin({email, passwd}).then(res => {
                handleIn(res)
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Client account successful',
            description: 'Welcome back to possibilities',
            duration: NOTIFICATION_DURATION,
        });
        setLaddaProgress(0.9);
        redirectToClientProfile();
    }

    return (
        <>
            <div className="row no-gutters">
                <div
                    className="col-md-8 offset-md-2 space-top-3 space-lg-0">
                    <form className="js-validate" onSubmit={formik.handleSubmit}>
                        <div className="mb-5 mb-md-7">
                            <h1 className="h2">Welcome back</h1>
                            {/*<p>Login to manage your business account.</p>*/}
                            <p>
                                <span className="font-size-1 text-muted">Don't have an account? </span>
                                <LinkComponent
                                    className="font-size-1 font-weight-bold"
                                    to={"/client/signup"}>Signup</LinkComponent>
                            </p>
                        </div>

                        <div className="js-form-message form-group">
                            <label className="input-label" htmlFor="signinSrEmail">Email address</label>
                            <input type="email"
                                   className="form-control"
                                   name="email"
                                   placeholder="Email address"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.email}
                                   aria-label="Email address"/>
                            {formik.touched.email && formik.errors.email &&
                            <FormFeedback>{formik.errors.email}</FormFeedback>}
                        </div>

                        <div className="js-form-message form-group">
                            <label className="input-label" htmlFor="signinSrPassword" tabIndex="0">
                              <span className="d-flex justify-content-between align-items-center">
                                Password
                                <LinkComponent
                                    to={"/client/forgot-password"}
                                    className="link-underline text-capitalize font-weight-normal"
                                >Forgot Password?</LinkComponent>
                              </span>
                            </label>
                            <input type="password" className="form-control" name="password"
                                   id="signinSrPassword"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.password}
                                   placeholder="********"
                                   aria-label="********"/>
                            {formik.touched.password && formik.errors.password &&
                            <FormFeedback>{formik.errors.password}</FormFeedback>}
                        </div>

                        <div className="row align-items-center mb-5">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                {/*<span className="font-size-1 text-muted">Don't have an account? </span>*/}
                                {/*<LinkComponent*/}
                                {/*    className="font-size-1 font-weight-bold"*/}
                                {/*    to={"/client/signup"}>Signup</LinkComponent>*/}
                            </div>

                            <div className="col-sm-6 text-sm-right">
                                <LaddaButton
                                    loading={laddaLoading}
                                    progress={laddaProgress}
                                    data-style={ZOOM_OUT}
                                    data-spinner-size={30}
                                    data-spinner-color="#fff"
                                    data-spinner-lines={12}
                                    className="btn btn-primary transition-3d-hover"
                                    type="submit"
                                    disabled={formik.isSubmitting}>
                                    Log in
                                </LaddaButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
})


export const mapStateToProps = ({client}) => {
    return {}
}

export default connect(mapStateToProps, {clientLogin})(ClientLogin);
