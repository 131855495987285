import {
    SETTING_CALL_END,
    SETTING_CALL_START,
    SETTING_PRIVACY_TERMS,
    SUCCESS_CODE
} from "../constants/index"
import api from "../api";
import {BLOG_URL, TOP_DEVELOPER, TOP_DEVELOPERS_END, TOP_DEVELOPERS_ENDED, TOP_DEVELOPERS_START} from "../constants";

export const getPrivacyTerms = () => {
    return dispatch => {
        dispatch({type: SETTING_CALL_START});
        api.get(SETTING_PRIVACY_TERMS).then(({data}) => {
            if (data.status === SUCCESS_CODE) {
                dispatch({type: SETTING_CALL_END, payload: data.data});
            }
        })
    }
}

export const getBlogPost = () => async dispatch => {
    const {data} = await api.getUrl(BLOG_URL);
    return data;
}


export const getTopDevelopers = () => async dispatch => {
    dispatch({type: TOP_DEVELOPERS_START});
    try {
        const {data} = await api.get(TOP_DEVELOPER);
        if (data.status === SUCCESS_CODE) {
            dispatch({type: TOP_DEVELOPERS_END, payload: data.data});
        }
    } catch (e) {
        dispatch({type: TOP_DEVELOPERS_ENDED});
        console.log(e.message)
    } finally {
        dispatch({type: TOP_DEVELOPERS_ENDED});
    }
}
