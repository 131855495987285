import {
    COUNTRY_LIST_START, COUNTRY_LIST_END
} from '../constants';

const INIT_STATE = {
    country_loading: false,
    country_list: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case COUNTRY_LIST_START: {
            return {...state, country_loading: true};
        }
        case COUNTRY_LIST_END: {
            return {...state, country_loading: false, country_list: action.payload};
        }
        default:
            return state;
    }
};
