import {
    TALENT_PROFILE_LOGIN_START,
    TALENT_PROFILE_LOGIN_END,
    TALENT_STATS_START,
    TALENT_STATS_END,
    TALENT_PROFILE_LOGIN_CLEAR,
    TALENT_SAVE_EDUCATION_START,
    TALENT_SAVE_EDUCATION_END,
    TITLE_LIST_START,
    TITLE_LIST_END,
    TALENT_SAVE_EXPERIENCE_START,
    TALENT_SAVE_EXPERIENCE_END,
    TALENT_DELETE_EXPERIENCE,
    TALENT_DELETE_EDUCATION,
    GENDER_LIST_START,
    GENDER_LIST_END,
    TALENT_UPDATE_END,
    TALENT_UPDATE_START,
    TALENT_SKILL_START,
    TALENT_SKILL_END,
    TALENT_TOP_SKILL_START, TALENT_TOP_SKILL_END, TALENT_VETTING_END, TALENT_VETTING_START, SET_TOKEN, REMOVE_TOKEN
} from '../constants';
import {getSessionJsonWithKey, setSessionWithValue} from "../store/cookies";
import {isEmpty} from "lodash"
import Education from '../../containers/TalentInfo/Education';

const INIT_STATE = {
    token: isEmpty(getSessionJsonWithKey('___session')) ? null : getSessionJsonWithKey('___session'),
    gender_loading: false,
    genders: {},
    talent_title_loading: false,
    talent_titles: {},
    talent_statc_loading: false,
    talent_statc: {},
    talent_skills_pin_loading: false,
    talent_skills_pin: [],
    talent_skills_loading: false,
    talent_skills: [],
    talent_profile_loading: false,
    talent_profile_update_loading: false,
    talent_profile: isEmpty(getSessionJsonWithKey('talent_profile')) ? {} : getSessionJsonWithKey('talent_profile'),
    talent_experience: [],
    talent_experience_loading: false,
    talent_education: [],
    talent_education_loading: false,
    talent_vetting: [],
    talent_vetting_skills: [],
    talent_vetting_loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TALENT_VETTING_START: {
            return {...state, talent_vetting_loading: true};
        }
        case TALENT_VETTING_END: {
            return {...state, talent_vetting_loading: false, talent_vetting: action.payload.code, talent_vetting_skills: action.payload.skills};
        }
        case TALENT_UPDATE_START: {
            return {...state, talent_profile_loading: true};
        }
        case TALENT_PROFILE_LOGIN_START: {
            return {...state, talent_profile_update_loading: true};
        }
        case TALENT_PROFILE_LOGIN_END: {
            setSessionWithValue(action.payload, 'talent_profile');
            return {...state, talent_profile_loading: false, talent_profile_update_loading: false, talent_profile: action.payload,};
        }
        case TALENT_PROFILE_LOGIN_CLEAR: {
            return {...state, talent_profile_loading: false, talent_profile: {}};
        }
        case TALENT_STATS_START: {
            return {...state, talent_statc_loading: true};
        }
        case TALENT_STATS_END: {
            return {...state, talent_statc_loading: false, talent_statc: action.payload,};
        }
        case TALENT_SKILL_START: {
            return {...state, talent_skills_loading: true};
        }
        case TALENT_TOP_SKILL_START: {
            return {...state, talent_skills_pin_loading: true};
        }
        case TALENT_TOP_SKILL_END: {
            return {...state, talent_skills_pin_loading: false, talent_skills_pin: action.payload,};
        }
        case TALENT_SKILL_END: {
            return {...state, talent_skills_loading: false, talent_skills: action.payload,};
        }
        case TALENT_SAVE_EXPERIENCE_START: {
            return {...state, talent_experience_loading: true};
        }
        case TALENT_SAVE_EXPERIENCE_END: {
            return {...state, talent_experience_loading: false, talent_experience: action.payload};
        }
        case TALENT_SAVE_EDUCATION_START: {
            return {...state, talent_education_loading: true};
        }
        case TALENT_SAVE_EDUCATION_END: {
            return {...state, talent_education_loading: false, talent_education: action.payload};
        }
        case TALENT_DELETE_EXPERIENCE: {
            const deleted_experience_id = action.payload;
            const newExperiencePayload = state.talent_experience.filter(experience => experience.id !== deleted_experience_id);
            return {...state, talent_experience_loading: false, talent_experience: newExperiencePayload};
        }
        case TALENT_DELETE_EDUCATION: {
            const deleted_education_id = action.payload;
            const newEducationPayload = state.talent_education.filter(education => education.id !== deleted_education_id);
            return {...state, talent_education_loading: false, talent_education: newEducationPayload};
        }
        case TITLE_LIST_START: {
            return {...state, talent_title_loading: true};
        }
        case TITLE_LIST_END: {
            return {...state, talent_title_loading: false, talent_titles: action.payload,};
        }
        case SET_TOKEN: {
            return {...state,token: action.payload,};
        }
        case REMOVE_TOKEN: {
            return {...state, token: null};
        }
        case GENDER_LIST_START: {
            return {...state, gender_loading: true};
        }
        case GENDER_LIST_END: {
            return {...state, gender_loading: false, genders: action.payload,};
        }
        default:
            return state;
    }
};
