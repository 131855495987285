import React from 'react';
import {ReactComponent as Logo} from "../../_assets/media/svg/logo_white.svg";
import LinkComponent from "../../route/LinkComponent";
import {useLocation} from "@reach/router";

function AuthHeader(props) {
    const location = useLocation();
    const url = location.href;
    const is_signup = url.indexOf('/signup') !== -1;

    return (
        <div>
            <header id="header" className="header header-bg-transparent header-abs-top">
                <div className="header-section">
                    <div id="logoAndNav" className="container-fluid">
                        <nav className="navbar navbar-expand header-navbar">
                            <LinkComponent className="d-none d-lg-flex navbar-brand header-navbar-brand" to={"/"}>
                                <Logo style={{height: '30px', width: 'auto'}}/>
                            </LinkComponent>

                            <LinkComponent
                                className="d-flex d-lg-none navbar-brand header-navbar-brand header-navbar-brand-collapsed"
                                to={"/"}>
                                <Logo style={{height: '30px', width: 'auto'}}/>
                            </LinkComponent>

                            {(props.type !== 'client') ?
                                <div className="ml-auto">
                                    {/*<LinkComponent className="btn btn-sm btn-link text-body" to={"/client/login"}>*/}
                                    {/*    <i className="fas fa-angle-left fa-sm mr-1"/> Get Onboard*/}
                                    {/*</LinkComponent>*/}
                                    {
                                        is_signup ?
                                        <LinkComponent
                                            className="btn btn-sm btn-primary transition-3d-hover"
                                            to={"/client/signup"}>
                                            Business Signup
                                        </LinkComponent> :
                                        <LinkComponent
                                            className="btn btn-sm btn-primary transition-3d-hover"
                                            to={"/client/login"}>
                                            Business Login
                                        </LinkComponent>
                                    }
                                </div> :
                                <div className="ml-auto">
                                    {/*<LinkComponent className="btn btn-sm btn-link text-body" to={"/get_onboard"}>*/}
                                    {/*    <i className="fas fa-angle-left fa-sm mr-1"/> Get Onboard*/}
                                    {/*</LinkComponent>*/}
                                    {
                                        is_signup ?
                                        <LinkComponent
                                            className="btn btn-sm btn-primary transition-3d-hover"
                                            to={"/talents/signup"}>
                                            Talent Signup
                                        </LinkComponent> :
                                        <LinkComponent
                                            className="btn btn-sm btn-primary transition-3d-hover"
                                            to={"/talents/login"}>
                                            Talent Login
                                        </LinkComponent>
                                    }
                                </div>}
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default AuthHeader;
