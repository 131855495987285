import {
    CLIENT_PROFILE_LOGIN_CLEAR,
    CLIENT_PROFILE_LOGIN_END,
    FORGET_PASSWORD,
    SUCCESS_CODE,
    TALENT_DELETE_EXPERIENCE,
    TALENT_EDUCATION,
    TALENT_EDUCATION_CREATED,
    TALENT_EXPERIENCE,
    TALENT_EXPERIENCE_CREATED,
    TALENT_EXPERIENCE_DELETE,
    TALENT_LOGIN,
    TALENT_PROFILE,
    TALENT_PROFILE_LOGIN_CLEAR,
    TALENT_PROFILE_LOGIN_END,
    TALENT_PROFILE_LOGIN_START,
    TALENT_SAVE_EDUCATION_END,
    TALENT_SAVE_EDUCATION_START,
    TALENT_SAVE_EXPERIENCE_END,
    TALENT_SAVE_EXPERIENCE_START,
    TALENT_SIGNUP,
    TALENT_SKILL_CREATED,
    TALENT_SKILL_DELETE,
    TALENT_SKILL_END,
    TALENT_SKILL_PIN,
    TALENT_SKILL_START,
    TALENT_SKILLS,
    TALENT_STATS,
    TALENT_STATS_END,
    TALENT_STATS_START,
    TALENT_TOP_SKILL_END,
    TALENT_TOP_SKILL_START,
    TALENT_UPDATE,
    TALENT_UPDATE_END,
    TALENT_UPDATE_START,
    TALENT_EDUCATION_DELETE,
    TALENT_DELETE_EDUCATION_START,
    TALENT_DELETE_EDUCATION_END,
    TALENT_DELETE_EDUCATION, TALENT_VETTING, TALENT_VETTING_START, TALENT_VETTING_END, SET_TOKEN
} from "../constants";
import api, {setToken} from "../api";
import {clearSession, clearSessionWithParams, setSession} from "../store/cookies";
import {handleNotify, handleNotifyMessage} from "../response";
import {redirectToClientLogin, redirectToTalentLogin} from "../../route/Redirect";

const authTalent = (url, params) => async dispatch => {
    dispatch({type: TALENT_PROFILE_LOGIN_START});
    const {data} = await api.post(url, params);
    if (data.status === SUCCESS_CODE) {
        const {token, expiry_time} = data;
        clearSessionWithParams('client_profile'); //clear any logged in client_profile
        setToken(token);
        setSession(token, expiry_time);
        dispatch({type: SET_TOKEN, payload: token});
        dispatch({type: CLIENT_PROFILE_LOGIN_CLEAR});
        dispatch({type: TALENT_PROFILE_LOGIN_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const talentSignUp = params => async dispatch => (await dispatch(authTalent(TALENT_SIGNUP, params)))
export const talentLogin = params => async dispatch => (await dispatch(authTalent(TALENT_LOGIN, params)))

// export const talentSignUp = params => async dispatch( => {
//     dispatch({type: TALENT_PROFILE_LOGIN_START});
//     const {data} = await api.post(TALENT_SIGNUP, params);
//     if (data.status === SUCCESS_CODE) {
//         const {token, expiry_time} = data;
//         setToken(token);
//         setSession(token, expiry_time);
//         clearSessionWithParams('client_profile'); //clear any logged in client_profile
//         dispatch({type: CLIENT_PROFILE_LOGIN_CLEAR});
//         dispatch({type: TALENT_PROFILE_LOGIN_END, payload: data.data});
//         return {...data.data}
//     } else {
//         throw new Error(data.message)
//     }
// })
//
// export const talentLogin = (params) => async dispatch => {
//     dispatch({type: TALENT_PROFILE_LOGIN_START});
//     const {data} = await api.post(TALENT_LOGIN, params);
//     if (data.status === SUCCESS_CODE) {
//         const {token, expiry_time} = data;
//         setToken(token);
//         setSession(token, expiry_time);
//         clearSessionWithParams('client_profile'); //clear any logged in client_profile
//         dispatch({type: CLIENT_PROFILE_LOGIN_CLEAR});
//         dispatch({type: TALENT_PROFILE_LOGIN_END, payload: data.data});
//         return {...data.data}
//     } else {
//         throw new Error(data.message)
//     }
// }

export const forgotPassword = (params) => async dispatch => {
    const {data} = await api.post(FORGET_PASSWORD, params);
    if (data.status === SUCCESS_CODE) {
        return data
    } else {
        throw new Error(data.message)
    }
}

export const talentSaveExperience = params => async dispatch => {
    dispatch({type: TALENT_SAVE_EXPERIENCE_START});
    const {data} = await api.post(TALENT_EXPERIENCE_CREATED, params);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_SAVE_EXPERIENCE_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const getExperience = () => async dispatch => {
    dispatch({type: TALENT_SAVE_EXPERIENCE_START});
    const {data} = await api.get(TALENT_EXPERIENCE);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_SAVE_EXPERIENCE_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const deleteExperience = experience_id => {
    return dispatch => {
        api.get(TALENT_EXPERIENCE_DELETE + experience_id).then(({data}) => {
            if (data.status === SUCCESS_CODE) {
                handleNotifyMessage(data.message, 'success');
                dispatch({type: TALENT_DELETE_EXPERIENCE, payload: experience_id});
                return {...data.data}
            }
        })
    }
}

export const deleteSkill = id => async dispatch => {
    const {data} = await api.get(TALENT_SKILL_DELETE + id);
    if (data.status === SUCCESS_CODE) {
        dispatch(getTalentSKills());
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const talentSaveEducation = params => async dispatch => {
    dispatch({type: TALENT_SAVE_EDUCATION_START});
    const {data} = await api.post(TALENT_EDUCATION_CREATED, params);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_SAVE_EDUCATION_END, payload: data.data});
        return {...data.data}
    } else {
        dispatch({type: TALENT_SAVE_EDUCATION_END, payload: []});
        throw new Error(data.message)
    }
}

export const updateTalentSkills = params => async dispatch => {
    dispatch({type: TALENT_SKILL_START});
    const {data} = await api.post(TALENT_SKILL_CREATED, params);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_SKILL_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const updatePinTalentSkill = (id, type) => async dispatch => {
    dispatch({type: TALENT_TOP_SKILL_START});
    const {data} = await api.post(`${TALENT_SKILL_PIN}/${id}/${type}`);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_TOP_SKILL_END});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const getEducation = () => async dispatch => {
    dispatch({type: TALENT_SAVE_EDUCATION_START});
    const {data} = await api.get(TALENT_EDUCATION);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_SAVE_EDUCATION_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const deleteEducation = (education_id) => async dispatch => {
    api.get(TALENT_EDUCATION_DELETE + education_id).then(({data}) => {
        if (data.status === SUCCESS_CODE) {
            dispatch(getEducation())
            handleNotifyMessage(data.message, 'success');
            dispatch({type: TALENT_DELETE_EDUCATION, payload: education_id});
            return {...data.data}
        }
    })
}

export const updateTalent = params => async dispatch => {
    dispatch({type: TALENT_UPDATE_START});
    const {data} = await api.post(TALENT_UPDATE, params);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: TALENT_PROFILE_LOGIN_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const getTalentProfile = () => dispatch => {
    dispatch({type: TALENT_PROFILE_LOGIN_START});
    api.get(TALENT_PROFILE).then((data) => {
        if(typeof data != "undefined") {
            const d = data.data
            if (d.status === SUCCESS_CODE) {
                dispatch({type: TALENT_PROFILE_LOGIN_END, payload: d.data});
            }
        }
    });
}

export const getTalentStat = () => dispatch => {
    dispatch({type: TALENT_STATS_START});
    api.get(TALENT_STATS).then(({data}) => {
        if (data.status === SUCCESS_CODE) {
            dispatch({type: TALENT_STATS_END, payload: data.data});
        }
    });
}

export const getTalentSKills = () => dispatch => {
    dispatch({type: TALENT_SKILL_START});
    api.get(TALENT_SKILLS).then(({data}) => {
        if (data.status === SUCCESS_CODE) {
            dispatch({type: TALENT_SKILL_END, payload: data.data});
        }
    });
}

export const getProfileSkillVetting = () => dispatch => {
    dispatch({type: TALENT_VETTING_START});
    api.get(TALENT_VETTING).then(({data}) => {
        if (data.status === SUCCESS_CODE) {
            dispatch({type: TALENT_VETTING_END, payload: data.data});
        }
    });
}

export const userSignOut = (accountType) => async dispatch => {
    clearSessionWithParams('talent_profile');
    clearSessionWithParams('client_profile');
    dispatch({type: TALENT_PROFILE_LOGIN_CLEAR});
    dispatch({type: CLIENT_PROFILE_LOGIN_CLEAR});
    clearSession();
    if (accountType === 'client') {
        redirectToTalentLogin()
    } else {
        redirectToClientLogin()
    }
}
