import axios from 'axios';
import {getSession, getSessionWithKey} from "./store/cookies";
import {isEmpty} from "lodash";
import {redirectToClientLogin, redirectToProfile, redirectToTalentLogin} from "../route/Redirect";

export const settings = {
    // baseURL: `https://base-borderless.com/api`,
    baseURL: `http://borderless-admin.var/api`,
    //timeout: 1000,
    headers: {
        // 'X-Requested-With': 'XMLHttpRequest',
        // 'accept-encoding': 'gzip, deflate, br',
        // 'sec-fetch-mode': 'cors',
        // 'sec-fetch-site': 'same-origin',
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/json;charset=utf-8',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Credentials': true,
        // 'Access-Control-Expose-Headers': 'access_token',
        //  // 'Access-Control-Request-Headers': 'authorization',
        //  'Authorization': `Bearer ${getSession()}`,
        //  'common': {
        //      'access-token': `Bearer ${getSession()}`,
        //      'Authorization': `Bearer ${getSession()}`,
        //  }
    },
};
//pk_live_v00dnCBj1BPSzSrCZcvohwn900vCfFKiTn
// Request header field access-control-allow-origin is not allowed by Access-Control-Allow-Headers in preflight response.

const instance = axios.create(settings);
export const bearer = `Bearer ${getSession()}`;
const token = getSession();
if (token != null && !isEmpty(token)) {
    instance.defaults.headers.common['Authorization'] = bearer;
}

export const getUrl = url => {
    const baseURL = sessionStorage.getItem('BASE_URL');
    return (window.location.origin === 'http://localhost:3000') ? `${settings.baseURL}${url}` : `${baseURL}${url}`;
};

const onResponseSuccess = response => {
    if (!response.data.success && response.data.error_code === 401 ) { //|| response.data.error_code === 401
        console.log(response.data)
        if(!isEmpty(getSessionWithKey('talent_profile')) && isEmpty(getSessionWithKey('client_profile'))) return redirectToTalentLogin();
        if(isEmpty(getSessionWithKey('talent_profile')) && !isEmpty(getSessionWithKey('client_profile'))) return redirectToClientLogin();
        if(isEmpty(getSessionWithKey('talent_profile')) && isEmpty(getSessionWithKey('client_profile'))) return redirectToTalentLogin();
    } else {
        return Promise.resolve(response);
    }
};

const onResponseFail = error => {
    // const status = error.status || error.response.status;
    // if (status === 403 || status === 401) {
    //     console.log(error)
    //     // const checkInUse = _.isEmpty(getSessionWithKey('__checkInUse'))
    //     //     ? false
    //     //     : getSessionWithKey('__checkInUse');
    //     // if (checkInUse === false) {
    //     //     setSessionWithValue(true, '__checkInUse');
    //     //     return redirectToLogin();
    //     //     // return onUnauthenticated();
    //     // }
    // }
    return Promise.reject(error);
};

instance.interceptors.response.use(onResponseSuccess, onResponseFail);

export default {
    async getUrl(url, request) {
        return await instance.get(url, request)
    },
    async get(url, request) {
        return await instance.get(getUrl(url), request)
    },
    async post(url, request) {
        return await instance.post(getUrl(url), request)
    },
    async put(url, request) {
        return await instance.put(getUrl(url), request)
    },
    async patch(url, request) {
        return await instance.patch(getUrl(url), request)
    },
    async delete(url, request) {
        return await instance.delete(getUrl(url), request)
    },
};

export const setToken = token => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};
