import React, {useEffect} from 'react';
import {ProgressWidget, TopEarningsWidget, StatsWidget1, LoadingMini} from "../../components";
import {toAbsoluteUrl} from "../../_helpers";
import SVG from "react-inlinesvg";
import LinkComponent from "../../route/LinkComponent";
import {connect} from "react-redux";
import {getTalentStat} from "../../appRedux/actions/Talents";

function Overview(props) {

    useEffect(() => {
        props.getTalentStat()
    }, []);


    const {talent_profile_loading, talent_profile, talent_statc_loading, talent_statc} = props;

    return (
        <>
            <div className="t_overview">
                <div>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="card text-center h-100 transition-3d-hover">
                                <div className="card-body p-lg-5">
                                    <h3 className="h4">Applied Jobs</h3>
                                    {/*<div className="text-body mb-0"*/}
                                    {/*     style={{fontSize: '76px'}}>{talent_statc_loading ? <>*/}
                                    {/*    <LoadingMini/></> : <>{talent_statc.count_talents_jobs}</>}*/}
                                    {/*</div>*/}
                                    <div className="text-body mb-0"
                                         style={{fontSize: '76px'}}>{talent_statc_loading ? <>
                                        <LoadingMini/></> : <>{talent_statc.count_talents_jobs}</>}
                                    </div>
                                </div>
                                <div className="card-footer font-weight-bold py-3 px-lg-5">
                                    <LinkComponent to={'/profile/applied-jobs'}>
                                        Visit Applied Jobs <i className="fas fa-angle-right fa-sm ml-1"/>
                                    </LinkComponent>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center h-100 transition-3d-hover">
                                <div className="card-body p-lg-5">
                                    <h3 className="h4"> Working Experience </h3>
                                    {/*<div className="text-body mb-0"*/}
                                    {/*     style={{fontSize: '76px'}}>{talent_statc_loading ? <>*/}
                                    {/*    <LoadingMini/></> : <>{talent_statc.count_talents_jobs}</>}*/}
                                    {/*</div>*/}
                                    <div className="text-body mb-0"
                                         style={{fontSize: '76px'}}>{talent_statc_loading ? <>
                                        <LoadingMini/></> : <>{talent_statc.count_talents_experience}</>}
                                    </div>
                                </div>
                                <div className="card-footer font-weight-bold py-3 px-lg-5">
                                    <LinkComponent to={'/profile/info'}>
                                        Visit Experience <i className="fas fa-angle-right fa-sm ml-1"/>
                                    </LinkComponent>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center h-100 transition-3d-hover">
                                <div className="card-body p-lg-5">
                                    <h3 className="h4"> Add Skills </h3>
                                    {/*<div className="text-body mb-0"*/}
                                    {/*     style={{fontSize: '76px'}}>{talent_statc_loading ? <>*/}
                                    {/*    <LoadingMini/></> : <>{talent_statc.count_talents_jobs}</>}*/}
                                    {/*</div>*/}
                                    <div className="text-body mb-0"
                                         style={{fontSize: '76px'}}>{talent_statc_loading ? <>
                                        <LoadingMini/></> : <>{talent_statc.count_talents_skills}</>}
                                    </div>
                                </div>
                                <div className="card-footer font-weight-bold py-3 px-lg-5">
                                    <LinkComponent to={'/profile/info'}>
                                        Visit Profile <i className="fas fa-angle-right fa-sm ml-1"/>
                                    </LinkComponent>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}


const mapStateToProps = ({talent}) => {
    return {...talent}
}

export default connect(mapStateToProps, {getTalentStat})(Overview)
