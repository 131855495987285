import React, {useEffect, useState} from 'react';
import LinkComponent from "../../route/LinkComponent";
import {connect, useSelector} from "react-redux";
import {getTalentProfile, getTalentStat, updateTalent} from "../../appRedux/actions/Talents";
import {notification, Select, Spin, message, Upload, Button, Form, Skeleton} from "antd";
import {LoadingMini} from "../../components/Loading";
import {Progress} from 'antd';
import {useFormik} from "formik";
import {handleNotify} from "../../appRedux/response";
import * as Yup from "yup";
import {FormFeedback} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PhoneInput from "react-phone-number-input";
import {NOTIFICATION_DURATION, UPLOAD_FILE} from "../../appRedux/constants";
import {getCountryLists} from "../../appRedux/actions/Country";
import {UploadOutlined, FilePdfOutlined} from '@ant-design/icons';
import {storage} from "../../_helpers/Firebase"
import pdfsvg from "../../_assets/media/svg/pdf.svg"
import deleteBtn from "../../_assets/media/svg/delete-button.svg"
import {getUrl, settings, bearer} from '../../appRedux/api';
import {isEmpty} from 'lodash';

const storageRef = storage.ref();

const {Option} = Select;

function Profile(props) {

    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [defaultFile, setDefaultFile] = useState([]);
    const [defaultFileCover, setDefaultFileCover] = useState([]);

    const {talent_profile_loading, talent_profile, country_loading, country_list, genders, gender_loading, talent_titles, talent_title_loading} = props;

    const talent_id = talent_profile.id;

    useEffect(() => {
        props.getCountryLists()
    }, []);

    useEffect(() => {
        if (!isEmpty(talent_profile.resume_url)) {
            setDefaultFile([{
                uid: '1',
                name: talent_profile.resume_url,
                status: 'done',
                url: talent_profile.resume,
            }])
        }

        if (!isEmpty(talent_profile.cover_letter_url)) {
            setDefaultFileCover([{
                uid: '2',
                name: talent_profile.cover_letter_url,
                status: 'done',
                url: talent_profile.cover_letter,
            }])
        }
    }, [talent_profile]);

    const regProfileSchema = Yup.object().shape({
        name: Yup.string().nullable().required('name is required'),
        email: Yup.string().nullable().required('email is required'),
        citizenship: Yup.string().nullable().required('citizenship is required'),
        title: Yup.string().nullable().required('title is required'),
        current_location: Yup.string().nullable().required('current location is required'),
        gender: Yup.string().nullable().required('gender is required'),
        number: Yup.number().nullable().required('number is required'),
        address: Yup.string().nullable().required('address is required'),
        linkedin: Yup.string().nullable().url().required('linkedin url is required'),
        github: Yup.string().nullable().url().required('github link is required'),
        portfolio_url: Yup.string().nullable().url(),
        twitter_url: Yup.string().nullable().url()
    });

    const formik = useFormik({
        initialValues: {
            name: talent_profile.name,
            email: talent_profile.email,
            citizenship: talent_profile.citizenship,
            title: talent_profile.title_id,
            current_location: talent_profile.location,
            gender: talent_profile.gender_id,
            number: talent_profile.phone_number,
            address: talent_profile.address,
            linkedin: talent_profile.linkedin,
            github: talent_profile.github,
            twitter_url: talent_profile.twitter_url,
            portfolio_url: talent_profile.portfolio_url,
            // expectation_usd: talent_profile.expectation_usd,
            // expectation_cad: talent_profile.expectation_cad,
            // expectation_eur: talent_profile.expectation_eur,
        },
        validationSchema: regProfileSchema,
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            props.updateTalent(values).then(res => {
                handleIn(res)
                setSubmitting(false);
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Profile updated successful',
            description: res.message,
            duration: NOTIFICATION_DURATION,
        });
        setLaddaLoading(false);
        setLaddaProgress(1.0);
    }

    let options = [];
    let optionTitles = [];
    let optionsExpectation= [
        <Option value={'40,000 - 70,000'}>40,000 - 70,000</Option>,
        <Option value={'70,000 - 80,000'}>70,000 - 80,000</Option>,
        <Option value={'80,000 - 90,000'}>80,000 - 90,000</Option>,
        <Option value={'90,000 - 100,000'}>90,000 - 100,000</Option>,
        <Option value={'100,000+'}>100,000+</Option>
    ];
    let optionsC = [];
    let optionsGender = [];
    for (let country in country_list) {
        options.push(<Option key={country} value={country}>{country_list[country]}</Option>)
        optionsC.push(<Option key={country} value={country}>{country_list[country]}</Option>)
    }
    for (let gender in genders) {
        optionsGender.push(<Option key={gender} value={parseInt(gender)}>{genders[gender]}</Option>)
    }
    for (let title in talent_titles) {
        optionTitles.push(<Option key={title} value={parseInt(title)}>{talent_titles[title]}</Option>)
    }

    const headers = {'Authorization': bearer};
    const url = getUrl(UPLOAD_FILE);
    const uploads = {
        name: 'resume',
        maxCount: 1,
        action: url,
        headers: headers,
        method: 'post',
        data: {
            type: 'resume'
        },
        accept: ".pdf",
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                const {data} = info.file.response;
                console.log('data', data);
                setDefaultFile([{
                    uid: '1',
                    name: data.resume_url,
                    status: 'done',
                    url: data.resume,
                }])
                message.success(`${info.file.name} file uploaded successfully`);
                props.getTalentProfile();
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        fileList: defaultFile,
    };

    const uploadCoverLetter = {
        name: 'cover_letter',
        maxCount: 1,
        action: url,
        headers: headers,
        method: 'post',
        data: {
            type: 'cover_letter'
        },
        accept: ".pdf",
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                const {data} = info.file.response;
                setDefaultFileCover([{
                    uid: '2',
                    name: data.cover_letter_url,
                    status: 'done',
                    url: data.cover_letter,
                }])
                message.success(`${info.file.name} file uploaded successfully`);
                props.getTalentProfile();
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        fileList: defaultFileCover,
    };

    return (
        <>
            <div>
                <div className={'card mb-5'}>
                    <div className="card-header">
                        <h5 className="card-header-title"> Personal Information </h5>
                    </div>
                    <div className="card-body">

                        <div className="row">
                            <div className={"col-md-12"}>

                                <form className="js-validate" onSubmit={formik.handleSubmit}>
                                    <div className='row'>
                                        <div className={"col-md-3"}>
                                            {/*<h3 className={'card-title-inner d-block text-dark mb-2'}> Personal*/}
                                            {/*    Information</h3>*/}
                                            <p className={'d-block font-size-1 mb-2'}>Your Name & Contact
                                                Information</p>
                                        </div>
                                        <div className={"col-md-9"}>
                                            <div className="js-form-message form-group required-field">
                                                <label className="input-label"> Full Name </label>
                                                <input type="text"
                                                       className="form-control"
                                                       name="name"
                                                       placeholder="Enter full name"
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       value={formik.values.name}/>
                                                {formik.touched.name && formik.errors.name &&
                                                <FormFeedback>{formik.errors.name}</FormFeedback>}
                                            </div>
                                            <div className="js-form-message form-group required-field">
                                                <label className="input-label"> email Address </label>
                                                <input type="email"
                                                       className="form-control"
                                                       name="email"
                                                       placeholder="Enter email address"
                                                       disabled
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       value={formik.values.email}/>
                                                <p className="form-text mess"><small style={{color: 'red'}}> Provided
                                                    email
                                                    address can not be changed </small></p>
                                                {formik.touched.email && formik.errors.email &&
                                                <FormFeedback>{formik.errors.email}</FormFeedback>}
                                            </div>

                                            <div className={'row'}>
                                                <div className="col-xl-5">
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Gender </label>

                                                        <Select
                                                            defaultValue={formik.values.gender}
                                                            style={{width: '100%'}}
                                                            className={'select'}
                                                            size={'large'}
                                                            loading={gender_loading}
                                                            name="gender"
                                                            placeholder="Select gender"
                                                            onBlur={formik.handleBlur}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={event => {
                                                                formik.values.gender = event;
                                                                formik.handleChange.gender = event;
                                                            }}>
                                                            {optionsGender}
                                                        </Select>
                                                        {formik.touched.gender && formik.errors.gender &&
                                                        <FormFeedback>{formik.errors.gender}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className="col-xl-7">
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Phone number </label>

                                                        <PhoneInput
                                                            className="form-control"
                                                            placeholder="Enter phone number"
                                                            name={"number"}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.number}
                                                            onChange={event => {
                                                                formik.values.number = event;
                                                                formik.handleChange.number = event;
                                                            }}/>
                                                        {formik.touched.number && formik.errors.number &&
                                                        <FormFeedback>{formik.errors.number}</FormFeedback>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-8 mt-4'>
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className={"col-md-3"}>
                                            <p className={'d-block font-size-1 mb-2'}>Your Professional
                                                Information</p>
                                        </div>
                                        <div className={"col-md-9"}>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Title / Profession </label>

                                                        <Select
                                                            defaultValue={formik.values.title}
                                                            style={{width: '100%'}}
                                                            className={'select'}
                                                            size={'large'}
                                                            loading={talent_title_loading}
                                                            name="title"
                                                            placeholder="Select Title / Profession"
                                                            onBlur={formik.handleBlur}
                                                            onChange={event => {
                                                                formik.values.title = event;
                                                                formik.handleChange.title = event;
                                                            }}>
                                                            {optionTitles}
                                                        </Select>
                                                        {formik.touched.title && formik.errors.title &&
                                                        <FormFeedback>{formik.errors.title}</FormFeedback>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-8 mt-4'>
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row '>
                                        <div className={"col-md-3"}>
                                            <p className={'d-block font-size-1 mb-2'}> Payment Expectation </p>
                                        </div>
                                        <div className={"col-md-9"}>
                                            <div className="row">
                                                <div className={"col-md-12"}>
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Annual salary expectation (Canadian Dollar) </label>
                                                        <Select
                                                            defaultValue={formik.values.expectation_cad}
                                                            style={{width: '100%'}}
                                                            className={'select'}
                                                            size={'large'}
                                                            name="title"
                                                            placeholder="Expectation (Canadian Dollar)"
                                                            onBlur={formik.handleBlur}
                                                            onChange={event => {
                                                                formik.values.expectation_cad = event;
                                                                formik.handleChange.expectation_cad = event;
                                                            }}>
                                                            {optionsExpectation}
                                                        </Select>
                                                        {formik.touched.expectation_cad && formik.errors.expectation_cad &&
                                                        <FormFeedback>{formik.errors.expectation_cad}</FormFeedback>}
                                                    </div>
                                                </div>
                                                {/*<div className={"col-md-12"}>*/}
                                                {/*    <div className="js-form-message form-group required-field">*/}
                                                {/*        <label className="input-label"> Annual salary expectation (Euro) </label>*/}
                                                {/*        <Select*/}
                                                {/*            defaultValue={formik.values.expectation_eur}*/}
                                                {/*            style={{width: '100%'}}*/}
                                                {/*            className={'select'}*/}
                                                {/*            size={'large'}*/}
                                                {/*            name="title"*/}
                                                {/*            placeholder="Expectation (EUR)"*/}
                                                {/*            onBlur={formik.handleBlur}*/}
                                                {/*            onChange={event => {*/}
                                                {/*                formik.values.expectation_eur = event;*/}
                                                {/*                formik.handleChange.expectation_eur = event;*/}
                                                {/*            }}>*/}
                                                {/*            {optionsExpectation}*/}
                                                {/*        </Select>*/}
                                                {/*        {formik.touched.expectation_eur && formik.errors.expectation_eur &&*/}
                                                {/*        <FormFeedback>{formik.errors.expectation_eur}</FormFeedback>}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className={"col-md-12"}>*/}
                                                {/*    <div className="js-form-message form-group required-field">*/}
                                                {/*        <label className="input-label"> Annual salary expectation (Dollar) </label>*/}
                                                {/*        <Select*/}
                                                {/*            defaultValue={formik.values.expectation_usd}*/}
                                                {/*            style={{width: '100%'}}*/}
                                                {/*            className={'select'}*/}
                                                {/*            size={'large'}*/}
                                                {/*            name="title"*/}
                                                {/*            placeholder="Expectation (Dollar)"*/}
                                                {/*            onBlur={formik.handleBlur}*/}
                                                {/*            onChange={event => {*/}
                                                {/*                formik.values.expectation_usd = event;*/}
                                                {/*                formik.handleChange.expectation_usd = event;*/}
                                                {/*            }}>*/}
                                                {/*            {optionsExpectation}*/}
                                                {/*        </Select>*/}
                                                {/*        {formik.touched.expectation_usd && formik.errors.expectation_usd &&*/}
                                                {/*        <FormFeedback>{formik.errors.expectation_usd}</FormFeedback>}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                            </div>
                                            <div className='mb-8 mt-4'>
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className={"col-md-3"}>
                                            <p className={'d-block font-size-1 mb-2'}>Your Name & Contact
                                                Information</p>
                                        </div>
                                        <div className={"col-md-9"}>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Address </label>
                                                        <textarea role="4"
                                                                  className="form-control"
                                                                  name="address"
                                                                  placeholder="Address"
                                                                  onChange={formik.handleChange}
                                                                  onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.address && formik.errors.address &&
                                                        <FormFeedback>{formik.errors.address}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> What is your
                                                            citizenship </label>
                                                        <Select
                                                            showSearch
                                                            defaultValue={formik.values.citizenship}
                                                            style={{width: '100%'}}
                                                            className={'select'}
                                                            size={'large'}
                                                            loading={country_loading}
                                                            name="citizenship"
                                                            placeholder="Select citizenship"
                                                            onBlur={formik.handleBlur}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={event => {
                                                                formik.values.citizenship = event;
                                                                formik.handleChange.citizenship = event;
                                                            }}>
                                                            {options}
                                                        </Select>
                                                        {formik.touched.citizenship && formik.errors.citizenship &&
                                                        <FormFeedback>{formik.errors.citizenship}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Where are you currently
                                                            located? </label>

                                                        <Select
                                                            defaultValue={formik.values.current_location}
                                                            style={{width: '100%'}}
                                                            className={'select'}
                                                            size={'large'}
                                                            loading={country_loading}
                                                            name="current_location"
                                                            placeholder="Select current location"
                                                            onBlur={formik.handleBlur}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={event => {
                                                                formik.values.current_location = event;
                                                                formik.handleChange.current_location = event;
                                                            }}>
                                                            {optionsC}
                                                        </Select>
                                                        {formik.touched.current_location && formik.errors.current_location &&
                                                        <FormFeedback>{formik.errors.current_location}</FormFeedback>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-8 mt-4'>
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className={"col-md-3"}>
                                            <p className={'d-block font-size-1 mb-2'}> Important Links </p>
                                        </div>
                                        <div className={"col-md-9"}>
                                            <div className="row">
                                                <div className={"col-md-6"}>
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Linkedin Profile </label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="linkedin"
                                                               placeholder="Linkedin profile"
                                                               onChange={formik.handleChange}
                                                               onBlur={formik.handleBlur}
                                                               value={formik.values.linkedin}/>
                                                        {formik.touched.linkedin && formik.errors.linkedin &&
                                                        <FormFeedback>{formik.errors.linkedin}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 "}>
                                                    <div className="js-form-message form-group required-field">
                                                        <label className="input-label"> Github Profile </label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="github"
                                                               placeholder="Github profile"
                                                               onChange={formik.handleChange}
                                                               onBlur={formik.handleBlur}
                                                               value={formik.values.github}/>
                                                        {formik.touched.github && formik.errors.github &&
                                                        <FormFeedback>{formik.errors.github}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className={"col-md-6"}>
                                                    <div className="js-form-message form-group">
                                                        <label className="input-label"> Portfolio url </label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="portfolio_url"
                                                               placeholder="Portfolio url"
                                                               onChange={formik.handleChange}
                                                               onBlur={formik.handleBlur}
                                                               value={formik.values.portfolio_url}/>
                                                        {formik.touched.portfolio_url && formik.errors.linkedin &&
                                                        <FormFeedback>{formik.errors.portfolio_url}</FormFeedback>}
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 "}>
                                                    <label className="input-label"> Twitter url </label>
                                                    <div className="js-form-message form-group">
                                                        <label className="input-label"> </label>
                                                        <input type="text"
                                                               className="form-control"
                                                               name="twitter_url"
                                                               placeholder="Twitter url"
                                                               onChange={formik.handleChange}
                                                               onBlur={formik.handleBlur}
                                                               value={formik.values.twitter_url}/>
                                                        {formik.touched.twitter_url && formik.errors.twitter_url &&
                                                        <FormFeedback>{formik.errors.twitter_url}</FormFeedback>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" text-sm-right">
                                        <LaddaButton
                                            loading={laddaLoading}
                                            progress={laddaProgress}
                                            data-style={ZOOM_OUT}
                                            data-spinner-size={30}
                                            data-spinner-color="#fff"
                                            data-spinner-lines={12}
                                            className="btn btn-primary transition-3d-hover"
                                            type="submit"
                                            disabled={formik.isSubmitting}>
                                            Update Profile
                                        </LaddaButton>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-3 mb-lg-5">
                    <div className="card-header">
                        <h5 className="card-title">Resume</h5>
                    </div>
                    <div className="card-body">
                        <div>
                            <div className='row'>
                                <div className={"col-md-3"}>
                                    <p className={'d-block font-size-1 mb-2'}>Your Resume & Cover letter <br/>
                                        <strong><small> PDF files only </small></strong></p>
                                </div>
                                <div className={"col-md-9"}>
                                    <div>
                                        <div className="js-form-message form-group required-field">
                                            <label className="input-label"> Resume Upload </label>
                                            <Upload {...uploads}>
                                                <Button icon={<FilePdfOutlined/>}>Click to Upload </Button>
                                            </Upload>
                                        </div>

                                        <hr style={{
                                            border: 0,
                                            height: '1px',
                                            background: '#eef1f6',
                                        }}/>

                                        <label className="input-label"> Cover letter </label>
                                        <Upload {...uploadCoverLetter}>
                                            <Button icon={<FilePdfOutlined/>}>Click to Upload </Button>
                                        </Upload>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-3 mb-lg-5">
                    {/*<div className="card-header">*/}
                    {/*    <h5 className="card-title">Privacy</h5>*/}
                    {/*</div>*/}

                    {/*<div className="card-body">*/}
                    {/*    <form data-select2-id="271">*/}
                    {/*        <div className="row form-group">*/}
                    {/*            <div className="col-sm-3">*/}
                    {/*                <span className="d-block font-size-1 mb-2">Who can see your profile photo? <i*/}
                    {/*                    className="far fa-question-circle" data-toggle="tooltip" data-placement="top"*/}
                    {/*                    title=""*/}
                    {/*                    data-original-title="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."></i></span>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-sm-9">*/}
                    {/*                <div className="select2-custom" data-select2-id="270">*/}
                    {/*                    <select className="js-custom-select select2-hidden-accessible"*/}
                    {/*                            data-select2-id="260" tabIndex="-1" aria-hidden="true">*/}
                    {/*                        <option value="privacy1"*/}
                    {/*                                data-option-template="<span class=&quot;media&quot;><i class=&quot;fas fa-globe-americas text-body mt-1 mr-2&quot;></i><span class=&quot;media-body&quot;><span class=&quot;d-block&quot;>Anyone</span><small class=&quot;select2-custom-hide&quot;>Visible to anyone who can view your content. Accessible by installed apps.</small></span></span>"*/}
                    {/*                                data-select2-id="262">Anyone*/}
                    {/*                        </option>*/}
                    {/*                        <option value="privacy2"*/}
                    {/*                                data-option-template="<span class=&quot;media&quot;><i class=&quot;fas fa-user-lock text-body mt-1 mr-2&quot;></i><span class=&quot;media-body&quot;><span class=&quot;d-block&quot;>Only you</span><small class=&quot;select2-custom-hide&quot;>Only visible to you.</small></span></span>"*/}
                    {/*                                data-select2-id="263">Only you*/}
                    {/*                        </option>*/}
                    {/*                    </select><span*/}
                    {/*                    className="select2 select2-container select2-container--default select2-container--below"*/}
                    {/*                    dir="ltr" data-select2-id="261"><span*/}
                    {/*                    className="selection"><span className="select2-selection custom-select"*/}
                    {/*                                                role="combobox" aria-haspopup="true"*/}
                    {/*                                                aria-expanded="false" tabIndex="0"*/}
                    {/*                                                aria-disabled="false"*/}
                    {/*                                                aria-labelledby="select2-xvlj-container"><span*/}
                    {/*                    className="select2-selection__rendered" id="select2-xvlj-container"*/}
                    {/*                    role="textbox" aria-readonly="true" title="Anyone"><span className="media"><i*/}
                    {/*                    className="fas fa-globe-americas text-body mt-1 mr-2"></i><span*/}
                    {/*                    className="media-body"><span className="d-block">Anyone</span><small*/}
                    {/*                    className="select2-custom-hide">Visible to anyone who can view your content. Accessible by installed apps.</small></span></span></span><span*/}
                    {/*                    className="select2-selection__arrow" role="presentation"><b*/}
                    {/*                    role="presentation"></b></span></span></span><span className="dropdown-wrapper"*/}
                    {/*                                                                       aria-hidden="true"></span></span>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </form>*/}
                    {/*</div>*/}

                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({talent, country}) => ({...talent, ...country})
export default connect(mapStateToProps, {getCountryLists, updateTalent, getTalentProfile})(Profile);
