import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import {FormFeedback} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import LinkComponent from "../../route/LinkComponent";
import * as Yup from "yup";
import {connect} from "react-redux";
import {resetPassword, verifyActivationCode} from "../../appRedux/actions/ResetPassword"
import {notification, Alert, Skeleton} from "antd";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {handleNotify} from "../../appRedux/response";
import {isEmpty} from "lodash"
import {redirectToParams} from "../../route/Redirect";

function ResetPassword(props) {
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [alert, setAlert] = useState(false);
    const {resetPassword, path} = props;

    useEffect(() => {
        if (props.activation_code) {
            setLoading(true)
            props.verifyActivationCode(props.activation_code).then(res => {
                setLoading(false)
            }).catch(e => {
                handleNotify(e.message, () => {
                    redirectToParams('/' + path.split('/')[1] + '/login')
                })
            })
        } else {
            redirectToParams('/' + path.split('/')[1] + '/login')
        }
    }, []);

    const is_talent = (path) => {
        return !isEmpty(path) && path.substring(0, 8) === '/talents';
    }

    const back_path = is_talent(path) ? "/talents/login" : "/client/login"

    const resetSchema = Yup.object().shape({
        passwd: Yup.string()
            .required('Password is Required')
            .matches(/^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'),
        con_password: Yup.string()
            .oneOf([Yup.ref('passwd'), null], "Passwords do not match")
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            passwd: '',
            con_password: '',
            activation_code: props.activation_code
        },
        validationSchema: resetSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            resetPassword(values).then(result => {
                notification['success']({
                    message: 'Password updated',
                    description: result.message,
                    duration: NOTIFICATION_DURATION,
                });
                redirectToParams('/' + path.split('/')[1] + '/login')
            }).catch(e => {
                handleNotify(e.message, () => {
                    setSubmitting(false);
                    setLaddaLoading(false);
                    setLaddaProgress(1);
                });
            })
        },
    });

    return (
        <>
            <div className="row no-gutters">
                <div
                    className="col-md-8 offset-md-2 space-top-3 space-lg-0">
                    <form className="js-validate" onSubmit={formik.handleSubmit}>
                        <div className="mb-5 mb-md-7">
                            <h1 className="h2">Reset Your Password</h1>
                            <p>Please provide your new password</p>

                            {!isEmpty(alert) && <Alert
                                message="Password has been reset"
                                description={alert}
                                type="success"
                            />}
                        </div>

                        {loading ? <>
                            <Skeleton active/>
                        </> : <>
                            <div className="js-form-message form-group">
                                <label className="input-label">New Password</label>
                                <input type="password"
                                       className="form-control"
                                       name="passwd"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.passwd}
                                       placeholder="********"
                                       aria-label="********"/>
                                {formik.touched.passwd && formik.errors.passwd &&
                                <FormFeedback>{formik.errors.passwd}</FormFeedback>}
                            </div>
                            <div className="js-form-message form-group">
                                <label className="input-label" tabIndex="0">
                                    Retype Password
                                </label>
                                <input type="password" className="form-control"
                                       name="con_password"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.con_password}
                                       placeholder="********"
                                       aria-label="********"/>
                                {formik.touched.con_password && formik.errors.con_password &&
                                <FormFeedback>{formik.errors.con_password}</FormFeedback>}
                            </div>
                            <div className="row align-items-center mb-5">

                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <LinkComponent to={`${back_path}`} className="font-size-1 font-weight-bold">
                                        <i className="fas fa-angle-left fa-sm mr-1"/> Back to sign in
                                    </LinkComponent>
                                </div>

                                <div className="col-sm-6 text-sm-right">
                                    <LaddaButton
                                        loading={laddaLoading}
                                        progress={laddaProgress}
                                        data-style={ZOOM_OUT}
                                        data-spinner-size={30}
                                        data-spinner-color="#fff"
                                        data-spinner-lines={12}
                                        className="btn btn-primary transition-3d-hover"
                                        type="submit"
                                        disabled={formik.isSubmitting}>
                                        Reset Password
                                    </LaddaButton>
                                </div>
                            </div>
                        </>}

                    </form>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {};
}

export default connect(mapStateToProps, {resetPassword, verifyActivationCode})(ResetPassword)
