import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {useFormik} from "formik";
import LinkComponent from "../../route/LinkComponent";
import {FormFeedback} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {talentSignUp} from "../../appRedux/actions/Talents";
import {connect, useDispatch} from "react-redux";
import {notification, Select} from "antd";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {redirectToOverview, redirectToProfile, redirectToResume} from "../../route/Redirect";
import {getCountryLists} from "../../appRedux/actions/Country";
import {handleNotify} from "../../appRedux/response";
import {isEmpty} from "lodash";
import ArrowLeft from "../../_assets/media/svg/icons/Navigation/Arrow-left.svg"
import {getSessionWithKey} from "../../appRedux/store/cookies"

const {Option} = Select

function SignUp(props) {
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [currentTab, setCurrentTab] = useState(1);
    const referral_code = getSessionWithKey('ref_code');

    const handlePreviousClicked = () => {
        setCurrentTab(1)
    }
    const handleNextClicked = () => {
        setCurrentTab(2)
    }

    const submitDisabled = () => {
        return (
            !(isEmpty(formik.errors.skills) && isEmpty(formik.errors.title) &&
                isEmpty(formik.errors.consent))
            ||
            formik.isSubmitting
        )
    }

    useEffect(() => {
        props.getCountryLists();
    }, []);

    const {skill_loading, skill_list, talent_title_loading, talent_titles} = props

    const regSchema = Yup.object().shape({
        full_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required('Full name is required'),
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .email('Invalid email address').required('Email address is required'),
        password: Yup.string()
            .required('Password is required')
            .matches(/^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
            ),
        confirm_password: Yup.string()
            .required('Confirm Password is required')
            .when('password', {
                is: val => (!!(val && val.length > 0)),
                then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Both password need to be the same',
                ),
            }),
        consent: Yup.boolean()
            .required('The Terms and Conditions must be accepted.')
            .oneOf([true], 'Please accept our Terms and Conditions'),
        skills: Yup.array().min(3).required('Skills is required'),
        title: Yup.string().required('Profession is required'),
    });

    const formik = useFormik({
        initialValues: {
            full_name: '',
            email: '',
            password: '',
            confirm_password: '',
            skills: '',
            title: '',
            referral: referral_code,
            consent: false
        },
        validationSchema: regSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            const {full_name, email, password: passwd, confirm_password: con_passwd, skills, title} = values;
            props.talentSignUp({
                full_name,
                email,
                passwd,
                con_passwd,
                skills,
                title,
                referral: referral_code
            }).then(res => {
                handleIn(res)
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Talent account successful',
            description: 'Welcome to possibilities',
            duration: NOTIFICATION_DURATION,
        });
        setLaddaProgress(0.9);
        redirectToResume();
    }

    const optionTitles = [];
    const optionSkills = [];
    for (let skill in skill_list) {
        optionSkills.push(<Option key={skill} value={skill}>{skill_list[skill]}</Option>)
    }
    for (let title in talent_titles) {
        optionTitles.push(<Option key={title} value={title}>{talent_titles[title]}</Option>)
    }

    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 space-top-3 space-lg-0">

                    <form className="js-validate" onSubmit={formik.handleSubmit}>
                        {/*<div className="text-center">*/}
                        {/*    <ul className="nav nav-segment nav-pills scrollbar-horizontal mb-7" role="tablist">*/}
                        {/*        <li className="nav-item">*/}
                        {/*            <a type='button' className={`nav-link ${currentTab === 1 && 'active'}`}*/}
                        {/*               id="pills-two-code-features-example1-tab"*/}
                        {/*               data-toggle="pill" role="tab"*/}
                        {/*               aria-controls="pills-two-code-features-example1"*/}
                        {/*               aria-selected="false">Account</a>*/}
                        {/*        </li>*/}
                        {/*        <li className="nav-item">*/}
                        {/*            <a className={`nav-link ${currentTab === 2 && 'active'}`}*/}
                        {/*               id="pills-three-code-features-example1-tab"*/}
                        {/*               data-toggle="pill" role="tab"*/}
                        {/*               aria-controls="pills-three-code-features-example1"*/}
                        {/*               aria-selected="false"> Skills & Profession </a>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        {/*<div className="mb-5 mb-md-7">
                            <h1 className="h2">Talent Account</h1>
                            <p>Fill out the form to get started as a talent</p>
                            </div>*/}

                        <div className="pb-5 pb-lg-15">
                            <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"> Create Talent
                                Account</h3>
                            <div className="text-muted font-size-h4">Already have an
                                Account
                                ? {" "}
                                <LinkComponent to={"/talents/login"}
                                               className="text-primary "> Sign
                                    In </LinkComponent>
                            </div>
                        </div>
                        {currentTab === 1 && <>

                            <div className="js-form-message form-group required-field">
                                <label className="input-label" htmlFor="signinSrEmail"> Full Name </label>
                                <input type="text"
                                       className="form-control"
                                       name="full_name"
                                       placeholder="Full Name"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.full_name}
                                       aria-label="Full Name"/>
                                {formik.touched.full_name && formik.errors.full_name &&
                                <FormFeedback>{formik.errors.full_name}</FormFeedback>}
                            </div>

                            <div className="js-form-message form-group required-field">
                                <label className="input-label" htmlFor="signinSrEmail">Email address</label>
                                <input type="email"
                                       className="form-control"
                                       name="email"
                                       placeholder="Email address"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.email}
                                       aria-label="Email address"/>
                                {formik.touched.email && formik.errors.email &&
                                <FormFeedback>{formik.errors.email}</FormFeedback>}
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="js-form-message form-group required-field">
                                        <label className="input-label" htmlFor="signinSrPassword">Password</label>
                                        <input type="password" className="form-control" name="password"
                                               id="signinSrPassword"
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               value={formik.values.password}
                                               placeholder="********"
                                               aria-label="********"/>
                                        {formik.touched.password && formik.errors.password &&
                                        <FormFeedback>{formik.errors.password}</FormFeedback>}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="js-form-message form-group required-field">
                                        <label className="input-label" htmlFor="signinSrConfirmPassword">Confirm
                                            password</label>
                                        <input type="password" className="form-control" name="confirm_password"
                                               id="signinSrConfirmPassword"
                                               placeholder="********" aria-label="********"
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               value={formik.values.confirm_password}/>
                                        {formik.touched.confirm_password && formik.errors.confirm_password &&
                                        <FormFeedback>{formik.errors.confirm_password}</FormFeedback>}
                                    </div>
                                </div>
                            </div>
                        </>}

                        <hr/>
                        {/*{currentTab === 2 && <>*/}
                        <div>
                            {/*<div onClick={handlePreviousClicked} style={{cursor: 'pointer'}}>*/}
                            {/*    <p><img src={ArrowLeft}/> Back</p>*/}
                            {/*</div>*/}
                            <div className="js-form-message form-group required-field">
                                <label className="input-label"> Top 3 Skills / Stacks </label>

                                <Select
                                    style={{width: '100%'}}
                                    className={'select select_skill'}
                                    size={'large'}
                                    loading={skill_loading}
                                    name="skills"
                                    placeholder="Select Top 3 Skills"
                                    mode="multiple"
                                    onBlur={formik.handleBlur}
                                    onChange={event => {
                                        formik.values.skills = event;
                                        formik.handleChange.skills = event;
                                    }}>
                                    {optionSkills}
                                </Select>
                                <p className="form-text"><small>Top 3 stacks in order of proficiency. </small></p>
                                {formik.touched.skills && formik.errors.skills &&
                                <FormFeedback>{formik.errors.skills}</FormFeedback>}
                            </div>
                            <div className="js-form-message form-group required-field">
                                <label className="input-label"> Title / Profession </label>

                                <Select
                                    style={{width: '100%'}}
                                    className={'select'}
                                    size={'large'}
                                    loading={talent_title_loading}
                                    name="title"
                                    placeholder="Select Title / Profession"
                                    onBlur={formik.handleBlur}
                                    onChange={event => {
                                        formik.values.title = event;
                                        formik.handleChange.title = event;
                                    }}>
                                    {optionTitles}
                                </Select>
                                {formik.touched.title && formik.errors.title &&
                                <FormFeedback>{formik.errors.title}</FormFeedback>}
                            </div>
                        </div>
                        <hr/>
                        <div className="js-form-message form-group">
                            <label className="input-label"> Referral (optional) </label>
                            <input type="text"
                                   className="form-control"
                                   name="referral"
                                   placeholder="Referral Code"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.referral}
                                   aria-label="Referral Code"/>
                        </div>
                        {/*referral*/}
                        <div className="js-form-message mb-0">
                            <div
                                className="custom-control custom-checkbox d-flex align-items-center text-muted">
                                <input type="checkbox" className="custom-control-input" id="termsCheckbox"
                                       name="consent"
                                       checked={formik.values.consent}
                                       onBlur={formik.handleBlur}
                                       onChange={formik.handleChange}/>
                                <label className="custom-control-label" htmlFor="termsCheckbox">
                                    <small>
                                        I have read and agree to Borderless <LinkComponent to={"/terms"}
                                                                                           className="hkbold">Terms
                                        and Conditions</LinkComponent>
                                    </small>
                                </label>
                            </div>
                            {formik.touched.consent && formik.errors.consent &&
                            <FormFeedback>{formik.errors.consent}</FormFeedback>}
                        </div>
                        {/*</>}*/}


                        {/*<div className="row">*/}
                        {/*    <div className="col-sm-6 mb-3 mb-sm-0">*/}
                        {/*        <span className="font-size-1 text-muted">Already have an account?</span>*/}
                        {/*        <LinkComponent to={'/talents/login'}*/}
                        {/*                       className="font-size-1 font-weight-bold">Login</LinkComponent>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<br/>*/}
                        <br/>

                        {/*<div className="d-flex justify-content-between">*/}
                            {/*<div className="mr-2">*/}
                            {/*    {currentTab !== 1 &&*/}
                            {/*    <button type="button"*/}
                            {/*            onClick={handlePreviousClicked}*/}
                            {/*            className="btn btn-soft-primary transition-3d-hover">*/}
                            {/*        Previous*/}
                            {/*    </button>}*/}
                            {/*</div>*/}
                            {/*{currentTab === 1 && <div>*/}
                            {/*    <button*/}
                            {/*        type="button"*/}
                            {/*        disabled={!((isEmpty(formik.errors.full_name)*/}
                            {/*            && isEmpty(formik.errors.email)*/}
                            {/*            && isEmpty(formik.errors.password)*/}
                            {/*            && isEmpty(formik.errors.confirm_password)*/}
                            {/*            && !isEmpty(formik.touched)))}*/}
                            {/*        onClick={handleNextClicked}*/}
                            {/*        className="btn btn-primary transition-3d-hover">*/}
                            {/*        Next*/}
                            {/*    </button>*/}
                            {/*</div>}*/}
                            {/*{currentTab === 2 && <div>*/}
                            <div className="col-sm-12 text-sm-right">
                                <LaddaButton
                                    loading={laddaLoading}
                                    progress={laddaProgress}
                                    data-style={ZOOM_OUT}
                                    data-spinner-size={30}
                                    data-spinner-color="#fff"
                                    data-spinner-lines={12}
                                    className="btn btn-primary transition-3d-hover"
                                    type="submit"
                                    disabled={submitDisabled()}>
                                    Get Started
                                </LaddaButton>
                            </div>
                            {/*</div>}*/}
                        {/*</div>*/}

                    </form>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({talent, skill}) => {
    return {...talent, ...skill}
}

export default connect(mapStateToProps, {talentSignUp, getCountryLists})(SignUp);
