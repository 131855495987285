import React from 'react';
import LinkComponent from "../../route/LinkComponent";
import {useSelector} from "react-redux";
import {Spin} from "antd";
import {isEmpty} from "lodash"

function ClientHeader(props) {

    const {client_profile: client} = useSelector(state => state.client);

    return (
        <div>
            <div className="page-header">
                <div className="d-flex align-items-lg-center">
                    {/*<div className="mr-4">*/}
                    {/*    <img className="avatar avatar-xl avatar-circle" src="../assets/img/100x100/img20.jpg"*/}
                    {/*         alt="Image Description" />*/}
                    {/*</div>*/}

                    <div className="media-body">
                        <div className={'d-flex align-items-lg-center'}>
                            {isEmpty(client) ? <Spin/> :
                                <>
                                    <div className="col-lg mb-3 mb-lg-0">
                                        <h1 className="h2 mb-1">
                                        <small>{client.name}</small></h1>

                                        <ul className="list-inline list-separator font-size-1">
                                            {/*<li className="list-inline-item">*/}
                                                {/*<i className="fas fa-map-marker-alt text-primary mr-1"/> {client.location}, {client.country.name}*/}
                                                {/*{client.country.name} {!isEmpty(client.location) && `, ${client.location}`}*/}
                                            {/*</li>*/}
                                            <li className="list-inline-item">{client.email}</li>
                                            {/*<li className="list-inline-item">{client.phone_number}</li>*/}
                                        </ul>
                                    </div>
                                    <div className="col-lg-auto align-self-lg-end text-lg-right">
                                        <LinkComponent to={'/logout'}
                                                       className="btn btn-sm btn-ghost-secondary mb-1 mr-0">Log out
                                        </LinkComponent>
                                    </div>
                                </>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientHeader;
