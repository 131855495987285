import React, {useState} from 'react';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {useFormik} from "formik";
import {Input, Form, notification} from "antd";
import * as Yup from "yup";
import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";
import EyeTwoTone from "@ant-design/icons/lib/icons/EyeTwoTone";
import {connect} from "react-redux";
import {resetPassword} from "../../appRedux/actions/Auth";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {handleNotify} from "../../appRedux/response";

function ResetPassword(props) {

    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);

    const resetPasswordSchema = Yup.object().shape({
        currentPassword: Yup.string().required("current password is required"),
        newPassword: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
        newPasswordConfirm: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: "", newPassword: ""
        },
        validationSchema: resetPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            values['type'] = props.type;
            props.resetPassword(values).then(res => {
                notification['success']({
                    message: 'Password reset',
                    description: 'Password update successfully',
                    duration: NOTIFICATION_DURATION,
                });
                resetForm(true);
            }).catch(e => {
                handleNotify(e.message, () => {
                    console.log('got here')
                })
            }).finally(() => {
                setLaddaLoading(false);
                setLaddaProgress(1.0);
                setSubmitting(false);
            })
        },
    });

    return (
        <>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <h5 className="card-title"> Update Password</h5>
                </div>

                <div className="card-body">
                    <form className="js-validate" onSubmit={formik.handleSubmit}>

                        <div className='row'>
                            <div className={"col-md-5"}>
                                <p className="card-text font-size-1">Ensure that these requirements are met:</p>
                                <ul className="font-size-1">
                                    <li>Minimum 8 characters long - the more, the better</li>
                                    <li>At least one lowercase character</li>
                                    <li>At least one uppercase character</li>
                                    <li>At least one number, symbol, or whitespace character</li>
                                </ul>
                            </div>
                            <div className={"col-md-7"}>
                                <div className="form-group">
                                    <div className="js-form-message form-group required-field">
                                        <label className="input-label"> Current password </label>
                                        <Form.Item
                                            label={false}
                                            colon={false}
                                            style={{width: '100%', marginRight: 0}}
                                            validateStatus={
                                                formik.errors.currentPassword &&
                                                formik.touched.currentPassword
                                                    ? 'error'
                                                    : null
                                            }
                                            help={
                                                formik.errors.currentPassword &&
                                                formik.touched.currentPassword
                                                    ? formik.errors.currentPassword
                                                    : null
                                            }>
                                            <Input.Password
                                                className="fd-input"
                                                size="large"
                                                type="password"
                                                name="currentPassword"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.currentPassword}
                                                iconRender={visible =>
                                                    visible ? (
                                                        <EyeTwoTone/>
                                                    ) : (
                                                        <EyeInvisibleOutlined/>
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="js-form-message form-group required-field">
                                        <label htmlFor="newPassword" className="input-label">New
                                            password</label>
                                        <div>
                                            <Form.Item
                                                label={false}
                                                colon={false}
                                                style={{width: '100%', marginRight: 0}}
                                                validateStatus={
                                                    formik.errors.newPassword &&
                                                    formik.touched.newPassword
                                                        ? 'error'
                                                        : null
                                                }
                                                help={
                                                    formik.errors.newPassword &&
                                                    formik.touched.newPassword
                                                        ? formik.errors.newPassword
                                                        : null
                                                }>
                                                <Input.Password
                                                    className="fd-input"
                                                    size="large"
                                                    type="password"
                                                    name="newPassword"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.newPassword}
                                                    iconRender={visible =>
                                                        visible ? (
                                                            <EyeTwoTone/>
                                                        ) : (
                                                            <EyeInvisibleOutlined/>
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="js-form-message form-group required-field">
                                        <label htmlFor="confirmNewPasswordLabel" className="input-label">Confirm
                                            new password</label>
                                        <div>
                                            <Form.Item
                                                label={false}
                                                colon={false}
                                                style={{width: '100%', marginRight: 0}}
                                                validateStatus={
                                                    formik.errors.newPasswordConfirm &&
                                                    formik.touched.newPasswordConfirm
                                                        ? 'error'
                                                        : null
                                                }
                                                help={
                                                    formik.errors.newPasswordConfirm &&
                                                    formik.touched.newPasswordConfirm
                                                        ? formik.errors.newPasswordConfirm
                                                        : null
                                                }>
                                                <Input.Password
                                                    className="fd-input"
                                                    size="large"
                                                    type="password"
                                                    name="newPasswordConfirm"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.newPasswordConfirm}
                                                    iconRender={visible =>
                                                        visible ? (
                                                            <EyeTwoTone/>
                                                        ) : (
                                                            <EyeInvisibleOutlined/>
                                                        )
                                                    }
                                                />
                                            </Form.Item>

                                            {/*<h5>Password requirements:</h5>*/}

                                            {/*<p className="card-text font-size-1">Ensure that these requirements are met:</p>*/}
                                            {/*<ul className="font-size-1">*/}
                                            {/*    <li>Minimum 8 characters long - the more, the better</li>*/}
                                            {/*    <li>At least one lowercase character</li>*/}
                                            {/*    <li>At least one uppercase character</li>*/}
                                            {/*    <li>At least one number, symbol, or whitespace character</li>*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <span className="mx-2"/>
                                    <LaddaButton
                                        loading={laddaLoading}
                                        progress={laddaProgress}
                                        data-style={ZOOM_OUT}
                                        data-spinner-size={30}
                                        data-spinner-color="#fff"
                                        data-spinner-lines={12}
                                        className="btn btn-primary transition-3d-hover"
                                        type="submit"
                                        disabled={formik.isSubmitting}>
                                        Update Password
                                    </LaddaButton>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {resetPassword})(ResetPassword);
