import React, {useEffect, useState} from 'react';
import {getAppliedJobs, getJobLists} from "../../appRedux/actions/Jobs";
import {connect, useSelector} from "react-redux";
import {LIMIT} from "../../appRedux/constants";
import {isEmpty} from "lodash"
import LinkComponent from "../../route/LinkComponent";
import {Pagination, Space, Table, Tag} from "antd";
import {Loading} from "../../components/Loading";
import moment from "moment";

function ProfileAppliedJobs(props) {

    const [paginationSortCurrent, setPaginationSortCurrent] = useState(1);
    const {talent_profile: talent} = useSelector(state => state.talent);

    useEffect(() => {
        props.getAppliedJobs({talent_id: talent.id, limit: LIMIT});
    }, []);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <div>
                    {console.log(record)}
                    <LinkComponent to={`/client/job-details/${record.id}`}>
                        <p className={'h5 d-block'}>{text}</p>
                    </LinkComponent>
                    <p className={'text-body font-size-1 mb-1'}>{record.salary}</p>
                </div>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (location, record) => (
                <div>
                    <p className={'text-body font-size-1 mb-1'}>{record.location.name}, <br/> {record.location.country.name}
                    </p>
                </div>
            ),
        },
        {
            title: 'Skills',
            key: 'skills',
            dataIndex: 'skills',
            render: (skills, record) => (
                <>
                    {skills.map(skill => {
                        // let color = tag.length > 5 ? 'geekblue' : 'green';
                        // if (tag === 'loser') {
                        //     color = 'volcano';
                        // }
                        return (
                            <Tag className={'mb-1'} color={"blue"} key={skill.id}>
                                {skill.title.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Applied',
            dataIndex: 'applied_created',
            key: 'applied_created',
            render: (created, record) => (
                <div>
                    <p className={'text-body font-size-1 mb-1'}>{moment(created).format("YYYY-MM-DD, h:mm:ss a")}</p>
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <LinkComponent
                        to={`/profile/job-details/${record.id}`}
                        className={"btn btn-xs btn-ghost-secondary mb-1 mr-1"}>
                        Details
                    </LinkComponent>
                </Space>
            ),
        },
    ];

    const {jobs_applied: jobs, jobs_applied_loading: jobs_loading} = props;

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = {...pagination};
        console.info(pager)
        pager.current = pagination.current;
        setPaginationSortCurrent(pagination.current);
        pagination.current -= 0;
        paginateSort(pagination);
    };

    const paginateSort = (pag) => {
        props.getAppliedJobs({
            talent_id: talent.id,
            limit: pag.pageSize,
            page: LIMIT * pag.current
        });
    };

    const handlePagination = {
        // position: ["topRight", "none"],
        // showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total}`,
        total: !isEmpty(jobs.paging) ? jobs.paging.count : 0,
        current: paginationSortCurrent,
        pageSize: LIMIT
    };

    const tableLoading = {
        spinning: jobs_loading,
        indicator: <Loading/>,
    }

    return (
        <>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <h5 className="card-header-title"> Applied Jobs </h5>

                    <div>
                        <LinkComponent to={"/jobs"} className={"btn btn-primary btn-xs transition-3d-hover"}>
                            Job Listings
                        </LinkComponent>
                    </div>
                </div>

                {/*<div className="card mb-3 mb-lg-5">*/}
                {/*<div className="card-header">*/}
                {/*    <h5 className="card-title"> Listed Jobs </h5>*/}
                {/*</div>*/}
                <div className="card-body" style={{padding: "0"}}>
                    <Table
                        rowKey="id"
                        // loading={true}
                        loading={tableLoading}
                        columns={columns}
                        pagination={handlePagination}
                        onChange={handleTableChange}
                        dataSource={jobs.content}/>
                </div>
                {/*</div>*/}
            </div>
        </>
    );
}

const mapStateToProps = ({client, job}) => ({...job, ...client})

export default connect(mapStateToProps, {getAppliedJobs})(ProfileAppliedJobs);
