import React from 'react';
import knowledgebase from "../../_assets/media/svg/illustrations/knowledgebase-community-1.svg"
import LinkComponent from "../../route/LinkComponent";

function Faqs(props) {

    const faqs = [
        {
            h: 'Client'
        },
        {
            q: "What kind of talents can I expect from Borderless?",
            a: "You can expect a full range of software engineers across multiple stacks. These include Frontend, Backend, Full-stack, Mobile, Quality Assurance, DevOps, User Experience and Data Engineers."
        },
        {
            q: "Can I hire on part-time or freelance basis from Borderless?",
            a: "We don't provide part-time or freelance talents. Talents matched on our platform are dedicated, full-timers."
        },
        {
            q: "Do you provide entry-level talents?",
            a: "No, we don't. We only provide mid to senior level software engineers. Our talents typically have a minimum of 3 years relevant experience"
        },
        {
            q: "Upon handover, can we have our software engineers relocate to our HQ/regional base?",
            a: "We work closely with immigration specialists that can assist with this. Please contact us for details."
        },
        {
            q: "I need a Chief Technical Officers (CTO); is that a service you offer?",
            a: "Yes. We work with founders in facilitating the matching and onboarding of CTOs. Our process allows (particularly, non-technical) founders to de-risk the process in a safe space."
        },
        {
            q: "What is the hiring process?",
            a: "We start out our hiring process by conducting technical, psychometric, and aptitude assessments; then we run interview. Afterwards, we match to available opportunities based on performance and fit.."
        },
        {
            q: "What happens if I change my mind about working with a new hire?",
            a: `Should you decide to discontinue working with any talent, we guarantee to make full reimbursement of fees and/or replace the candidate at no additional cost to you within the first 90 days of starting on the job.`
        },
        {
            h: 'Talents'
        },
        {
            q: "How do I get hired?",
            a: () => <> <span>We’re glad to have you onboard. Please click on the link</span> <a href="http://borderlesshr.com/talents/signup">http://borderlesshr.com/talents/signup </a> <span> to sign up. </span> </>
        },
        {
            q: "I know a candidate that might find this beneficial. What do I do?",
            a: () => <> <span>Please send the profile/resume of the candidate(s) to </span> <a href="malito:hi@borderlesshr.com">hi@borderlesshr.com</a> <span> and we shall reach out to them on your behalf. Should any of them get hired, you get $100 as a token of our appreciation. </span> </>
        },
        {
            q: "Do you have anything for early stage startups?",
            a: `We do. Contact us for details on our Startup Stock Plan and to see if you qualify.`
        },
        {
            h: 'General'
        },
        {
            q: "How do I ask more questions beyond those addressed here?",
            a: () => <> <span>Chat with us, or send an email to</span> <a href="malito:hi@borderlesshr.com">hi@borderlesshr.com</a> </>
        },
    ]

    return (
        <div>
            <main id="content" role="main">

                <div className="bg-light">
                    <div className="container">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-6 space-2 space-top-lg-3 space-bottom-lg-1">
                                <div className="mb-3">
                                    <h1 style={{fontSize: '48px'}}> FAQs </h1>
                                </div>

                                <p className="font-size-1 text-dark">
                                    Here you can get basic needed information
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="border-bottom space-2 space-lg-3">
                        <div className="row">

                            <div class="container">
                                <div class="mx-lg-auto">
                                    <div class="row">
                                        {faqs.map((faq, index) =>{
                                            if(faq.h) return (
                                                <div className="col-md-12 col-12 col-lg-12 mb-3">
                                                    <h3 style={{textAlign: 'center'}}>{faq.h}</h3>
                                                </div>
                                            )
                                            return (
                                                <div key={index} className="col-md-6 mb-3 mb-md-5 ">
                                                    <div className="card card-frame ">
                                                        <div className="card-body">
                                                            <div className="pr-md-4 ">
                                                                <h4>{faq.q}</h4>
                                                                <p>{typeof faq.a === 'function' ? faq.a() : faq.a}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> )
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Faqs;
