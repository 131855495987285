import React from 'react';
import {toAbsoluteUrl} from "../../_helpers";
import LinkComponent from "../../route/LinkComponent";
import {Button} from "antd";

function Missing404() {
    return (
        <div className="container error-container">
            <div style={{ marginTop: 140, display: "block" }}>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-6  text-center">
                        <p>
                            The page you are looking for might have been removed had its
                            name changed or is temporarily unavailable.
                        </p>
                        <div>
                            <LinkComponent to={"/"}>
                                <Button
                                    className="fd-button primary"
                                    type="link"
                                    shape="round"
                                    size={"large"}
                                >
                                    Back to home
                                </Button>
                            </LinkComponent>
                            {/*<h2>Missing404 {!_.isEmpty(props.componentMissing) && props.componentMissing === true && 'Added Component Missing'}</h2>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Missing404);
