import {
    SKILL_FEATURE_START,
    SKILL_FEATURE_END,
    JOB_LIST_START,
    JOB_LIST_END,
    JOB_DETAIL,
    JOB_DETAIL_END,
    JOB_DETAIL_START,
    JOB_APPLIED_END,
    JOB_APPLIED_START,
    JOB_APPLIED_LOADING_END,
    JOB_APPLIED_LOADING_START, CLIENT_SAVE_POSTING_START, CLIENT_SAVE_POSTING_END
} from '../constants';

const INIT_STATE = {
    jobs_loading: false,
    jobs_posting_loading: false,
    job_detail_loading: false,
    jobs_apply_loading: false,
    jobs_applied_loading: false,
    skills_feature_loading: false,
    skills_featured: [],
    job_detail: {},
    job_applied: [],
    jobs_applied: [],
    jobs: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLIENT_SAVE_POSTING_START: {
            return {...state, jobs_posting_loading: true};
        }
        case CLIENT_SAVE_POSTING_END: {
            return {...state, jobs_posting_loading: false};
        }
        case JOB_LIST_START: {
            return {...state, jobs_loading: true};
        }
        case JOB_DETAIL_START: {
            return {...state, job_detail_loading: true};
        }
        case JOB_APPLIED_START: {
            return {...state, jobs_apply_loading: true};
        }
        case JOB_APPLIED_LOADING_START: {
            return {...state, jobs_applied_loading: true};
        }
        case SKILL_FEATURE_START: {
            return {...state, skills_feature_loading: true};
        }
        case SKILL_FEATURE_END: {
            return {...state, skills_feature_loading: false, skills_featured: action.payload};
        }
        case JOB_LIST_END: {
            return {...state, jobs_loading: false, jobs: action.payload};
        }
        case JOB_DETAIL_END: {
            return {...state, job_detail_loading: false, job_detail: action.payload};
        }
        case JOB_APPLIED_END: {
            return {...state, jobs_apply_loading: false, job_applied: action.payload};
        }
        case JOB_APPLIED_LOADING_END: {
            return {...state, jobs_applied_loading: false, jobs_applied: action.payload};
        }
        default:
            return state;
    }
};
