import React, {useEffect} from 'react';
import {Button, Skeleton} from "antd";
import {
    getProfileSkillVetting,
    getTalentSKills,
    updateTalent,
    updateTalentSkills
} from "../../appRedux/actions/Talents";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuidv4";
import {Loading} from "../../components/Loading";

function TalentGetVetted(props) {

    const dispatch = useDispatch();
    const {talent_vetting, talent_vetting_skills, talent_vetting_loading} = useSelector(state => state.talent)

    useEffect(() => {
        dispatch(getProfileSkillVetting())
    }, []);

    const skillsVetting = talent_vetting.map(item =>
        <div className={'col-md-6 mb-3 mb-sm-5'}>
            <div className="card card-bordered card-hover-shadow h-100">
                <a href={item.codebase?.code} className="card-body">
                    <div className="media align-items-center">
                        <div className="media-body">
                            <small className="skill_feature"> Assessment </small>
                            <h3 className="text-hover-primary mb-0"> {item.codebase?.title} </h3>
                            <small className="text-body">{item.codebase?.src}</small>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );

    return (
        <>
            <div className={'card'}>
                <div className="card-header border-0 bg-light text-center px-4">
                    <h5 className="card-header-title"> Get Vetted
                    </h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <p className="d-block font-size-1 mb-2">Vetting is based on the skills you've previously
                                selected</p>
                        </div>
                        <div className="col-md-9">
                            <h4 class={'d-inline-block text-body small font-weight-bold mb-1'}>Please update your skills to be able to get test in
                                different skills</h4>

                            <br/>
                            <div class="position-relative">
                                {talent_vetting_loading ? <>
                                    <Skeleton active/>
                                    <Skeleton active/>
                                </> : <>
                                    <div className={'skill_holder'}>
                                        {talent_vetting_skills.map(skill => <small key={uuid}
                                                                                   className="btn btn-xs btn-ghost-secondary">{skill.skill.title}</small>)}
                                    </div>
                                    <hr/>
                                    <div className={'row'}>
                                        {skillsVetting}
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TalentGetVetted;
