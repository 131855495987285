import React from 'react';
import knowledgebase from "../../_assets/media/svg/illustrations/knowledgebase-community-1.svg"
import faq from '../../_assets/media/svg/icons/icon-24.svg'
import help from '../../_assets/media/svg/icons/icon-64.svg'
import LinkComponent from "../../route/LinkComponent";

function HelpDesk(props) {

    return (
        <div>
            <main id="content" role="main">

                <div className="bg-light">
                    <div className="container">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-6 space-2 space-top-lg-5 space-bottom-lg-3">
                                <div className="mb-3">
                                    <h1 style={{fontSize: '48px'}}> How can we help?</h1>
                                </div>

                                <p className="text-body text-dark">
                                    Here we get to attend to your issue and hope to make it better
                                </p>
                            </div>

                            <div className="col-lg-6 align-self-lg-end d-none d-lg-inline-block">
                                <figure className="mb-n7 ml-lg-4">
                                    <img className="img-fluid"
                                         src={knowledgebase}
                                         alt="Image Description"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="border-bottom space-2 space-lg-3">
                        <div className="row">
                            <div className="col-lg-6 mb-3 mb-lg-5">
                                <LinkComponent className="card card-frame h-100" to={'/help/faqs'}>
                                    <div className="card-body">
                                        <div className="media d-block d-sm-flex">
                                            <figure className="w-100 max-w-8rem mb-2 mb-sm-0 mr-sm-4">
                                                <img className="img-fluid" src={faq}
                                                     alt="SVG"/>
                                            </figure>
                                            <div className="media-body">
                                                <h2 className="h3">FAQs</h2>
                                                <p className="font-size-1 text-body">Here you can get basic needed information</p>
                                            </div>
                                        </div>
                                    </div>
                                </LinkComponent>
                            </div>

                            <div className="col-lg-6 mb-3 mb-lg-5">
                                <span className="card card-frame h-100">
                                    <div className="card-body">
                                        <div className="media d-block d-sm-flex">
                                            <figure className="w-100 max-w-8rem mb-2 mb-sm-0 mr-sm-4">
                                                <img className="img-fluid" src={help}
                                                     alt="SVG"/>
                                            </figure>
                                            <div className="media-body">
                                                <h3> Get help from support</h3>
                                                <p className="font-size-1 text-body"> Reach out to us and someone from the team will get back ASAP </p>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default HelpDesk;
