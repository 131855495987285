import React from 'react';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_helpers";
import {Rate} from "antd";

function Talents(props) {
    return (
        <>
            <div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="card card-custom bg-light-success card-shadowless gutter-b">
                            <div className="card-body my-3">
                                <a href="#"
                                   className="card-title font-weight-bolder text-success text-hover-state-dark font-size-h6 mb-4 d-block">Total Talent</a>
                                <div className="font-weight-bold text-muted font-size-sm">
                                    <span className="text-dark-75 font-size-h2 font-weight-bolder mr-2">67%</span>Average
                                </div>
                                <div className="progress progress-xs mt-7 bg-success-o-60">
                                    <div className="progress-bar bg-success" role="progressbar" style={{width: "67%"}}
                                         aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card card-custom bg-light-success card-shadowless gutter-b">
                            <div className="card-body my-3">
                                <a href="#"
                                   className="card-title font-weight-bolder text-success text-hover-state-dark font-size-h6 mb-4 d-block">Total Employee</a>
                                <div className="font-weight-bold text-muted font-size-sm">
                                    <span className="text-dark-75 font-size-h2 font-weight-bolder mr-2">67%</span>Average
                                </div>
                                <div className="progress progress-xs mt-7 bg-success-o-60">
                                    <div className="progress-bar bg-success" role="progressbar" style={{width: "67%"}}
                                         aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`card card-custom card-stretch gutter-b`}>
                    {/* begin::Header */}
                    <div className="card-header border-0 py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                              Talents Overview
                            </span>
                            <span className="text-muted mt-3 font-weight-bold font-size-sm">
                              Talents / Employees
                            </span>
                        </h3>
                        <div className="card-toolbar">
                            <a href="#"
                               className="btn btn-primary font-weight-bolder font-size-sm">
                                New Employees
                            </a>
                        </div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className="card-body pt-0 pb-3">
                        <div className="tab-content">
                            {/* begin::Table */}
                            <div className="table-responsive">
                                <table
                                    className="table table-head-custom table-vertical-center table-head-bg table-borderless">
                                    <thead>
                                    <tr className="text-left">
                                        <th style={{minWidth: "250px"}} className="pl-7">
                                            <span className="text-dark-75"> Talents / Employees </span>
                                        </th>
                                        <th style={{minWidth: "120px"}}>earnings</th>
                                        <th style={{minWidth: "100px"}}>comission</th>
                                        <th style={{minWidth: "100px"}}>company</th>
                                        <th style={{minWidth: "100px"}}>rating</th>
                                        <th style={{minWidth: "100px"}}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="pl-0 py-8">
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                    "/media/svg/avatars/boy.svg"
                                )}
                            />
                          </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                                    >
                                                        Brad Simmons
                                                    </a>
                                                    <span className="text-muted font-weight-bold d-block">
                            HTML, JS, ReactJS
                          </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $8,000,000
                      </span>
                                            <span className="text-muted font-weight-bold">
                        In Proccess
                      </span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $520
                      </span>
                                            <span className="text-muted font-weight-bold">Paid</span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Intertico
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Web, UI/UX Design
                      </span>
                                        </td>
                                        <td>
                                            <Rate allowHalf defaultValue={2.5} />
                                            <span className="text-muted font-weight-bold d-block">
                        Best Rated
                      </span>
                                        </td>
                                        <td className="pr-0 text-right">
                                            <a
                                                href="#"
                                                className="btn btn-light-success font-weight-bolder font-size-sm"
                                            >
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pl-0 py-0">
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                    "/media/svg/avatars/boy.svg"
                                )}
                            />
                          </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                                    >
                                                        Jessie Clarcson
                                                    </a>
                                                    <span className="text-muted font-weight-bold d-block">
                            C#, ASP.NET, MS SQL
                          </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $23,000,000
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Pending
                      </span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $1,600
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Rejected
                      </span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Agoda
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Houses & Hotels
                      </span>
                                        </td>
                                        <td>
                                            <Rate allowHalf defaultValue={2.5} />
                                            <span className="text-muted font-weight-bold d-block">
                        Above Average
                      </span>
                                        </td>
                                        <td className="pr-0 text-right">
                                            <a
                                                href="#"
                                                className="btn btn-light-success font-weight-bolder font-size-sm"
                                            >
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pl-0 py-8">
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                    "/media/svg/avatars/boy.svg"
                                )}
                            />
                          </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                                    >
                                                        Lebron Wayde
                                                    </a>
                                                    <span className="text-muted font-weight-bold d-block">
                            PHP, Laravel, VueJS
                          </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $34,000,000
                      </span>
                                            <span className="text-muted font-weight-bold">Paid</span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $6,700
                      </span>
                                            <span className="text-muted font-weight-bold">Paid</span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        RoadGee
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Transportation
                      </span>
                                        </td>
                                        <td>
                                            <Rate allowHalf defaultValue={2.5} />
                                            <span className="text-muted font-weight-bold d-block">
                        Best Rated
                      </span>
                                        </td>
                                        <td className="pr-0 text-right">
                                            <a
                                                href="#"
                                                className="btn btn-light-success font-weight-bolder font-size-sm"
                                            >
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pl-0 py-0">
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                    "/media/svg/avatars/boy.svg"
                                )}
                            />
                          </span>
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                                    >
                                                        Natali Trump
                                                    </a>
                                                    <span className="text-muted font-weight-bold d-block">
                            Python, PostgreSQL, ReactJS
                          </span>
                                                </div>
                                            </div>
                                        </td>
                                        {/*<td>*/}
                                        {/*    <div className="d-flex flex-column w-100 mr-2">*/}
                                        {/*        <div className="d-flex align-items-center justify-content-between mb-2">*/}
                                        {/*            <span*/}
                                        {/*                className="text-muted mr-2 font-size-sm font-weight-bold">65%</span>*/}
                                        {/*            <span*/}
                                        {/*                className="text-muted font-size-sm font-weight-bold">Progress</span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="progress progress-xs w-100">*/}
                                        {/*            <div className="progress-bar bg-danger" role="progressbar"*/}
                                        {/*                 style={{width: "65%"}} aria-valuenow="50" aria-valuemin="0"*/}
                                        {/*                 aria-valuemax="100"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</td>*/}
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $2,600,000
                      </span>
                                            <span className="text-muted font-weight-bold">Paid</span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        $14,000
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Pending
                      </span>
                                        </td>
                                        <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        The Hill
                      </span>
                                            <span className="text-muted font-weight-bold">
                        Insurance
                      </span>
                                        </td>
                                        <td>
                                            <Rate allowHalf defaultValue={2.5} />
                                            <span className="text-muted font-weight-bold d-block">
                        Average
                      </span>
                                        </td>
                                        <td className="pr-0  text-right">
                                            <a
                                                href="#"
                                                className="btn btn-light-success font-weight-bolder font-size-sm"
                                            >
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* end::Table */}
                        </div>
                    </div>
                    {/* end::Body */}
                </div>
            </div>
        </>
    );
}

export default Talents;
