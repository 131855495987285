import React from "react";
import LinkComponent from "../../route/LinkComponent";
import {toAbsoluteUrl} from "../../_helpers";

function Brand() {
    return (
        <>
            <div className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12`}>
                <LinkComponent to="" className="brand-logo">
                    {/*<img alt="logo" src={toAbsoluteUrl("/media/borderless.png")} className="max-h-30px"/>*/}
                    <h1> BH </h1>
                </LinkComponent>
            </div>
        </>
    );
}

export default Brand;
