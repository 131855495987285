import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import LinkComponent from "../../route/LinkComponent";
import {FormFeedback} from "reactstrap";
import {Button, Empty, message, Modal, notification, Upload} from "antd";
import {NOTIFICATION_DURATION, SUCCESS_CODE, UPLOAD_FILE} from "../../appRedux/constants";
import {redirectToLogin, redirectToOverview, redirectToProfile} from "../../route/Redirect";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {connect, useSelector} from "react-redux";
import {getTalentProfile, talentLogin} from "../../appRedux/actions/Talents";
import {handleNotify} from "../../appRedux/response";
import Programming from "../../_assets/media/svg/Coding-rafiki.svg";
import {FilePdfOutlined} from "@ant-design/icons";
import {getUrl} from "../../appRedux/api";
import {isEmpty} from "lodash"

function Resume(props) {

    const {talent_profile, talent_profile_loading, token} = useSelector(state => state.talent);
    const [defaultFile, setDefaultFile] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (isEmpty(token)) {
            notification['error']({
                message: 'Talent account not logged in',
                description: 'Please login',
                duration: NOTIFICATION_DURATION,
            });
            redirectToLogin();
        }
    }, []);

    //
    // const regSchema = Yup.object().shape({
    //     email: Yup.string()
    //         .min(3, "Minimum 3 symbols")
    //         .max(50, "Maximum 50 symbols")
    //         .email('Invalid email address').required('Email address is Required'),
    //     password: Yup.string()
    //         .required('Password is required')
    // });
    //
    // const formik = useFormik({
    //     initialValues: {
    //         email: '',
    //         password: '',
    //     },
    //     validationSchema: regSchema,
    //     onSubmit: (values, {setStatus, setSubmitting}) => {
    //         setSubmitting(true);
    //         setLaddaLoading(true);
    //         setLaddaProgress(0.5);
    //
    //         const {email, password: passwd} = values;
    //         props.talentLogin({email, passwd}).then(res => {
    //             handleIn(res)
    //         }).catch(e => {
    //             handleNotify(e.message, () => {
    //                 setLaddaLoading(false);
    //                 setLaddaProgress(1.0);
    //                 setSubmitting(false);
    //             })
    //         })
    //     },
    // });
    const url = getUrl(UPLOAD_FILE);
    const propUpload = {
        name: 'resume',
        maxCount: 1,
        accept: ".pdf",
        action: url,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        method: 'post',
        data: {
            type: 'resume'
        },
        async onChange(info) {
            if (info.file.status === 'uploading') {
                setShow(false)
                console.log(info.file, info.fileList);
            }
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                const {data, status} = info.file.response;
                if (status === SUCCESS_CODE) {
                    message.success(`${info.file.name} uploaded successfully`);
                    await props.getTalentProfile();
                    setShow(true)
                    // setDefaultFile([{
                    //     uid: '1',
                    //     name: data.resume_url,
                    //     status: 'done',
                    //     url: data.resume,
                    // }])
                } else {
                    setShow(false)
                    message.error(`Error! Uploading ${info.file.name}. Please try again`);
                }

            } else if (info.file.status === 'error') {
                setShow(false)
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    // const handleIn = res => {
    //     notification['success']({
    //         message: 'Talent account successful',
    //         description: 'welcome to possibilities',
    //         duration: NOTIFICATION_DURATION,
    //     });
    //     setLaddaProgress(0.9);
    //     redirectToOverview();
    // }

    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 space-top-3 space-lg-0">
                    {/*<form className="js-validate" onSubmit={formik.handleSubmit}>*/}
                    <div className="mb-5 mb-md-7">

                        <h1 className="h2">Upload Resume</h1>
                        {/*<p>Login to manage your account (as a talent)</p>*/}
                        <div>
                            <span className="font-size-1 text-muted">We know you want to get right at it. Kindly get this done so we can better place you 👍🏽</span>
                        </div>

                    </div>

                    {/*<Modal title="Resume / " wrapClassName={'model_css'} centered footer={null} header={null} visible={visible}*/}
                    {/*       closable={false} keyboard={false}>*/}
                    {/*    <>*/}
                    {/*        <div className={'row'}>*/}
                    {/*            <div className={"col-md-12"}>*/}
                    {/*                <div>*/}
                    {/*                    <Empty imageStyle={{*/}
                    {/*                        height: 320,*/}
                    {/*                    }} description={*/}
                    {/*                        <>*/}

                    {/*                        </>*/}
                    {/*                    } image={Programming}/>*/}
                    {/*                </div>*/}
                    {/*                <div className={'text-center'}>*/}
                    {/*                    <>*/}
                    {/*                        <h4>Oops!... Resume currently not uploaded</h4>*/}
                    {/*                        <p>Please get this done so we can better place you 👍🏽 </p>*/}
                    {/*                    </>*/}
                    {/*                    <div className="js-form-message form-group required-field">*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*</Modal>*/}


                    {/*<Upload {...propUpload} fileList={defaultFile}>*/}
                    <Upload {...propUpload} >
                        <Button icon={<FilePdfOutlined/>}> Click to Upload Resume</Button>
                    </Upload>
                    {/*{uploading && <p>Uploading file, please wait... </p>}*/}


                    {/*<div className="js-form-message form-group">*/}
                    {/*    <label className="input-label" htmlFor="signinSrEmail">Email address</label>*/}
                    {/*    <input type="email"*/}
                    {/*           className="form-control"*/}
                    {/*           name="email"*/}
                    {/*           placeholder="Email address"*/}
                    {/*           onChange={formik.handleChange}*/}
                    {/*           onBlur={formik.handleBlur}*/}
                    {/*           value={formik.values.email}*/}
                    {/*           aria-label="Email address"/>*/}
                    {/*    {formik.touched.email && formik.errors.email &&*/}
                    {/*    <FormFeedback>{formik.errors.email}</FormFeedback>}*/}
                    {/*</div>*/}

                    {/*<div className="js-form-message form-group">*/}
                    {/*    <label className="input-label" htmlFor="signinSrPassword" tabIndex="0">*/}
                    {/*      <span className="d-flex justify-content-between align-items-center">*/}
                    {/*        Password*/}
                    {/*        <LinkComponent*/}
                    {/*            to={"/talents/forgot-password"}*/}
                    {/*            className="link-underline text-capitalize font-weight-normal"*/}
                    {/*        >Forgot Password?</LinkComponent>*/}
                    {/*      </span>*/}
                    {/*    </label>*/}
                    {/*    <input type="password" className="form-control" name="password"*/}
                    {/*           id="signinSrPassword"*/}
                    {/*           onChange={formik.handleChange}*/}
                    {/*           onBlur={formik.handleBlur}*/}
                    {/*           value={formik.values.password}*/}
                    {/*           placeholder="********"*/}
                    {/*           aria-label="********"/>*/}
                    {/*    {formik.touched.password && formik.errors.password &&*/}
                    {/*    <FormFeedback>{formik.errors.password}</FormFeedback>}*/}
                    {/*</div>*/}

                    {show && <>
                        <hr/>
                        <br/>

                        <br/>
                        <div className="row align-items-center mb-5">
                            <div className="col-sm-12 text-sm-right">
                                <LaddaButton
                                    // loading={laddaLoading}
                                    // progress={laddaProgress}
                                    data-style={ZOOM_OUT}
                                    data-spinner-size={30}
                                    data-spinner-color="#fff"
                                    data-spinner-lines={12}
                                    className="btn btn-primary transition-3d-hover"
                                    type="submit"
                                    onClick={() => redirectToOverview()}
                                    // disabled={formik.isSubmitting}
                                >
                                    Proceed to profile
                                </LaddaButton>
                            </div>
                        </div>
                    </>}
                    {/*</form>*/}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({talent}) => {
    const {talent_profile_loading, talent_profile} = talent;
    return {talent_profile_loading, talent_profile}
}

export default connect(mapStateToProps, {getTalentProfile})(Resume);
