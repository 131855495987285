import React, {useState} from 'react';
import {Aside, SubHeader} from "../../components";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_helpers";

function ScrollTop() {
    return (
        <div id="bh_scrolltop" className="scrolltop">
        <span className="svg-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")}/>
        </span>{" "}
        </div>
    );
}

const Inner = (Component) => {

    const [aside, setAside] = useState('aside-minimize');
    const handleAside = () => {
        (aside === "") ? setAside('aside-minimize') : setAside('');
    }

    return (props) => (
        <>
            <div
                className={`header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-secondary-enabled ${aside}`}>
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <Aside aside={handleAside}/>

                        <div className="d-flex flex-column flex-row-fluid wrapper" id="bh_wrapper">
                            <div id="bh_content" className={`content d-flex flex-column flex-column-fluid`}>
                                <SubHeader/>

                                <div className="d-flex flex-column-fluid">
                                    <div className={"container"}>
                                        <Component {...props}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*<QuickUser />*/}
                {/*<QuickPanel />*/}
                {/*<QuickNotification />*/}
                <ScrollTop/>
                {/*<QuickSearch />*/}
                {/*<QuickActions />*/}
                {/*<StickyToolbar />*/}
            </div>
        </>
    )
}

export default Inner;
