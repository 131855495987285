import React, {forwardRef, useEffect, useState, useImperativeHandle} from 'react';
import LinkComponent from "../../route/LinkComponent";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import * as Yup from "yup";
import {useFormik} from "formik";
import {FormFeedback} from "reactstrap";
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {getCountryLists} from "../../appRedux/actions/Country";
import {clientSignUp} from "../../appRedux/actions/Clients";
import {notification, Select} from "antd";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {redirectToClientProfile} from "../../route/Redirect";
import {handleNotify} from "../../appRedux/response";
import PhoneInput from "react-phone-number-input";
import account from "../../_assets/media/svg/illustrations/Account-amico.svg"

const {Option} = Select;


const ClientSignUp = forwardRef((props, ref) => {

    const currentState = 1;
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);
    const [currentTab, setCurrentTab] = useState(currentState);
    const [mail, setMail] = useState("");
    const [succestTab, setSuccestTab] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disabledFirst, setDisabledFirst] = useState('disabled');

    useEffect(() => {
        props.getCountryLists();
    }, []);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        setNewAccessWidth: "620px"
    }));

    // ref.current.setNewAccessWidth();

    const regSchema = Yup.object().shape({
        company_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required('Company name is Required'),
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .email('Invalid email address').required('Email address is Required'),
        size: Yup.string().required('Company is Required'),
        address: Yup.string().required('Address is Required'),
        number: Yup.number().required('Number is Required'),
        location: Yup.string().required('state/province is Required'),
        country: Yup.string().required('country is Required'),
        industry: Yup.string().required('industry is Required'),
        consent: Yup.boolean()
            .required('The Terms and Conditions must be accepted.')
            .oneOf([true], 'Please accept our Terms and Conditions'),
    });

    const {country_loading, country_list, industry_list, industry_loading, location_list, location_loading} = props;

    const formik = useFormik({
        initialValues: {
            company_name: '',
            email: '',
            size: '0-5',
            address: '',
            number: '',
            location: '',
            industry: Object.keys(industry_list)[0],
            country: Object.keys(country_list)[0],
            consent: false,
        },
        validationSchema: regSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            const {company_name: name, number: phone_number, email, size, address, location, consent, country: country_id, industry: industry_id} = values;
            setMail(email)
            props.clientSignUp({
                name,
                address,
                size,
                email,
                phone_number,
                consent,
                location,
                country_id,
                industry_id
            }).then(res => {
                handleIn(res)
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })

            // props.resetPassword(values).then(res => {
            //     notification['success']({
            //         message: 'Password reset',
            //         description: 'Password update successfully',
            //         duration: NOTIFICATION_DURATION,
            //     });
            //     resetForm(true);
            // }).catch(e => {
            //     handleNotify(e.message, () => {
            //         console.log('got here')
            //     })
            // }).finally(() => {
            //     setLaddaLoading(false);
            //     setLaddaProgress(1.0);
            //     setSubmitting(false);
            // })

        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Client account successful',
            description: res.message,
            duration: NOTIFICATION_DURATION,
        });
        setLaddaProgress(0.9);
        // redirectToClientProfile();
        setSuccestTab(true);
    }

    const handlePreviousClicked = () => {
        setCurrentTab(currentTab - 1)
    }
    const handleNextClicked = () => {
        setCurrentTab(currentTab + 1)
    }

    let options = [];
    let optionIndustry = [];
    // let optionLocation = [];
    for (let country in country_list) {
        options.push(<Option key={country} value={country}>{country_list[country]}</Option>)
    }
    for (let industry in industry_list) {
        optionIndustry.push(<Option key={industry} value={industry}>{industry_list[industry]}</Option>)
    }
    // for (let location in location_list) {
    //     optionLocation.push(<Option key={location} value={location}>{location_list[location]}</Option>)
    // }

    return (
        <>
            <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-8 offset-md-2 offset-lg-2 offset-xl-2 space-top-3 space-lg-0">
                    <div className="login-content d-flex flex-column pt-lg-0 pt-12">

                        {!succestTab && <div className="login-form">
                            <form className="form fv-plugins-bootstrap fv-plugins-framework"
                                  onSubmit={formik.handleSubmit}>

                                <div className="text-center">
                                    <ul className="nav nav-segment nav-pills scrollbar-horizontal mb-7" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${currentTab === 1 && 'active'}`}
                                               id="pills-one-code-features-example1-tab"
                                               data-toggle="pill" role="tab"
                                               aria-controls="pills-one-code-features-example1" aria-selected="true">
                                                Business Account
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a type='button' className={`nav-link ${currentTab === 2 && 'active'}`}
                                               id="pills-two-code-features-example1-tab"
                                               data-toggle="pill" role="tab"
                                               aria-controls="pills-two-code-features-example1"
                                               aria-selected="false">Business Details</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${currentTab === 3 && 'active'}`}
                                               id="pills-three-code-features-example1-tab"
                                               data-toggle="pill" role="tab"
                                               aria-controls="pills-three-code-features-example1"
                                               aria-selected="false"> Overview </a>
                                        </li>
                                    </ul>
                                </div>

                                {currentTab === 1 &&
                                <div>
                                    <div className="pb-5 pb-lg-15">
                                        <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"> Business
                                            Account</h3>
                                        <div className="text-muted font-weight-bold font-size-h4">Already have an
                                            Account
                                            ? {" "}
                                            <LinkComponent to={"/client/login"}
                                                           className="text-primary "> Sign
                                                In </LinkComponent>
                                        </div>
                                    </div>

                                    <div className="js-form-message form-group">
                                        <label className="input-label">Company Name</label>
                                        <input type="text"
                                               className="form-control"
                                               name="company_name"
                                               placeholder="Company Name"
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               value={formik.values.company_name}
                                               aria-label="Company Name"/>
                                        {formik.touched.company_name && formik.errors.company_name &&
                                        <FormFeedback>{formik.errors.company_name}</FormFeedback>}
                                    </div>

                                    <div className="js-form-message form-group">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <label className="input-label">Email address</label>
                                                <p className="form-text"><small>Business
                                                    email only </small></p>
                                                <input type="email"
                                                       className="form-control"
                                                       name="email"
                                                       placeholder="Email address"
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       value={formik.values.email}
                                                       aria-label="Email address"/>
                                                {formik.touched.email && formik.errors.email &&
                                                <FormFeedback>{formik.errors.email}</FormFeedback>}
                                            </div>
                                            <div className="col-md-4">
                                                <label className="input-label"> Company Size </label>
                                                <Select
                                                    defaultValue={'1-20'}
                                                    style={{width: '100%'}}
                                                    className={'select'}
                                                    size={'large'}
                                                    name="size"
                                                    // onChange={formik.handleChange}
                                                    // onBlur={formik.handleBlur}
                                                    // value={formik.values.size}
                                                    onChange={event => {
                                                        formik.values.size = event;
                                                        formik.handleChange.size = event;
                                                    }}
                                                >

                                                    <Option value="1-20"> 1 - 20 </Option>
                                                    <Option value="21-50"> 21-50 </Option>
                                                    <Option value="51-200"> 51-200 </Option>
                                                    <Option value="201-1000"> 201-1000 </Option>
                                                    <Option value="1001_above"> 1001 &amp; above </Option>

                                                </Select>
                                                {formik.touched.size && formik.errors.size &&
                                                <FormFeedback>{formik.errors.size}</FormFeedback>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div className="mr-2"/>
                                        <div className="mr-2">
                                            <button
                                                type="button"
                                                disabled={!((isEmpty(formik.errors.company_name) && isEmpty(formik.errors.email) && !isEmpty(formik.touched)))}
                                                onClick={handleNextClicked}
                                                className="btn btn-primary transition-3d-hover">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>}

                                {currentTab === 2 &&
                                <div>
                                    <div>
                                        <div className="pb-5 pb-lg-15">
                                            <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                                                Address Details</h3>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="js-form-message form-group">
                                                    <label className="input-label">Address</label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="address"
                                                           onChange={formik.handleChange}
                                                           onBlur={formik.handleBlur}
                                                           value={formik.values.address}/>
                                                    {formik.touched.address && formik.errors.address &&
                                                    <FormFeedback>{formik.errors.address}</FormFeedback>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-7">
                                                <div className="js-form-message form-group">
                                                    <label className="input-label"> Industry/Sector </label>

                                                    <Select
                                                        defaultValue={formik.values.industry}
                                                        style={{width: '100%'}}
                                                        className={'select'}
                                                        size={'large'}
                                                        loading={industry_loading}
                                                        name="industry"
                                                        placeholder="Select industry"
                                                        onBlur={formik.handleBlur}
                                                        onChange={event => {
                                                            formik.values.industry = event;
                                                            formik.handleChange.industry = event;
                                                        }}>
                                                        {optionIndustry}
                                                    </Select>
                                                    {formik.touched.industry && formik.errors.industry &&
                                                    <FormFeedback>{formik.errors.industry}</FormFeedback>}
                                                </div>
                                            </div>
                                            <div className="col-xl-5">
                                                <div className="js-form-message form-group">
                                                    <label className="input-label"> Contact Number </label>
                                                    <PhoneInput
                                                        className="form-control"
                                                        placeholder="Enter phone number"
                                                        name={"number"}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.number}
                                                        onChange={event => {
                                                            formik.values.number = event;
                                                            formik.handleChange.number = event;
                                                        }}/>
                                                    {formik.touched.number && formik.errors.number &&
                                                    <FormFeedback>{formik.errors.number}</FormFeedback>}

                                                    {/*<input type="number"*/}
                                                    {/*       className="form-control"*/}
                                                    {/*       name="number"*/}
                                                    {/*       onChange={formik.handleChange}*/}
                                                    {/*       onBlur={formik.handleBlur}*/}
                                                    {/*       value={formik.values.number}/>*/}
                                                    {/*{formik.touched.number && formik.errors.number &&*/}
                                                    {/*<FormFeedback>{formik.errors.number}</FormFeedback>}*/}
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="js-form-message form-group">
                                                    <label className="input-label"> Country </label>

                                                    <Select
                                                        defaultValue={formik.values.country}
                                                        style={{width: '100%'}}
                                                        className={'select'}
                                                        size={'large'}
                                                        loading={country_loading}
                                                        name="country"
                                                        placeholder="Select country"
                                                        onBlur={formik.handleBlur}
                                                        onChange={event => {
                                                            formik.handleChange.country = event;
                                                            formik.values.country = event;
                                                        }}>
                                                        {options}
                                                    </Select>
                                                    {formik.touched.country && formik.errors.country &&
                                                    <FormFeedback>{formik.errors.country}</FormFeedback>}
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="js-form-message form-group">
                                                    <label className="input-label"> Location </label>

                                                    <input type="text"
                                                           className="form-control"
                                                           name="location"
                                                           onChange={formik.handleChange}
                                                           onBlur={formik.handleBlur}
                                                           value={formik.values.location}/>
                                                    {formik.touched.location && formik.errors.location &&
                                                    <FormFeedback>{formik.errors.location}</FormFeedback>}
                                                    {/*<Select*/}
                                                    {/*    defaultValue={formik.values.location}*/}
                                                    {/*    style={{width: '100%'}}*/}
                                                    {/*    className={'select'}*/}
                                                    {/*    size={'large'}*/}
                                                    {/*    loading={location_loading}*/}
                                                    {/*    name="location"*/}
                                                    {/*    placeholder="Select Location"*/}
                                                    {/*    onBlur={formik.handleBlur}*/}
                                                    {/*    onChange={event => {*/}
                                                    {/*        formik.values.location = event;*/}
                                                    {/*        formik.handleChange.location = event;*/}
                                                    {/*    }}>*/}
                                                    {/*    {optionLocation}*/}
                                                    {/*</Select>*/}
                                                    {/*{formik.touched.location && formik.errors.location &&*/}
                                                    {/*<FormFeedback>{formik.errors.location}</FormFeedback>}*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="mr-2">
                                            {currentTab !== 1 &&
                                            <button type="button"
                                                    onClick={handlePreviousClicked}
                                                    className="btn btn-soft-primary transition-3d-hover">
                                                Previous
                                            </button>}
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                disabled={!((
                                                    // isEmpty(formik.errors.country) &&
                                                    // isEmpty(formik.errors.location) &&
                                                    // isEmpty(formik.errors.industry) &&
                                                    !isEmpty(formik.values.country) &&
                                                    !isEmpty(formik.values.location) &&
                                                    !isEmpty(formik.values.industry) &&
                                                    isEmpty(formik.errors.number) &&
                                                    isEmpty(formik.errors.address) &&
                                                    !isEmpty(formik.touched)))}
                                                onClick={handleNextClicked}
                                                className="btn btn-primary transition-3d-hover">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>}

                                {currentTab === 3 &&
                                <div>
                                    <div>
                                        <div className="mb-6">
                                            <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Complete</h3>
                                            <div className="text-muted font-weight-bold font-size-h4">Complete Your
                                                Signup!
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <h4 className="font-weight-bolder mb-3"> Business Details </h4>
                                                <div className="text-dark-50 line-height-lg mb-2">
                                                    <div>{formik.values.company_name}</div>
                                                    <div>{formik.values.number}</div>
                                                    <div>{formik.values.email}</div>
                                                    <div>{industry_list[formik.values.industry]}</div>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <h4 className="font-weight-bolder mb-3"> Business Information </h4>
                                                <div className="text-dark-50 line-height-lg mb-2">
                                                    <div>{formik.values.address}</div>
                                                    <div>{formik.values.location}, {country_list[formik.values.country]}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<h4 className="font-weight-bolder mb-3">Support Channels:</h4>*/}
                                        {/*<div className="text-dark-50 font-weight-bold line-height-lg mb-8">*/}
                                        {/*    <div>Overnight Delivery with Regular Packaging</div>*/}
                                        {/*    <div>Preferred Morning (8:00AM - 11:00AM) Delivery</div>*/}
                                        {/*</div>*/}

                                        <hr/>
                                        <div className="js-form-message mb-5">
                                            <div
                                                className="custom-control custom-checkbox d-flex align-items-center text-muted">
                                                <input type="checkbox"
                                                       className="custom-control-input"
                                                       id="termsCheckbox"
                                                       name="consent"
                                                       checked={formik.values.consent}
                                                       onBlur={formik.handleBlur}
                                                       onChange={formik.handleChange}/>
                                                <label className="custom-control-label" htmlFor="termsCheckbox">
                                                    <small>
                                                        I agree to the<LinkComponent to={"/terms"}
                                                                                     className="link-underline">Terms
                                                        and Conditions</LinkComponent>
                                                    </small>
                                                </label>
                                            </div>
                                            {formik.touched.consent && formik.errors.consent &&
                                            <FormFeedback>{formik.errors.consent}</FormFeedback>}
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div className="mr-2">
                                            {currentTab !== 1 &&
                                            <button type="button"
                                                    onClick={handlePreviousClicked}
                                                    className="btn btn-soft-primary transition-3d-hover">
                                                Previous
                                            </button>}
                                        </div>
                                        <div className="">
                                            {/*{currentTab !== 3 &&*/}
                                            {/*<button type="button"*/}
                                            {/*        disabled={false}*/}
                                            {/*        onClick={handleNextClicked}*/}
                                            {/*        className="btn btn-primary transition-3d-hover"> {currentTab !== 3 ? 'Next' : 'Create Client'}*/}
                                            {/*</button>}*/}

                                            {currentTab === 3 &&
                                            <LaddaButton
                                                loading={laddaLoading}
                                                progress={laddaProgress}
                                                data-style={ZOOM_OUT}
                                                data-spinner-size={30}
                                                data-spinner-color="#fff"
                                                data-spinner-lines={12}
                                                className="btn btn-primary transition-3d-hover"
                                                type="submit"
                                                disabled={formik.isSubmitting}>
                                                Create Account
                                            </LaddaButton>}

                                        </div>
                                    </div>
                                </div>}
                            </form>
                        </div>}
                        {succestTab && <div className="login-form">
                            <div>
                                <div className="text-center">
                                    <img className="img-fluid mb-3" src={account}
                                         alt="Image Description" style={{maxWidth: '15rem'}}/>

                                    <div className="mb-4">
                                        <h2>Business account has been successfully created!</h2>
                                        <p className={'text font-size-1'}>Please check <strong>"{mail}"</strong> for you
                                            account password to activate your account.</p>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <LinkComponent to={'/client/login'} className="btn btn-primary mr-4"
                                                       href="employee.html">
                                            Go to profile
                                        </LinkComponent>
                                        <a className="btn btn-soft-primary btn-pill transition-3d-hover" target="_blank"
                                           href="https://calendly.com/paschal-borderlesshr">
                                            Schedule a call with us <i className="fas fa-angle-right ml-1"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </>
    );
})

const mapStateToProps = ({country, industry, location}) => {
    const {country_loading, country_list} = country;
    const {industry_loading, industry_list} = industry;
    const {location_loading, location_list} = location;
    return {country_loading, country_list, industry_loading, industry_list, location_loading, location_list}
}

export default connect(mapStateToProps, {getCountryLists, clientSignUp})(ClientSignUp);
