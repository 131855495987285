import api, {setToken} from "../api";
import {
    CLIENT_PROFILE_LOGIN_END,
    CLIENT_RESET_PASSWORD,
    JOB_APPLIED,
    JOB_APPLIED_END, JOB_APPLIED_LOADING_END, JOB_APPLIED_LOADING_START, JOB_APPLIED_START,
    JOB_APPLY,
    JOB_DETAIL, JOB_DETAIL_END, JOB_DETAIL_START,
    JOB_LIST,
    JOB_LIST_END,
    JOB_LIST_START, PASSWORD_AUTH_LOADING_END,
    SKILL_FEATURE,
    SKILL_FEATURE_END,
    SKILL_FEATURE_START, SUCCESS_CODE, TALENT_RESET_PASSWORD
} from "../constants";
import {clearSessionWithParams, setSession} from "../store/cookies";
import {handleNotifyMessage} from "../response";

export const getSkillsFeatured = () => {
    return dispatch => {
        dispatch({type: SKILL_FEATURE_START});
        api.get(SKILL_FEATURE + '/12').then(({data}) => {
            if (data.status === SUCCESS_CODE) {
                dispatch({type: SKILL_FEATURE_END, payload: data.data});
            }
        })
    }
}

const dispatchJob = params => async dispatch => {
    dispatch({type: JOB_LIST_START});
    const {data} = await api.get(JOB_LIST, {params: params});
    if (data.status === SUCCESS_CODE) {
        dispatch({type: JOB_LIST_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const getJobLists = params => dispatchJob(params)
export const searchJobLists = params => dispatchJob(params)

// export const searchJobLists = params => async dispatch => {
//     dispatch({type: JOB_LIST_START});
//     const {data} = await api.get(JOB_LIST, {params: params})
//     if (data.status === SUCCESS_CODE) {
//         dispatch({type: JOB_LIST_END, payload: data.data});
//         return {...data.data}
//     } else {
//         throw new Error(data.message)
//     }
// }

export const getJobDetail = job_id => dispatch => {
    dispatch({type: JOB_DETAIL_START});
    api.get(JOB_DETAIL + job_id).then(({data}) => {
        if (data.status === SUCCESS_CODE) {
            dispatch({type: JOB_DETAIL_END, payload: data.data});
        } else {
            dispatch({type: JOB_DETAIL_END, payload: {}});
            handleNotifyMessage(data.message, 'error');
        }
    })
}

export const talentApplyForJob = job_id => async dispatch => {
    dispatch({type: JOB_APPLIED_START});
    const {data} = await api.get(JOB_APPLY + job_id);
    if (data.status === SUCCESS_CODE) {
        dispatch({type: JOB_DETAIL_END, payload: data.data});
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

// export const clearAppliedJobs = () => dispatch => {
//     dispatch({type: JOB_APPLIED_LOADING_END, payload: {}});
// }

export const getAppliedJobs = (params) => {
    return dispatch => {
        dispatch({type: JOB_APPLIED_LOADING_START});
        api.get(JOB_APPLIED, {params: params}).then(({data}) => {
            if (data.status === SUCCESS_CODE) {
                dispatch({type: JOB_APPLIED_LOADING_END, payload: data.data}); // this is to update current job applied for
            }
        })
    }
}
