import React, {useEffect, useState} from 'react';
import {Alert, Button, DatePicker, Empty, List, notification, Popconfirm, Select, Skeleton} from "antd";
import {FormFeedback} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import * as Yup from "yup";
import {ErrorMessage, useFormik} from "formik";
import {connect, useDispatch, useSelector} from "react-redux";
import PhoneInput from 'react-phone-number-input'
import {handleNotify} from "../../appRedux/response";
import {ACTIVE_TALENT_ACCOUNT, NOTIFICATION_DURATION} from "../../appRedux/constants";
import {getTalentSKills, updatePinTalentSkill, updateTalent, updateTalentSkills} from "../../appRedux/actions/Talents";
import {Loading} from "../../components/Loading";
import {isEmpty} from "lodash";
import PopconfirmExperienceDelete from "./PopconfirmExperienceDelete";
import PopconfirmSkillDelete from "./PopconfirmSkillDelete";
import Programming from "../../_assets/media/svg/Coding-rafiki.svg";
import developer from "../../_assets/media/svg/illustrations/developer.svg";


const {Option} = Select;

function General(props) {

    const [skills, setSkills] = useState({});
    const [loadedSkills, setLoadedSkills] = useState([]);
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);

    const {talent_skills, talent_skills_loading, skill_loading, skill_list, skill_list_loading, country_loading} = props;

    useEffect(() => {
        props.getTalentSKills()
    }, []);

    useEffect(() => {
        setLoadedSkills(talent_skills)
    }, [talent_skills]);

    useEffect(() => {
        updateSkills()
    }, [loadedSkills, skill_list]);

    const updateSkillsNode = (e, eventNode) => {
        setLoadedSkills(loadedSkills => {
            loadedSkills.push({
                skill_id: parseInt(eventNode.value),
                years: null,
                feature: null,
                skill: {id: eventNode.children, title: eventNode.children}
            });
            return loadedSkills;
        });
        updateSkills()
    }

    const updateSkills = () => {
        let talentSkill = {};
        for (let i in loadedSkills) {
            talentSkill[loadedSkills[i].skill_id] = loadedSkills[i].skill.title
        }

        let items = {};
        for (let skill in skill_list) {
            if (isEmpty(talentSkill[skill])) {
                items[skill] = skill_list[skill]
            }
        }
        setSkills(items)
    }

    const formik = useFormik({
        initialValues: {'skill': loadedSkills},
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            // skill: Yup.array().of(
            //     Yup.object().shape({
            //         year: Yup.array().required("year required"),
            //         competency: Yup.array().required("competency required"),
            //     })
            // )
        }),
        onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            props.updateTalentSkills(values).then(res => {
                handleIn(res)
                setSubmitting(false);
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        notification['success']({
            message: 'Profile updated successful',
            description: res.message,
            duration: NOTIFICATION_DURATION,
        });
        setLaddaLoading(false);
        setLaddaProgress(1.0);
    }

    const year_list = [
        {key: '1', value: '<= 1 year'},
        {key: '2', value: '2 years'},
        {key: '3', value: '3 years'},
        {key: '4', value: '4 years'},
        {key: '5', value: '5 years'},
        {key: '6', value: '6 years'},
        {key: '7', value: '7 years'},
        {key: '8', value: '8 years'},
        {key: '9', value: '9 years'},
        {key: '10', value: '>= 10 years'},
    ];
    const competency_list = [
        {index: 1, key: 'beginner', value: 'Beginner'},
        {index: 2, key: 'experienced', value: 'Experienced'},
        {index: 3, key: 'advance', value: 'Advance'},
        {index: 4, key: 'expert', value: 'Expert'},
    ];

    let optionSkills = [];
    let yearsOptions = [];
    let competencyOption = [];

    for (let skill in skills) {
        optionSkills.push(<Option key={skill} value={skill}>{skills[skill]}</Option>)
    }

    for (let year in year_list) {
        yearsOptions.push(<Option key={year_list[year].key}
                                  value={year_list[year].key}>{year_list[year].value}</Option>)
    }
    for (let competency in competency_list) {
        competencyOption.push(<Option key={competency_list[competency].key}
                                      value={competency_list[competency].key}>{competency_list[competency].value}</Option>)
    }

    const pinSkills = (skill_id, type) => {
        props.updatePinTalentSkill(skill_id, type).then(res => {
            handleIn(res)
            props.getTalentSKills()
        }).catch(e => {
            handleNotify(e.message, () => {
            })
        })
    }

    let pinned = 0;
    const skillPinned = loadedSkills.map((skill, index) => {
            if (skill.pin === 1) {
                pinned++;
            }
            return (
                skill.pin === 1 && <div key={index} className={'col-sm-6 col-lg-4 col-xl-4 mb-3 mb-sm-5'}>
                    <div className="card card-bordered card-hover-shadow h-100">
                        <div className="card-body">
                            <div className="media align-items-center">
                                <div className="media-body">
                                    <small
                                        className="skill_feature"> {isEmpty(skill.feature) ? 'beginner' : skill.feature} </small>
                                    <h3 className="text-hover-primary mb-0"> {skill?.skill?.title} </h3>
                                    <small
                                        className="text-body"> {isEmpty(skill.years) ? '<= 1 Year' : `${skill.years} Years`} </small>
                                </div>

                                <div className="text-muted text-hover-primary pl-2 ml-auto">
                                    {/*<i className="fas fa-angle-right"/>*/}
                                    <button type="button" onClick={() => pinSkills(skill.skill_id, 'unpin')} className="btn btn-sm btn-outline-info btn-xs transition-3d-hover mt-2">
                                        unPin
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    );

    const countedSkills = [];
    for (let i in loadedSkills) {
        let skill = loadedSkills[i];
        if (typeof skill?.id !== "undefined") {
            countedSkills.push(skill)
        }
    }

    return (
        <>

            <div className={'card mb-5'}>
                <div className="card-header border-0 bg-light px-4">
                    <h5 className="card-header-title"> Top 3 Stacks <br/> <small>Your Selected top skills</small></h5>
                </div>
                <div className="card-body">
                    <div className="row form-group">
                        {/*<div className="col-sm-3">*/}
                        {/*    <span className="d-block font-size-1 mb-2">*/}
                        {/*        Your Selected top skills*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                        <div className="col-sm-12">
                            {/*<span className="d-block font-size-1 mb-2">*/}
                            {/*    Your Selected top skills*/}
                            {/*</span>*/}
                            {/*<div>{countedSkills.length < 3 && <Alert type="info" message={'Pinned skills is not up to 3'} />}</div>*/}

                            {talent_skills_loading ? <>
                                <Skeleton active/>
                            </> : <>
                                {pinned === 0 ? <div className={''}><Empty imageStyle={{
                                    height: 320,
                                }} description={
                                    <>Oops!... Empty top skills</>
                                } image={Programming}/></div> : <>
                                    <div className={'row'}>{skillPinned}</div>
                                </>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            <div className={'card mb-5'}>
                <div className="card-header border-0 bg-light text-center px-4">
                    <h5 className="card-header-title"> Technical Details </h5>
                </div>
                <div className="card-body">
                    {/*{skill_list_loading && <Loading/>}*/}
                    <div className="row">
                        <div className={"col-md-12"}>
                            <form className="js-validate" onSubmit={formik.handleSubmit}>

                                <div className="row form-group">
                                    <div className="col-sm-3">
                                        <span className="d-block font-size-1 mb-2">
                                            Select the role/stack that best fits you
                                        </span>
                                    </div>
                                    <div className="col-sm-9">
                                        <div>
                                            <div className="js-form-message  form-group">
                                                <label className="input-label"> Role & Stacks </label>

                                                <Select
                                                    style={{width: '100%'}}
                                                    className={'select select_skill'}
                                                    size={'large'}
                                                    loading={skill_loading}
                                                    // name="skills"
                                                    // placeholder="Select skill"
                                                    // mode="multiple"
                                                    value={""}
                                                    onBlur={formik.handleBlur}
                                                    // onSelect={(e, eventNode) => {
                                                    //     updateSkillsNode(eventNode)
                                                    // }}
                                                    onChange={updateSkillsNode}
                                                    // onChange={(event, eventNode) => {
                                                    //     // formik.values.skills = event;
                                                    //     // formik.handleChange.skills = event;
                                                    //
                                                    // }}
                                                >
                                                    {optionSkills}
                                                </Select>
                                                {/*{formik.touched.skills && formik.errors.skills &&*/}
                                                {/*<FormFeedback>{formik.errors.skills}</FormFeedback>}*/}
                                            </div>

                                            {talent_skills_loading ? <>
                                                <Skeleton active/>
                                                <Skeleton active/>
                                            </> : <List
                                                // header={<div>Header</div>}
                                                // footer={<div>Footer</div>}
                                                dataSource={loadedSkills}
                                                // loading={talent_skills_loading}
                                                renderItem={(skill, index) => (
                                                    <List.Item>
                                                        <div style={{width: '100%'}} className={'row'}>

                                                            <input type="hidden"
                                                                   name={`skill.${skill.skill_id}.skill_id`}
                                                                   value={!isEmpty(formik.values.skill) && formik.values.skill[index].skill_id}
                                                                   onChange={formik.handleChange}
                                                                   onBlur={formik.handleBlur}/>
                                                            <div className={'col-md-3'}>
                                                                <div className={'mt-2'}>
                                                                    <h4 style={{fontSize: "16px"}}>{skill.skill.title}</h4>
                                                                </div>
                                                            </div>
                                                            <div className={'col-md-6'}>
                                                                <div className={'row'}>
                                                                    <div className={'col-md-6'}>
                                                                        <div className="js-form-message required-field">
                                                                            <Select
                                                                                defaultValue={!isEmpty(skill.years) ? skill.years : '1'}
                                                                                //value={!isEmpty(formik.values.skill) && formik.values.skill[index].year}
                                                                                style={{width: '100%'}}
                                                                                size={'small'}
                                                                                className={'select select_skill role_stack'}
                                                                                name={`skill.${index}.years`}
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={event => {
                                                                                    formik.values = event;
                                                                                    formik.setFieldValue(
                                                                                        `skill.${index}.years`,
                                                                                        event
                                                                                    )
                                                                                }}>
                                                                                {yearsOptions}
                                                                            </Select>
                                                                            {/*<ErrorMessage name={`skill.${skill.skill_id}.year`} />*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className={'col-md-6'}>
                                                                        <div className="js-form-message required-field">
                                                                            <Select
                                                                                defaultValue={!isEmpty(skill.feature) ? skill.feature : 'beginner'}
                                                                                //value={!isEmpty(formik.values.skill) && formik.values.skill[index].feature}
                                                                                style={{width: '100%'}}
                                                                                size={'small'}
                                                                                className={'select select_skill role_stack'}
                                                                                name={`skill.${index}.feature`}
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={event => {
                                                                                    formik.values = event;
                                                                                    formik.setFieldValue(
                                                                                        `skill.${index}.feature`,
                                                                                        event
                                                                                    )
                                                                                }}>
                                                                                {competencyOption}
                                                                            </Select>
                                                                            {/*<ErrorMessage name={`skill.${skill.skill_id}.competency`} />*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'col-md-3'}>
                                                                {skill?.pin !== 1 &&
                                                                <>{(typeof skill?.id !== "undefined" && countedSkills.length !== 3) &&
                                                                <button type="button"
                                                                        onClick={() => pinSkills(skill.skill_id, 'pin')}
                                                                        className="btn btn-sm btn-outline-info btn-xs transition-3d-hover mt-2">
                                                                    Pin
                                                                </button>}
                                                                </>}

                                                                <PopconfirmSkillDelete skill={skill}
                                                                                       countedSkills={countedSkills}/>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            />}

                                            <p className={'d-block font-size-1 mb-2'}><small>Select years of experience
                                                and how competent you are with each of them</small>. </p>
                                        </div>
                                    </div>
                                </div>


                                <div className=" text-sm-right">
                                    <LaddaButton
                                        loading={laddaLoading}
                                        progress={laddaProgress}
                                        data-style={ZOOM_OUT}
                                        data-spinner-size={30}
                                        data-spinner-color="#fff"
                                        data-spinner-lines={12}
                                        className="btn btn-primary transition-3d-hover"
                                        type="submit"
                                        disabled={formik.isSubmitting}>
                                        Save
                                    </LaddaButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStatToProps = ({talent, skill}) => {
    return {...talent, ...skill}
}

export default connect(mapStatToProps, {getTalentSKills, updateTalentSkills, updatePinTalentSkill})(General);
