import React from 'react';
import ReactDOM from 'react-dom';

import './fonts.css';
import 'ladda/dist/ladda.min.css';
import 'react-phone-number-input/style.css';
import 'antd/dist/antd.css';

// import '../src/_assets/css/index.scss';
import '../src/_assets/css/fontawesome.css';
import '../src/_assets/css/landing.css';
import './landing.scss';
import "animate.css"

import App from './App';
import reportWebVitals from './reportWebVitals';

import configureStore from './appRedux/store';
import {Provider} from 'react-redux';

/**
 * Base URL of the website.
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;
export const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App basename={PUBLIC_URL}/>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
