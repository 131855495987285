import React, {useState} from 'react';
import {Popconfirm} from "antd";
import {useDispatch} from "react-redux";
import {deleteSkill} from "../../appRedux/actions/Talents";
import {LoadingMini} from "../../components/Loading";

function PopconfirmSkillDelete(props) {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const confirm = (id) => {
        setLoading(true)
        dispatch(deleteSkill(id))
    }

    return (
        <>
            {loading ? <LoadingMini/> : <> {typeof props.skill?.id !== "undefined" ?
                <>{props.countedSkills.length > 3 &&
                <Popconfirm
                    placement="topRight"
                    title={'You are about to delete this skill, Do you want to proceed?'}
                    onConfirm={() => confirm(props.skill.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <button type="button"
                            className="btn remove btn-sm btn-outline-danger btn-xs transition-3d-hover mt-2">
                        {/*<span> <i className="fas fa-trash-alt mr-1"/> Remove </span>*/}
                        <span> Remove </span>
                    </button>
                </Popconfirm> }</>
                :
                <button type="button" disabled={true}
                        className="btn btn-sm btn-outline-info btn-xs transition-3d-hover mt-2">
                    <span> Pending </span>
                </button>}

            </>}
        </>
    );
}

export default PopconfirmSkillDelete;
