import React, {useState} from 'react';
import * as Yup from "yup";
import {useFormik} from "formik";
import LinkComponent from "../../route/LinkComponent";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {handleNotify} from "../../appRedux/response";
import {FormFeedback} from "reactstrap";
import {notification, Alert} from "antd";
import {NOTIFICATION_DURATION} from "../../appRedux/constants";
import {connect} from "react-redux";
import {forgetPassword, forgotPassword} from "../../appRedux/actions/Talents";
import {isEmpty} from "lodash"

function ForgotPassword(props) {

    const [alert, setAlert] = useState(false);
    const [laddaLoading, setLaddaLoading] = useState(false);
    const [laddaProgress, setLaddaProgress] = useState(0);

    const schema = Yup.object().shape({
        email: Yup.string().required('email is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: schema,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setLaddaLoading(true);
            setLaddaProgress(0.5);

            props.forgotPassword({type: 'talent',origin: window.location.origin, ...values}).then(res => {
                handleIn(res)
                setSubmitting(false);
                resetForm(true);
            }).catch(e => {
                handleNotify(e.message, () => {
                    setLaddaLoading(false);
                    setLaddaProgress(1.0);
                    setSubmitting(false);
                })
            })
        },
    });

    const handleIn = res => {
        setAlert(res.message);
        // notification['success']({
        //     message: 'Password reset',
        //     description: res.message,
        //     duration: NOTIFICATION_DURATION,
        // });
        setLaddaLoading(false);
        setLaddaProgress(1.0);
    }

    return (
        <>
            <div className="col-md-8 offset-md-2 space-top-3 space-lg-0">
                <form className="js-validate" onSubmit={formik.handleSubmit}>
                    <div className="mb-5 mb-md-7">
                        <h1 className="h2">Forgot your password?</h1>
                        <p>Enter your email address below and we'll get you back on track.</p>

                        {!isEmpty(alert) && <Alert
                            message="Password reset"
                            description={alert}
                            type="success"
                        />}

                    </div>

                    <div className="js-form-message form-group">
                        <label className="input-label"> Email Address </label>
                        <input type="email"
                               className="form-control"
                               name="email"
                               placeholder="Enter email address"
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.email}/>
                        {formik.touched.email && formik.errors.email &&
                        <FormFeedback>{formik.errors.email}</FormFeedback>}
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <LinkComponent to={"/talents/login"} className="font-size-1 font-weight-bold">
                                <i className="fas fa-angle-left fa-sm mr-1"/> Back to sign in
                            </LinkComponent>
                        </div>

                        <div className="col-sm-6 text-sm-right">
                            <div className=" text-sm-right">
                                <LaddaButton
                                    loading={laddaLoading}
                                    progress={laddaProgress}
                                    data-style={ZOOM_OUT}
                                    data-spinner-size={30}
                                    data-spinner-color="#fff"
                                    data-spinner-lines={12}
                                    className="btn btn-primary transition-3d-hover"
                                    type="submit"
                                    disabled={formik.isSubmitting}>
                                    Request Reset Link
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

const mapStateToProps = () => {
    return {}
}

export default connect(mapStateToProps, {forgotPassword})(ForgotPassword);
