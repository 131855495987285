import {
    VERIFY_FORGOT_PASSWORD,
    RESET_PASSWORD_START,
    RESET_PASSWORD_END, VERIFY_ACTIVATION_CODE, SUCCESS_CODE, JOB_DETAIL_END
} from "../constants";
import api from "../api";

export const resetPassword = (params) => async dispatch => {
    const {data} = await api.post(VERIFY_FORGOT_PASSWORD, params);
    if (data.status === SUCCESS_CODE) {
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}

export const verifyActivationCode = activation_code => async dispatch => {
    const {data} = await api.get(`${VERIFY_ACTIVATION_CODE}/${activation_code}`);
    if (data.status === SUCCESS_CODE) {
        return {...data.data}
    } else {
        throw new Error(data.message)
    }
}
