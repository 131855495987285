import React, {useEffect, useState} from 'react';
import {Button, Spin, Upload, Modal, message, Empty} from "antd";
import LinkComponent from "../../route/LinkComponent";
import {connect, useSelector} from "react-redux";
import {redirectTo, useLocation} from "@reach/router";
import {getTalentProfile} from "../../appRedux/actions/Talents";
import {LoadingMini} from "../Loading";
import {ACTIVE_TALENT_ACCOUNT, UPLOAD_FILE} from "../../appRedux/constants";
import {isEmpty} from "lodash";
import {getUrl, settings, bearer} from '../../appRedux/api';
import {UploadOutlined, InboxOutlined, EditOutlined, FilePdfOutlined} from '@ant-design/icons';
import {Form} from "antd";
import {FilePond, registerPlugin} from "react-filepond";

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the plugin code
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import the plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import Programming from "../../_assets/media/svg/Coding-rafiki.svg";
import {redirectToLogin, redirectToTalentForResume} from "../../route/Redirect";
import {getSession} from "../../appRedux/store/cookies";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize,
    FilePondPluginFileValidateType, FilePondPluginImageTransform, FilePondPluginImageEdit);

function TalentMenu(props) {

    const {talent_profile, talent_profile_loading, token} = useSelector(state => state.talent);
    const [visibility, setVisibility] = useState(false);
    const [visible, setVisible] = useState(false);
    const [resumeNeeded, setResumeNeeded] = useState(true);
    const location = useLocation();
    const [avatarStyle, setAvatarStyle] = useState({background: '#1d315e'});

    // console.log(talent_profile)

    useEffect(() => {
        props.getTalentProfile()
    }, []);

    useEffect(() => {
        if (!isEmpty(talent_profile) && isEmpty(talent_profile.resume)) {
            redirectToTalentForResume();
        }
    }, [talent_profile])

    useEffect(() => {
        if(isEmpty(talent_profile)) {
            redirectToLogin();
        }

        if (!isEmpty(talent_profile.avatar)) {
            setAvatarStyle({backgroundImage: `url(${talent_profile.avatar})`, backgroundSize: 'contain'});
        }

        // if (!isEmpty(talent_profile) && isEmpty(talent_profile.resume)) {
        //     if (location.pathname !== '/profile') {
        //         setVisible(true);
        //     }
        // }
    }, [talent_profile])

    const copyCode = (ref_code) => event => {
        event.preventDefault();
        const el = document.createElement('textarea');
        const content = `${window.location.origin}/talents/referral/${ref_code}`
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        message.success(`"${content}" copied`)
    }

    const avatarEditInput = () => {
        setVisibility(true);
    }

    const displayAvatar = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setAvatarStyle({backgroundImage: `url(${reader.result})`, backgroundSize: 'contain'});
                resolve(true);
            }
        })
    }

    const showModal = () => {
        setVisibility(true);
    };

    const handleOk = () => {
        setVisibility(false);
    };

    const handleCancel = () => {
        setVisibility(false);
    };

    const processProfileImage = {
        timeout: 7000,
        process: {
            url: getUrl(UPLOAD_FILE),
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            withCredentials: false,
            onload: (response) => {
                response = JSON.parse(response)
                if (response.status === 200) {
                    setAvatarStyle({backgroundImage: `url(${response.data.avatar})`, backgroundSize: 'contain'});
                    message.success(response.message);
                } else {
                    message.error(response.message);
                }
            },
            onerror: (response) => {
                message.error(response.message);
            },
            ondata: (formData) => {
                formData.append('type', 'avatar');
                return formData;
            },
        },
        // fake server to simulate loading a 'local' server file and processing a file
        // process: (fieldName, file, metadata, load) => {
        //     console.log(fieldName, file, metadata, load)
        //     // simulates uploading a file
        //     setTimeout(() => {
        //         load(Date.now())
        //     }, 1500);
        // },
        // load: (source, load) => {
        //     // simulates loading a file from the server
        //     fetch(source).then(res => res.blob()).then(load);
        // }

    }

    // const header = {'Authorization': bearer};
    // const url = getUrl(UPLOAD_FILE);
    // const uploads = {
    //     name: 'resume',
    //     maxCount: 1,
    //     action: url,
    //     headers: header,
    //     method: 'post',
    //     data: {
    //         type: 'resume'
    //     },
    //     accept: ".pdf",
    //     onChange(info) {
    //         if (info.file.status !== 'uploading') {
    //             console.log(info.file, info.fileList);
    //         }
    //         if (info.file.status === 'done') {
    //             const {data} = info.file.response;
    //             setDefaultFile([{
    //                 uid: '1',
    //                 name: data.resume_url,
    //                 status: 'done',
    //                 url: data.resume,
    //             }])
    //             message.success(`${info.file.name} file uploaded successfully`);
    //         } else if (info.file.status === 'error') {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     },
    //     fileList: defaultFile,
    // };
    // const uploadCoverLetter = {
    //     name: 'cover_letter',
    //     maxCount: 1,
    //     action: url,
    //     headers: header,
    //     method: 'post',
    //     data: {
    //         type: 'cover_letter'
    //     },
    //     accept: ".pdf",
    //     onChange(info) {
    //         if (info.file.status !== 'uploading') {
    //             console.log(info.file, info.fileList);
    //         }
    //         if (info.file.status === 'done') {
    //             const {data} = info.file.response;
    //             console.log(data)
    //             setDefaultFileCover([{
    //                 uid: '2',
    //                 name: data.cover_letter_url,
    //                 status: 'done',
    //                 url: data.cover_letter,
    //             }])
    //             message.success(`${info.file.name} file uploaded successfully`);
    //         } else if (info.file.status === 'error') {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     },
    //     fileList: defaultFileCover,
    // };

    const [defaultFile, setDefaultFile] = useState([]);
    // const headers = {'Authorization': bearer};
    const url = getUrl(UPLOAD_FILE);
    // const uploads = {
    //     name: 'resume',
    //     maxCount: 1,
    //     action: url,
    //     headers: headers,
    //     method: 'post',
    //     data: {
    //         type: 'resume'
    //     },
    //     accept: ".pdf",
    //     onChange(info) {
    //         if (info.file.status !== 'uploading') {
    //             console.log(info.file, info.fileList);
    //         }
    //         // if (info.file.status === 'done') {
    //         //     const {data} = info.file.response;
    //         //     console.log('data', data);
    //         //     // setVisible(true);
    //         //     // setDefaultFile([{
    //         //     //     uid: '1',
    //         //     //     name: data.resume_url,
    //         //     //     status: 'done',
    //         //     //     url: data.resume,
    //         //     // }])
    //         //     // message.success(`${info.file.name} file uploaded successfully`);
    //         //     // props.getTalentProfile();
    //         // } else if (info.file.status === 'error') {
    //         //     message.error(`${info.file.name} file upload failed.`);
    //         // }
    //     },
    //     fileList: defaultFile,
    // };



    return (
        <>


            <Modal title={'Profile photo'} style={{padding: 0}} forceRender={true} visible={visibility} footer={null}
                   onOk={handleOk} onCancel={handleCancel}>
                {visibility && <div className="App">
                    <FilePond
                        // files={files}
                        server={processProfileImage}
                        name={"avatar"}
                        instantUpload={false}
                        allowImageEdit={true}
                        imageCropAspectRatio={'1:1'}
                        imageResizeTargetWidth={200}
                        imageResizeTargetHeight={200}
                        imagePreviewHeight={170}
                        allowFileSizeValidation={true}
                        acceptedFileTypes={["image/png", "image/jpeg", "image/JPEG", "image/PNG", "image/GIF", "image/gif"]}
                        allowFileTypeValidation={true}
                        labelMaxFileSizeExceeded={'File is too large'}
                        labelFileTypeNotAllowed={'File of invalid type'}
                        stylePanelLayout={'compact circle'}
                        styleLoadIndicatorPosition={'center bottom'}
                        styleProgressIndicatorPosition={'right bottom'}
                        styleButtonRemoveItemPosition={'left bottom'}
                        styleButtonProcessItemPosition={'right bottom'}
                        labelIdle='Drag & Drop your picture or <span className="filepond--label-action">Browse</span>'
                    />
                </div>}
            </Modal>

            <div className="col-lg-3">
                <div className="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light">
                    <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
                        <div className="card mb-5">
                            {talent_profile.active !== ACTIVE_TALENT_ACCOUNT &&
                            <div className="alert alert-soft-danger text-center rounded-0 mb-0 error-min" role="alert">
                                Your account is currently inactive. Please update your <b>General details</b> / <b>Get
                                Vetted</b> to get activated.
                            </div>}

                            <div className="card-body">
                                {/*<div className="flex-md-wrap"></div>*/}
                                <div className="d-none d-lg-block text-center mb-5"
                                     style={{height: 'auto', width: '100%'}}>
                                    {talent_profile_loading ? <><br/><br/><br/><LoadingMini/></> : <>
                                        <div className="avatar avatar-xxl avatar-circle mb-3 rel">
                                            <div className={'avatar-img'} style={avatarStyle}/>
                                            <div className={"edit_avatar"} onClick={avatarEditInput}>
                                                <EditOutlined/>
                                            </div>
                                            {/*<img className="avatar-img"*/}
                                            {/*     src="assets/img/160x160/img1.jpg"*/}
                                            {/*     alt="Image Description"/>*/}
                                            {/*<img className="avatar-status avatar-lg-status"*/}
                                            {/*     src="assets/svg/illustrations/top-vendor.svg"*/}
                                            {/*     alt="Image Description" data-toggle="tooltip"*/}
                                            {/*     data-placement="top" title=""*/}
                                            {/*     data-original-title="Verified user"/>*/}
                                        </div>

                                        <h4 className="card-title">{talent_profile.name}</h4>
                                        <p className="card-text font-size-1">{talent_profile.email}</p>
                                        {/*<p className="card-text font-size-1">*/}
                                        {/*    <button type="button"*/}
                                        {/*            className="btn btn-xs btn-outline-primary font-weight-bold text-nowrap ml-1">*/}
                                        {/*        {talent_profile.title?.name}*/}
                                        {/*    </button>*/}
                                        {/*</p>*/}

                                    </>}
                                </div>

                                {/*{talent_profile_loading ? <LoadingMini/> : <>*/}
                                {/*    {isEmpty(talent_profile.test) &&*/}
                                {/*    <div className="alert alert-warning alert-dismissible fade show" role="alert" style={{padding:"10px", fontSize: "12px"}}>*/}
                                {/*        You currently have not taking our test, Please follow this <LinkComponent to={'/profile/get-vetted'}  className="btn-close" > <strong>link</strong> </LinkComponent> to*/}
                                {/*        Get Vetted.*/}
                                {/*    </div>}*/}
                                {/*</>}*/}

                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                    <li className="nav-item">
                                        <LinkComponent
                                            className={`nav-link ${location.pathname === '/overview' && 'active'}`}
                                            to="/overview">
                                            <i className="fas fa-database nav-icon"/> Overview
                                        </LinkComponent>
                                    </li>
                                </ul>

                                <h6 className="text-cap small">Account</h6>
                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                    <li className="nav-item">
                                        <LinkComponent
                                            className={`nav-link ${location.pathname === '/profile' && 'active'}`}
                                            to="/profile">
                                            <i className="fas fa-user nav-icon"/> Profile
                                        </LinkComponent>
                                    </li>
                                    <li className="nav-item">
                                        <LinkComponent
                                            className={`nav-link ${location.pathname === '/profile/info' && 'active'}`}
                                            to="/profile/info">
                                            <i className="fas fa-id-card nav-icon"/> Information
                                        </LinkComponent>
                                    </li>
                                    <li className="nav-item">
                                        <LinkComponent
                                            className={`nav-link ${location.pathname === '/profile/get-vetted' && 'active'}`}
                                            to="/profile/get-vetted">
                                            <i className="fas fa-user-shield nav-icon"/> Get Vetted
                                        </LinkComponent>
                                    </li>
                                    <li>
                                        <LinkComponent
                                            className={`nav-link ${location.pathname === '/profile/account' && 'active'}`}
                                            to="/profile/account">
                                            <i className="fas fa-shield-alt nav-icon"/> Login & security
                                        </LinkComponent>
                                    </li>
                                </ul>

                                <h6 className="text-cap small"> Jobs </h6>
                                <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4">
                                    <li className="nav-item">
                                        <LinkComponent
                                            className={`nav-link ${location.pathname === '/profile/applied-jobs' && 'active'}`}
                                            to="/profile/applied-jobs">
                                            <i className="fas fa-shopping-basket nav-icon"/> Applied Jobs
                                        </LinkComponent>
                                    </li>
                                </ul>

                                {/*<h6 className="text-cap small">Billing</h6>*/}
                                {/*<ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2">*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <LinkComponent className="nav-link "*/}
                                {/*                       to="account-billing.html">*/}
                                {/*            <i className="fas fa-credit-card nav-icon"/> Plans &amp; payment*/}
                                {/*        </LinkComponent>*/}
                                {/*    </li>*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <LinkComponent className="nav-link "*/}
                                {/*                       to="account-address.html">*/}
                                {/*            <i className="fas fa-map-marker-alt nav-icon"/> Address*/}
                                {/*        </LinkComponent>*/}
                                {/*    </li>*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <LinkComponent className="nav-link "*/}
                                {/*                       to="account-teams.html">*/}
                                {/*            <i className="fas fa-users nav-icon"/> Teams*/}
                                {/*            <span*/}
                                {/*                className="badge badge-soft-dark badge-pill nav-link-badge">+2 new users</span>*/}
                                {/*        </LinkComponent>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}

                                <div className="">
                                    <div className="dropdown-divider"/>

                                    <ul className="nav nav-sub nav-sm nav-tabs nav-list-y-2">
                                        <li className="nav-item">
                                            <LinkComponent className="nav-link"
                                                           to={'/logout'}>
                                                <i className="fas fa-sign-out-alt nav-icon"/> Log out
                                            </LinkComponent>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-indigo">
                            <div className="card-body">
                                <h2 className="h5 text-white mb-3">Referral Code</h2>
                                <p className="text-info d-block">As a talent, whoever you
                                    refer upon hire, you get <b>$100</b></p>

                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <h3 className="text-white mb-2 font-weight-light"
                                            style={{fontSize: '54px'}}> {talent_profile.ref_code} </h3>
                                        {/*<small className="d-block text-light">Business</small>*/}
                                        <span className="btn btn-sm btn-soft-primary btn-pill transition-3d-hover"
                                              onClick={copyCode(talent_profile.ref_code)}> Copy Referral Link <i
                                            className="fas fa-angle-right ml-1"/></span>
                                    </div>
                                </div>

                                {/*<div>*/}
                                {/*    <form>*/}
                                {/*        <div className="input-group mb-4">*/}
                                {/*            <input id="copyToClipboard" type="text" className="form-control"*/}
                                {/*                   value="http://www.front-course.com/share/3874/"/>*/}
                                {/*            <div className="input-group-append">*/}
                                {/*                <a className="js-clipboard input-group-text">Copy</a>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </form>*/}

                                {/*    <ul className="list-inline mb-0">*/}
                                {/*        <li className="list-inline-item">*/}
                                {/*            <a className="btn btn-xs btn-icon btn-outline-secondary" href="#">*/}
                                {/*                <i className="fab fa-facebook-f"></i>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li className="list-inline-item">*/}
                                {/*            <a className="btn btn-xs btn-icon btn-outline-secondary" href="#">*/}
                                {/*                <i className="fab fa-twitter"></i>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li className="list-inline-item">*/}
                                {/*            <a className="btn btn-xs btn-icon btn-outline-secondary" href="#">*/}
                                {/*                <i className="fab fa-medium-m"></i>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li className="list-inline-item">*/}
                                {/*            <a className="btn btn-xs btn-icon btn-outline-secondary" href="#">*/}
                                {/*                <i className="fa fa-envelope"></i>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*        <li className="list-inline-item">*/}
                                {/*            <a className="btn btn-xs btn-icon btn-outline-secondary" href="#">*/}
                                {/*                <i className="fab fa-slack"></i>*/}
                                {/*            </a>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({talent}) => {
    const {talent_profile_loading, talent_profile} = talent;
    return {talent_profile_loading, talent_profile}
}

export default connect(mapStateToProps, {getTalentProfile})(TalentMenu);
