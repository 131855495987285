import {navigate} from "@reach/router";

export const redirectToOverview = () => navigate('/overview')
export const redirectToResume = () => navigate('/talents/resume')
export const redirectToProfile = () => navigate('/profile')
export const redirectToLogin = () => navigate('/login')
export const redirectToJobs = () => navigate('/jobs')
export const redirectToParams = params => navigate(params)
export const redirectToClientProfile = () => navigate('/client')
export const redirectToClientLogin = () => navigate('/client/login')
export const redirectToTalentLogin = () => navigate('/talents/login')
export const redirectToTalentSignup = () => navigate('/talents/signup')
export const redirectToTalentForResume = () => navigate('/talents/resume')
