import {
    DEGREES_LIST_START, DEGREES_LIST_END
} from '../constants';

const INIT_STATE = {
    degree_loading: false,
    degree_list: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEGREES_LIST_START: {
            return {...state, degree_loading: true};
        }
        case DEGREES_LIST_END: {
            return {...state, degree_loading: false, degree_list: action.payload};
        }
        default:
            return state;
    }
};
