import React from 'react';
import ResetPassword from "../../components/ResetPassword";

function ClientAccount(props) {
    return (
        <div>
            <ResetPassword type={"client"} />
        </div>
    );
}

export default ClientAccount;
