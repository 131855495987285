import React, {useState} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import Brand from "../Brand";
import {toAbsoluteUrl} from "../../_helpers";
import AsideSearch from "../AsideSearch";
import {useLocation} from "@reach/router";

function Aside(props) {

    const location = useLocation();
    const isPartiallyActive = (isCurrent) => {
        return location.pathname === isCurrent ? "active" : "";
    };

    // const isPartiallyActive =

    return (
        <>
            <div>
                <div id="bh_aside" className={`aside aside-left d-flex aside-fixed`}>
                    {/* begin::Primary */}
                    <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
                        <Brand/>
                        {/* begin::Nav Wrapper */}
                        <div
                            className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                            {/* begin::Nav */}
                            <ul className="list-unstyled flex-column" role="tablist">
                                {/* begin::Item */}
                                <li
                                    className="nav-item mb-3"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                    title="Latest Project">
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="latest-project"> Overview </Tooltip>
                                        }>
                                        <a
                                            href="/overview"
                                            className={`nav-link btn btn-icon btn-clean btn-lg ${isPartiallyActive('/overview')}`}
                                        >
                                            <span className="svg-icon svg-icon-lg">
                                              <SVG
                                                  src={toAbsoluteUrl(
                                                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                                                  )}
                                              />
                                            </span>
                                        </a>
                                    </OverlayTrigger>
                                </li>
                                {/* end::Item */}

                                {/* begin::Item */}
                                <li
                                    className="nav-item mb-3"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                    title="Features"
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="app-features"> Talents / Employees </Tooltip>
                                        }
                                    >
                                        <a
                                            href="/talents"
                                            className={`nav-link btn btn-icon btn-clean btn-lg ${isPartiallyActive('/talents')}`}
                                        >
                                        <span className="svg-icon svg-icon-lg">
                                          <SVG
                                              src={toAbsoluteUrl(
                                                  "/media/svg/icons/Communication/Group.svg"
                                              )}
                                          />
                                        </span>
                                        </a>
                                    </OverlayTrigger>
                                </li>
                                {/* end::Item */}

                                {/* begin::Item */}
                                <li
                                    className="nav-item mb-3"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                    title="Latest Reports"
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="latest-reports"> Reports</Tooltip>
                                        }
                                    >
                                        <a
                                            href="/reports"
                                            className={`nav-link btn btn-icon btn-clean btn-lg ${isPartiallyActive('/reports')}`}
                                        >
                                        <span className="svg-icon svg-icon-lg">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}/>
                                        </span>
                                        </a>
                                    </OverlayTrigger>
                                </li>
                                {/* end::Item */}

                                {/* begin::Item */}
                                <li
                                    className="nav-item mb-3"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                    title="Project Management"
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="project-management">
                                                Client / Project
                                            </Tooltip>
                                        }
                                    >
                                        <a
                                            href="/projects"
                                            className={`nav-link btn btn-icon btn-clean btn-lg ${isPartiallyActive('/projects')}`}
                                        >
                                            <span className="svg-icon svg-icon-lg">
                                              <SVG
                                                  src={toAbsoluteUrl(
                                                      "/media/svg/icons/General/Shield-check.svg"
                                                  )}
                                              />
                                            </span>
                                        </a>
                                    </OverlayTrigger>
                                </li>
                                {/* end::Item */}

                                {/* begin::Item */}
                                <li
                                    className="nav-item mb-3"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                    title="User Management"
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="user-management">User Management</Tooltip>
                                        }
                                    >
                                        <a
                                            href="/management"
                                            className={`nav-link btn btn-icon btn-clean btn-lg ${isPartiallyActive('/management')}`}
                                            data-toggle="tab"
                                            data-target="#bh_aside_tab_5"
                                            role="tab"
                                        >
                                            <span className="svg-icon svg-icon-lg">
                                              <SVG
                                                  src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                                              />
                                            </span>
                                        </a>
                                    </OverlayTrigger>
                                </li>
                                {/* end::Item */}

                                {/* begin::Item */}
                                <li
                                    className="nav-item mb-3"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                    title="Finance & Accounting"
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="finance-accounting">
                                                Finance & Accounting
                                            </Tooltip>
                                        }
                                    >
                                        <a
                                            href="/finance"
                                            className={`nav-link btn btn-icon btn-clean btn-lg ${isPartiallyActive('/finance')}`}
                                            data-toggle="tab"
                                            data-target="#bh_aside_tab_6"
                                            role="tab"
                                        >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Files/File-plus.svg"
                          )}
                      />
                    </span>
                                        </a>
                                    </OverlayTrigger>
                                </li>
                                {/* end::Item */}
                            </ul>
                            {/* end::Nav */}
                        </div>
                        {/* end::Nav Wrapper */}

                        {/* begin::Footer */}
                        <div
                            className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                            {/* begin::Aside Toggle */}
                            <>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip id="toggle-aside">Toggle Projects</Tooltip>}>
                                    <button type={'button'}
                                            className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                                            onClick={props.aside}>

                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                                    </button>
                                </OverlayTrigger>
                            </>
                            {/* end::Aside Toggle */}

                            {/* begin::Notifications */}
                            {/*{layoutProps.extrasNotificationsDisplay && (*/}
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="toggle-notifications">Notifications</Tooltip>
                                }
                            >
                                <a
                                    href="#"
                                    className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                                    id="bh_quick_notifications_toggle"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                >
                                      <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                                        />
                                      </span>
                                </a>
                            </OverlayTrigger>
                            {/*)}*/}
                            {/* end::Notifications */}

                            {/* begin::Quick Actions */}
                            {/*{layoutProps.extrasQuickActionsDisplay && (*/}
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="toggle-quick-actions">Quick Actions</Tooltip>
                                }
                            >
                                <a
                                    href="#"
                                    className="btn btn-icon btn-clean btn-lg mb-1"
                                    id="bh_quick_actions_toggle"
                                >
                                      <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Media/Equalizer.svg"
                                            )}
                                        />
                                      </span>
                                </a>
                            </OverlayTrigger>
                            {/*)}*/}
                            {/* end::Quick Actions */}

                            {/* begin::Quick Panel */}
                            {/*{layoutProps.extrasQuickPanelDisplay && (*/}
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="toggle-quick-panel">Quick Panel</Tooltip>}
                            >
                                <a
                                    href="#"
                                    className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                                    id="bh_quick_panel_toggle"
                                    data-placement="right"
                                    data-container="body"
                                    data-boundary="window"
                                >
                                      <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Layout/Layout-4-blocks.svg"
                                            )}
                                        />
                                      </span>
                                    <span
                                        className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1"> 3 </span>
                                </a>
                            </OverlayTrigger>
                            {/*)}*/}
                            {/* end::Quick Panel */}
                        </div>
                        {/* end::Footer */}
                    </div>
                    {/* end::Primary */}

                    <>
                        <div className="aside-secondary d-flex flex-row-fluid">
                            <div className="aside-workspace scroll scroll-push my-2">
                                <div className="tab-content">
                                    <AsideSearch/>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
}

export default Aside;
