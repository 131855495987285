import {combineReducers} from 'redux';
// import { loadingBarReducer } from 'react-redux-loading-bar';

import Job from '../reducers/Job';
import Talent from '../reducers/Talent';
import Country from '../reducers/Country';
import Industry from '../reducers/Industry';
import Location from '../reducers/Location';
import Skill from '../reducers/Skill';
import Degree from '../reducers/Degree';
import Client from '../reducers/Client';
import Setting from '../reducers/Setting';

const reducers = combineReducers({
    job: Job,
    talent: Talent,
    country: Country,
    industry: Industry,
    location: Location,
    skill: Skill,
    degree: Degree,
    client: Client,
    setting: Setting,
});

export default reducers;
