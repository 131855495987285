import React, {useState} from 'react';
import {Popconfirm} from "antd";
import {useDispatch} from "react-redux";
import {deleteEducation} from "../../appRedux/actions/Talents";
import {LoadingMini} from "../../components/Loading";

function PopconfirmEducationDelete(props) {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const confirm = (education_id) => {
        setLoading(true)
        dispatch(deleteEducation(education_id))
    }

    return (
        <>
            {loading ? <LoadingMini/> :
                <Popconfirm
                    placement="topRight"
                    title={`You are about to delete "${props.education_title}", Do you want to proceed?`}
                    onConfirm={() => confirm(props.education_id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <button type="button"
                            className="btn remove btn-sm btn-outline-danger btn-xs transition-3d-hover mt-2">
                        <span> <i className="fas fa-trash-alt mr-1"/> Delete </span>
                    </button>
                </Popconfirm>}
        </>
    );
}

export default PopconfirmEducationDelete;
