import React from 'react';
import ResetPassword from "../../components/ResetPassword";

function TalentAccount(props) {
    return (
        <div>
            <ResetPassword type={"talent"} />
        </div>
    );
}

export default TalentAccount;
