import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getJobDetail} from "../../appRedux/actions/Jobs";
import {Loading} from "../../components/Loading";
import {isEmpty} from 'lodash';
import moment from "moment";
import LinkComponent from "../../route/LinkComponent";
import {Skeleton, Space} from "antd";
import {v4 as uuid} from "uuidv4";
import renderHTML from 'react-render-html';

function ClientJobDetail(props) {

    useEffect(() => {
        props.getJobDetail(parseInt(props.job_id))
    }, []);

    const {job_detail: job, job_detail_loading} = props;
    // console.log('job', job)

    return (
        <>
            <div className={'card mb-5'}>
                {/*<div className="card-header border-0 bg-light text-center px-4">*/}
                {/*    <h5 className="card-header-title"> Job details </h5>*/}
                {/*</div>*/}
                {/*<div className="card-header border-0 bg-light text-center px-4">*/}
                {/*    <h5 className="card-header-title"> Post Listings </h5>*/}
                {/*</div>*/}
                {/*<div className="card mb-3 mb-lg-5">*/}
                {/*<div className="card-header">*/}
                {/*    <h5 className="card-title"> Listed Jobs </h5>*/}
                {/*</div>*/}
                <div className="card-body">
                    {/*<LinkComponent to={`/client/job-details/${job.id}`} className={"btn btn-xs btn-ghost-secondary mb-1 mr-1"} > Edit </LinkComponent>*/}

                    {isEmpty(job) ? <>
                        <Skeleton active/>
                        <Skeleton active/>
                    </> : <>
                        <div className="page-header" style={{paddingBottom: "1rem"}}>
                            <div className="d-sm-flex">
                                <div className="media-body">
                                    <div className="row mb-2">
                                        <div className="col">
                                            <h1 className="h2 mb-1">{job.title}</h1>
                                            <div>
                                                {job.skills.map(skill => <small key={uuid}
                                                                                className="btn btn-xs btn-soft-secondary mr-1 mb-1"
                                                                                style={{color: '#00284f'}}>{skill.title}</small>)}
                                                {/*{job.skills.map(skill => <small key={skill.id}*/}
                                                {/*                                className="btn btn-xs btn-success btn-pill text-uppercase shadow-soft mb-3">{skill.title}</small>)}*/}
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="list-inline list-separator small text-body mb-2">
                                        <li className="list-inline-item">{moment(job.created).format("YYYY-MM-DD, h:mm:ss a")}</li>
                                        <li className="list-inline-item">{job.location.name}, {job.location.country.name}</li>
                                        <li className="list-inline-item">Salary Range {job.salary} </li>
                                        {/*<li className="list-inline-item">Full time</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-6">
                                <h3>Description</h3>
                            </div>
                        </div>

                        <div className={"mb-4"}>
                            {renderHTML(job.description)}
                        </div>
                    </>}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({job}) => ({...job})

export default connect(mapStateToProps, {getJobDetail})(ClientJobDetail);
