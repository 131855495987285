import firebase from "firebase"
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyBu_36P1Nnn-FWG-MI4wPjE1YLIEGRtYCU",
    authDomain: "borderlesshr-d8cec.firebaseapp.com",
    projectId: "borderlesshr-d8cec",
    storageBucket: "borderlesshr-d8cec.appspot.com",
    messagingSenderId: "359773338855",
    appId: "1:359773338855:web:3db1dbaa52a3b4c5823642",
    measurementId: "G-3TK87T7D2D"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app(); // if already initialized, use that one
 }
 
export const storage = firebase.storage();