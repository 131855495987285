import React, {useEffect} from 'react';
import LinkComponent from "../../route/LinkComponent";
import {useDispatch, useSelector} from "react-redux";
import {getPrivacyTerms} from "../../appRedux/actions/Settings";
import {Loading} from "../../components/Loading";
import {isEmpty} from "lodash";
import Parser from 'html-react-parser';

function Terms(props) {

    const dispatch = useDispatch();
    const {content, setting_loading} = useSelector(state => state.setting)


    useEffect(() => {
        dispatch(getPrivacyTerms())
    }, []);

    return (
        <>
            <main id="content" role="main" className="">
                <div className="container space-top-3 space-top-lg-4 space-bottom-2 space-bottom-lg-3 overflow-hidden">
                    <div className="w-lg-80 mx-lg-auto">
                        {(isEmpty(content))  ? <Loading/> :
                            <div className="card">
                                <div
                                    className="card-header position-relative d-block bg-primary space-1 space-md-2 p-4 px-md-7 px-md-9">
                                    <h1 className="text-white">Terms &amp; Conditions</h1>
                                    {/*<p className="text-white-70">Last modified: March 27, 2021</p>*/}
                                    <p className="text-white-70">Last modified: {content.terms.modified}</p>
                                </div>

                                <div className="card-body p-4 p-md-9"> {Parser(content.terms.content)} </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
        </>
    );
}

export default Terms;
