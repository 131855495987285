import React, {useEffect, useState} from 'react';
import {Button, Modal} from "antd";
import Experience from "./Experience";
import General from "./General";
import {getCountryLists} from "../../appRedux/actions/Country";
import {useDispatch, useSelector} from "react-redux";
import Education from "./Education";
import {isEmpty} from "lodash";
import LoadingMini from "../../components/Loading/LoadingMini";
import LinkComponent from "../../route/LinkComponent";

function TalentInfo(props) {

    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    // const {talent_profile, talent_profile_loading} = useSelector(state => state.talent)

    useEffect(() => {
        dispatch(getCountryLists());
    }, []);

    return (
        <div>
            <>
                <General/>
                <Education/>
                <Experience/>
                {/*<Resume />*/}
            </>

        </div>
    );
}

export default TalentInfo;
