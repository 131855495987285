import React from 'react';
import {Row, Col, Spin} from 'antd';
import {ReactComponent as Logo} from "../../_assets/media/svg/logo.svg";
import LoadingMini from "./LoadingMini";

// import LogoLottie from "../../_assets/media/svg/logo.json";

function Loading(props) {
    return (
        <>
            <div>
                <>
                    <div className="loading_holder">
                        <div className="tcenter">
                            {/*<div>*/}
                            {/*    <Spin/>*/}
                            {/*</div>*/}
                            {/*<br/>*/}
                            {/*<div className="">*/}
                            {/*    <div className="spinner">*/}
                            {/*        <div className="bounce1"/>*/}
                            {/*        <div className="bounce2"/>*/}
                            {/*        <div className="bounce3"/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className={'d-flex justify-content-center'}>
                                <div>
                                    <Logo style={{height: "18px", width: "auto"}}/>
                                    <LoadingMini/>
                                </div>
                            </div>

                            {/*<lottie-player*/}
                            {/*    autoplay*/}
                            {/*    controls*/}
                            {/*    loop*/}
                            {/*    mode="normal"*/}
                            {/*    src={LogoLottie}/>*/}
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default Loading;
